const PauseButton = (props: any) => {
    const color = props.color ?? '#999999'
    const width = props.width || 20;
    return (
        <svg width={width} height={width} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6758 34C7.48019 34 0 26.5191 0 17.3242C0 8.12935 7.48019 0.648438 16.6758 0.648438C25.8714 0.648438 33.3516 8.12935 33.3516 17.3242C33.3516 26.5191 25.8714 34 16.6758 34ZM16.6758 2.03806C8.24696 2.03806 1.38963 8.89539 1.38963 17.3242C1.38963 25.753 8.24696 32.6104 16.6758 32.6104C25.1046 32.6104 31.9619 25.753 31.9619 17.3242C31.9619 8.89539 25.1046 2.03806 16.6758 2.03806Z" fill={color} />
            <path d="M14.9644 12.3929V22.6071C14.9644 22.9766 14.8176 23.3308 14.5564 23.592C14.2952 23.8533 13.9409 24 13.5715 24H13.1072C12.7378 24 12.3835 23.8533 12.1223 23.592C11.8611 23.3308 11.7144 22.9766 11.7144 22.6071V12.3929C11.7144 12.0234 11.8611 11.6692 12.1223 11.408C12.3835 11.1467 12.7378 11 13.1072 11H13.5715C13.9409 11 14.2952 11.1467 14.5564 11.408C14.8176 11.6692 14.9644 12.0234 14.9644 12.3929ZM19.6072 11H19.1429C18.7735 11 18.4192 11.1467 18.158 11.408C17.8968 11.6692 17.7501 12.0234 17.7501 12.3929V22.6071C17.7501 22.9766 17.8968 23.3308 18.158 23.592C18.4192 23.8533 18.7735 24 19.1429 24H19.6072C19.9766 24 20.3309 23.8533 20.5921 23.592C20.8533 23.3308 21.0001 22.9766 21.0001 22.6071V12.3929C21.0001 12.0234 20.8533 11.6692 20.5921 11.408C20.3309 11.1467 19.9766 11 19.6072 11Z" fill={color} />
        </svg>
    )
}
export default PauseButton


