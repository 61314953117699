import { useEffect, useState } from "react";

import { HorizontalNavItemsType } from "src/@core/layouts/types";

const ServerSideNavItems = () => {
  // ** State
  const [menuItems, setMenuItems] = useState<HorizontalNavItemsType>([]);

  useEffect(() => {}, []);

  return { menuItems };
};

export default ServerSideNavItems;
