import { Box, IconButton, styled } from "@mui/material";

import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";

import { ILayer } from "src/@core/context/DesignEditorContext/layers";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";

import { ResizeEndComponent, ResizeStartComponent } from "./ResizeHandles";

const TextOuterLayer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: "0px 20px",
  borderRadius: "8px",
  overflow: "hidden",
  height: "2.2rem",
}));

const DeleteButtonContainer = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "-0",
  left: "10px",
  zIndex: "4",
}));

const Text = styled("span")({
  color: "#c63b00",
  fontSize: "8px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  display: "inline-block",
});

const OuterLayer = styled("div")({
  padding: "0px",
  width: "100%",
});
const Frame = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  height: "2.2rem",
  borderRadius: "7px",
}));

const VideoOuterLayer = styled("div")({
  borderRadius: "8px",
  background: "#00acee",
  overflow: "hidden",
  display: "flex",
});

const Clip = (props: any) => {
  const {
    scene,
    currentLayer,
    setCurrentLayer,
    scalePoint,
    scaleSize,
    setLayersChanged,
  } = useDesignEditorContext();
  const [width, setWidth] = useState(props.layer.pixelWidth);
  const [lastXCoordinate, setLastXCoordinate] = useState(0);
  const [showDeleteBtn, setDeleteBtnVisibility] = useState(false);
  const { canvas } = useCanvasContext();
  useEffect(() => {
    if (props.mode == "SHOT-DESIGN")
      setWidth(props.layer.pixelWidth || 5);
    else
      setWidth(props.layer.pixelWidth);
  }, [props.layer.pixelWidth]);

  useEffect(() => {
    if (props.mode == "SHOT-DESIGN")
      setLastXCoordinate(props.pixelPoints[props.layer.position])
    else
      setLastXCoordinate(
        props.pixelPoints[
        Number(props.layer.display).toFixed(2)
          ? Number(props.layer.display.from).toFixed(2)
          : "0.00"
        ],
      );
  }, []);

  useEffect(() => {
    setWidth(props.layer.pixelWidth);
  }, [scaleSize, scalePoint.pixelPoints]);

  useEffect(() => {
    setWidth(props.layer.pixelWidth);
  });

  const updateStaticClips = () => {
    setLayersChanged(true);
  };

  return (
    <Rnd
      // onMouseEnter={() => setDeleteBtnVisibility(true)}
      // onMouseLeave={() => setDeleteBtnVisibility(false)}
      ref={props.rndRef}
      key={props.layer.id}
      style={{ zIndex: currentLayer?.id == props.layer.id ? 11 : 0 }}
      default={{
        x: props.mode == "SHOT-DESIGN" ? props.pixelPoints[props.layer.seconds?.toFixed(2) || "0.00"] : props.pixelPoints[
          Number(props.layer.display).toFixed(2)
            ? Number(props.layer.display.from).toFixed(2)
            : "0.00"
        ],
        y: 0,
        width: width || 0,
      }}
      size={{ width: width }}
      // minWidth={props.pixelWidth + 'px'}
      resizeGrid={[props.distanceBetweenTwoScalePoints, 1]}
      dragGrid={[
        props.distanceBetweenTwoScalePoints,
        props.distanceBetweenTwoScalePoints,
      ]}
      minWidth={props?.minWidth ? props?.minWidth : undefined}
      maxWidth={
        props?.maxWidth
          ? props?.maxWidth
          : props.distanceBetweenTwoScalePoints *
          (props.layer?.duration * Number(scaleSize) || 1)
      }
      enableResizing={{
        top: false,
        bottom: false,
        topRight: false,
        topLeft: false,
        bottomRight: false,
        bottomLeft: false,
        right: true,
        left: true,
      }}
      resizeHandleClasses={{
        left: "left-handle orange",
        right: "right-handle orange",
      }}
      resizeHandleWrapperClass={
        props.layer.id == currentLayer?.id ? "show-angles" : "hide-angles"
      }
      onClick={() => {
        if (props?.layer?.type === "Scene") {
          setCurrentLayer({ ...props.layer } as ILayer);
        }

        if (
          ["Scene", "Camera"].includes(props?.layer?.type) &&
          props?.setMinimizedElements
        ) {
          props?.setMinimizedElements((prev) => ({
            ...prev,
            videoPlayer: false,
          }));
        }
      }}
      className={currentLayer?.id == props.layer.id ? "current-scene" : ""}
      resizeHandleComponent={{
        left: currentLayer?.id == props.layer.id && <ResizeStartComponent />,
        right: currentLayer?.id == props.layer.id && <ResizeEndComponent />,
      }}
      onResizeStart={() => {
        setCurrentLayer(props.layer as ILayer);
      }}
      onResizeStop={(e, dir, refToElement, d, position) => {
        props.onResizeStop(
          dir,
          d,
          refToElement,
          position,
          props.layer,
          props.index,
          setWidth,
        );
        updateStaticClips();
      }}
      dragAxis="x"
      onDrag={(e, x) => {
        if (x.x < 20) {
          x.x = 20;
        }
      }}
      onDragStart={(e, d) => {
        setCurrentLayer({ ...(props.layer as ILayer) });
        setLastXCoordinate(d.lastX);
      }}
      onDragStop={(e, d) => {
        if (d.x < 20) {
          d.x = 20;
        }
        if (d.lastX !== lastXCoordinate && props?.layer?.type !== "Scene") {
          props.onDragStop(e, d, props.layer, props.index);
          updateStaticClips();
        }
      }}
      disableDragging={props?.layer?.type === "Scene" ? true : false}
    >
      {props.layer.type == "StaticVideo" && (
        <VideoOuterLayer
          style={
            (currentLayer?.id == props.layer.id && {
              borderRadius: 0,
              background: "transparent",
            }) ||
            {}
          }
        >
          <OuterLayer>
            <Frame
              style={{
                backgroundImage: `url(${props.layer.preview})`,
                backgroundSize: "contain",
                borderRadius: currentLayer?.id == props.layer.id ? 0 : "",
              }}
            >
              {props.layer.name}
            </Frame>
          </OuterLayer>
        </VideoOuterLayer>
      )}
      {props.layer.type == "StaticText" && (
        <TextOuterLayer>
          <Text>{props.layer.text}</Text>
        </TextOuterLayer>
      )}

      {props.layer.type == "StaticImage" && (
        <OuterLayer>
          <Frame
            sx={{
              backgroundImage: `url(${props.layer.src})`,
              backgroundSize: "contain",
            }}
          ></Frame>
        </OuterLayer>
      )}

      {props.layer.type == "StaticAudio" && (
        <OuterLayer>
          <Frame
            sx={{
              backgroundImage: 'url("/images/pages/icons/sound.svg")',
              backgroundRepeat: "repeat-x",
              backgroundPosition: "center",
            }}
          ></Frame>
        </OuterLayer>
      )}

      {props.layer.type == "Camera" && (
        <OuterLayer>
          <Box height={2} bgcolor="text.primary">
            a
          </Box>
        </OuterLayer>
      )}

      {props.layer.type == "Scene" && (
        <OuterLayer>
          <>
            {!props.layer.preview && (
              <Frame
                sx={{
                  backgroundColor: "#000",
                  backgroundImage: 'url("/images/pages/icons/VideoIcon.svg")',
                  backgroundRepeat: "repeat-x",
                  backgroundPosition: "center",
                }}
              ></Frame>
            )}
            {props.layer.preview && (
              <Frame
                sx={{
                  backgroundColor: "#000",
                  backgroundImage: `url(${props.layer.preview || "/images/pages/icons/VideoIcon.svg"
                    })`,
                  backgroundSize: "contain",
                }}
              ></Frame>
            )}
          </>
        </OuterLayer>
      )}
    </Rnd>
  );
};

export default Clip;
