import { Box, BoxProps, styled, useTheme } from "@mui/material";

import { memo, useEffect, useState } from "react";

import useDesignEditorContext from "src/hooks/useDesignEditorContext";

import Point from "./Point";

const MainScale = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  padding: "0.2rem 14px",
  position: "sticky",
  top: 0,
  background: "inherit",
  backgroundColor: theme.palette.background.paper,
}));

const MemoizedNewComponent = memo(Point);

const Scale = (props: any) => {
  const [points, setPoints] = useState([]);
  const [lastPoint, setLastPoint] = useState("");
  const {
    reRenderAllClips,
    scalePoint,
    setScalePoint,
    updateScale,
    updateScalePoint,
    scaleSize,
    setScaleRenderingProgress,
  } = useDesignEditorContext();
  const theme = useTheme();

  const getNewPoint = (currentSecond: any) => {
    return (
      <MemoizedNewComponent
        scaleSize={scaleSize}
        point={currentSecond}
        key={currentSecond}
        scene={props.scene}
        setTime={props.setTime}
        setPlaybackSeeked={props.setPlaybackSeeked}
      />
    );
  };

  useEffect(() => {
    const scalePointsCopy = JSON.parse(JSON.stringify(props.scalePoints));
    const pixelPoints = {};
    const distanceBetweenTwoScalePoints = scaleSize;
    let pixel = 14 + scaleSize / 2;

    scalePointsCopy.forEach((point: string) => {
      if (point == "0.00") {
      } else {
        pixel = pixel + scaleSize;
      }
      pixelPoints[point] = pixel;
      setLastPoint(point);
    });
    const ScalePoints = {
      pixelPoints: pixelPoints,
      distanceBetweenTwoScalePoints: distanceBetweenTwoScalePoints,
    };
    setScalePoint(ScalePoints);
    const newPoints = scalePointsCopy.map((point, index) => (
      <Point
        scaleSize={scaleSize}
        point={point}
        key={index}
        scene={props.scene}
        setTime={props.setTime}
        setPlaybackSeeked={props.setPlaybackSeeked}
        mode={props.mode}
      />
    ));
    setPoints(newPoints);
    reRenderAllClips(true);
  }, [props.scalePoints, scaleSize]);

  useEffect(() => {
    if (updateScalePoint) {
      updateScale(false);
    } else {
      return;
    }

    for (
      let second = Number(Number(lastPoint).toFixed(0));
      second < props.scene.duration + 3;
      second++
    ) {
      for (let index = 0; index < 10; index++) {
        if (
          Number(lastPoint) < Number(Number(second).toFixed(0) + "." + index)
        ) {
          const newPoint = getNewPoint(
            Number(Number(second).toFixed(0) + "." + index).toFixed(2),
          );
          points.push(newPoint);
          scalePoint.pixelPoints[
            Number(Number(second).toFixed(0) + "." + index).toFixed(2)
          ] =
            scalePoint.pixelPoints[
            Object.keys(scalePoint.pixelPoints).slice(
              Object.keys(scalePoint.pixelPoints).length - 1,
            )[0]
            ] + scaleSize;
          setLastPoint(
            (prevLastPoint) => Number(second).toFixed(0) + "." + index,
          );
        }
      }
    }

    setScaleRenderingProgress("completed");
  }, [updateScalePoint]);

  return (
    <>
      <MainScale>
        {points.map((component, index) => (
          <span key={index}>{component}</span>
        ))}
      </MainScale>
    </>
  );
};
export default Scale;
