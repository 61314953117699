const Next = (props: any) => {
    const color = props.color ?? '#999999'
    const width = props.width || 16;
    return (
        <svg width={width} height={width} viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4625 8.43157L1.54023 15.3924C1.3448 15.5472 1.09213 15.5555 0.89048 15.4211C0.685069 15.2851 0.558105 15.0253 0.558105 14.742V0.82046C0.558105 0.537243 0.685069 0.277374 0.89048 0.141421C0.98506 0.078702 1.09087 0.0470152 1.19543 0.0470152C1.31492 0.0470152 1.43567 0.0878372 1.54023 0.170135L10.4625 7.13092C10.6442 7.27289 10.755 7.5184 10.755 7.78125C10.755 8.04409 10.6442 8.28953 10.4625 8.43157ZM1.83269 2.23741V13.3251L8.93892 7.78125L1.83269 2.23741Z" fill={color} />
            <path d="M20.6592 8.43157L11.737 15.3924C11.5416 15.5472 11.2889 15.5555 11.0873 15.4211C10.8818 15.2851 10.7549 15.0253 10.7549 14.742V0.82046C10.7549 0.537243 10.8818 0.277374 11.0873 0.141421C11.1818 0.078702 11.2876 0.0470152 11.3922 0.0470152C11.5117 0.0470152 11.6325 0.0878372 11.737 0.170135L20.6592 7.13092C20.841 7.27289 20.9518 7.5184 20.9518 7.78125C20.9518 8.04409 20.841 8.28953 20.6592 8.43157ZM12.0295 2.23741V13.3251L19.1357 7.78125L12.0295 2.23741Z" fill={color} />
        </svg>
    )
}
export default Next