import { Box, CircularProgress, styled } from "@mui/material";

const Overlay = styled(Box)({
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  zIndex: 10,
  backgroundColor: "#0000006b",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
});
const TimelineLoader = (props: any) => {
  return (
    <Overlay className="overlay" sx={{ zIndex: props.zIndex ?? 10 }}>
      {props.loader && <CircularProgress />}
    </Overlay>
  );
};
export default TimelineLoader;
