import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";

import { useTimer } from "@layerhub-io/use-timer";
import { fabric } from "fabric";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import {
  ILayer,
  IStaticImage,
  IStaticVideo,
} from "src/@core/context/DesignEditorContext/layers";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";
import { updateStaticLayer } from "src/libs/utils";
import { closestObject } from "src/utils/closest";

import AudioTimeline from "./AudioTimeline";
import CameraTimeline from "./CameraTimeline";
import ImageTimeline from "./ImageTimeline";
import Scale from "./Scale";
import TextTimeline from "./TextTimeline";
import TimelineLoader from "./TimelineLoader";
import TimelineMarker from "./TimelineMarker";
import VideoTimeline from "./VideoTimeline";
import VideoTimelineControl from "../VideoTimelineController";
import { AddCircleOutline, AddOutlined } from "@mui/icons-material";

const Composer = styled("div")({
  overflow: "auto",
  // maxHeight: '30vh',
  // minHeight: '30vh',
  height: "100%",
  display: "block",
  position: "relative",
});

const MasterTimeline = (props: any) => {
  const router = useRouter();
  const { time, pause, reset } = useTimer();
  const { setTime, status } = useTimer();
  const composerRef = useRef(null);
  const {
    scene,
    setForceReloadFrames,
    setScene,
    setVideoEditorMode,
    setScalePoint,
    currentLayer,
    setCurrentLayer,
    reRenderState,
    reRenderTimeline,
    scalePoint,
    scaleSize,
    reRenderAllClips,
    reRenderClips,
    setPlaybackSeeked,
    fitTimeline,
    setFitTimeline,
    setScaleSize,
    scaleRenderingProgress,
    layersChanged,
    setLayersChanged,
    setLayersAddedDeleted,
    forceReloadFrames,
    displayPlayback,
    setDeletedLayerId,
    sceneParsedOrRendered,
    selectedVideoType,
    videoEditorMode,
    selectedCameraKeyframeId,
  } = useDesignEditorContext();
  const {
    canvas,
    drawVideo,
    drawImage,
    drawText,
    canvasLoader,
    canvasWidth,
    canvasHeight,
    reAlignObjectsBasedOnSize,
    setOldFrame,
    stopScaling,
  } = useCanvasContext();
  const { project, projectRoles, setProject, setProjectRoles } =
    useProjectContext();

  const [scalePoints, setScalePoints] = useState([]);
  const [videoLayers, setVideoLayer] = useState([]);
  const [cameraShotCurves, setCameraShotCurves] = useState([]);
  const [audioLayers, setAudioLayer] = useState([]);
  const [imageLayers, setImageLayer] = useState([]);
  const [textLayers, setTextLayer] = useState([]);
  const [updateLayer, setUpdateLayer] = useState(false);
  const [timelineMarkerHeight, setTimelineMarketHeight] = useState(0);
  const [forceUpdateScale, setForceUpdateScale] = useState(false);

  const getImageMetadata = async (url: string) => {
    return new Promise<{ width: number; height: number; url: string }>(
      (resolve, reject) => {
        const img = document.createElement("img");
        img.onload = () => {
          resolve({ width: img.width, height: img.height, url });
        };
        img.onerror = reject;
        img.src = url;
      },
    );
  };

  // Initializing ScalePoint object. ScalePoint is used to track pixel points for the seconds of the timeline.
  const setClipWidth = (layers: any, isVideoLayer = false) => {
    if (Object.keys(scalePoint?.pixelPoints || {}).length > 0) {
      layers.forEach((layer: any) => {
        if (isVideoLayer) {
          const selectedCameraView: string = layer[selectedVideoType]
            ? selectedVideoType
            : "videoWideAngle";
          if (
            layer[selectedCameraView].isDeletable == undefined ||
            layer[selectedCameraView].isDeletable == null
          ) {
            layer[selectedCameraView].isDeletable = true;
          }
          layer[selectedCameraView].startingPointPixel = closestObject(
            scalePoint?.pixelPoints,
            Number(layer[selectedCameraView].display.from).toFixed(2),
          );

          if (
            layer[selectedCameraView].display.to ==
            Object.keys(scalePoint?.pixelPoints).length
          )
            layer[selectedCameraView].endingPointPixel = closestObject(
              scalePoint?.pixelPoints,
              scene.duration.toFixed(2),
            );
          else
            layer[selectedCameraView].endingPointPixel = closestObject(
              scalePoint?.pixelPoints,
              Number(layer[selectedCameraView].display.to).toFixed(2),
            );
          layer[selectedCameraView].pixelWidth =
            layer[selectedCameraView].endingPointPixel -
            layer[selectedCameraView].startingPointPixel;
        } else {
          if (layer.isDeletable == undefined || layer.isDeletable == null) {
            layer.isDeletable = true;
          }
          layer.startingPointPixel = closestObject(
            scalePoint?.pixelPoints,
            Number(layer.display.from).toFixed(2),
          );

          if (layer.display.to == Object.keys(scalePoint?.pixelPoints).length)
            layer.endingPointPixel = closestObject(
              scalePoint?.pixelPoints,
              scene.duration.toFixed(2),
            );
          else
            layer.endingPointPixel = closestObject(
              scalePoint?.pixelPoints,
              Number(layer.display.to).toFixed(2),
            );
          layer.pixelWidth = layer.endingPointPixel - layer.startingPointPixel;
        }
      });
    }
  };

  useEffect(() => {
    setScalePoints([]);
    setScalePoint({});
    createScalePoints();
  }, [scaleSize]);

  useEffect(() => {
    if (forceUpdateScale) {
      setScalePoints([]);
      setScalePoint({});
      createScalePoints();
      setForceUpdateScale(false);
    }
  }, [forceUpdateScale]);

  useEffect(() => {
    setFitTimeline(true);
    updateStaticLayer(scene, setFitTimeline, selectedVideoType);
  }, [scene]);

  useEffect(() => {
    if (fitTimeline) {
      const length = Number(scene?.duration) * 10 + 1;
      const value = Number((props.width / length).toFixed(2));
      const size = value < 10 ? 10 : value > 100 ? 100 : value;
      setScaleSize(size);
      if (size == scaleSize) {
        setForceUpdateScale(true);
      }
      setFitTimeline(false);
    }
  }, [fitTimeline]);

  useEffect(() => {
    const layers = getCurrentVideoLayers() as Array<ILayer>;
    if (layers) {
      setClipWidth(layers, true);
      setVideoLayer(layers);
    }
  }, [scene?.videoLayers, scalePoint?.pixelPoints]);

  useEffect(() => {
    const layers = scene?.audioLayers as Array<ILayer>;
    if (layers) {
      setClipWidth(layers);
      setAudioLayer(layers);
    }
  }, [scene?.audioLayers, scalePoint?.pixelPoints, scaleRenderingProgress]);

  useEffect(() => {
    const layers = scene?.staticTextLayers as Array<ILayer>;
    if (layers) {
      setClipWidth(layers);
      setTextLayer(layers);
    }
  }, [scene?.staticTextLayers, scalePoint?.pixelPoints]);

  useEffect(() => {
    const layers = scene?.staticImageLayers as Array<ILayer>;
    if (layers) {
      setClipWidth(layers);
      setImageLayer(layers);
    }
  }, [scene?.staticImageLayers, scalePoint?.pixelPoints]);

  useEffect(() => {
    if (layersChanged) {
      updateStaticLayer(scene, setFitTimeline, selectedVideoType);
      setLayersChanged(false);
      setTimeout(() => {
        setForceReloadFrames(true);
        setVideoEditorMode("VIEW");
      }, 2000);
    }
  }, [layersChanged]);

  const createScalePoints = () => {
    const newScalePoints = [];
    let maxScalePoints = 0;
    if (scene && props.mode == "SHOT-DESIGN") {
      const maxDuration = Math.max(
        ...scene.cameraShotCurves.map((curve) => curve.duration || 0)
      );
      maxScalePoints = (maxDuration || 6) + 1 / 10;
      for (let point = 0; point <= maxScalePoints; point += 1 / 10) {
        newScalePoints.push(point.toFixed(2));
      }
      setScalePoints(newScalePoints);
    } else if (scene && scene.duration) {
      maxScalePoints = scene.duration + 1 / 10;
      if (scene.parseSceneResponse) {
        const originalDurationFrames = Math.ceil(
          scene.parseSceneResponse.original_duration_frames / 24,
        );
        if (maxScalePoints < originalDurationFrames)
          maxScalePoints = originalDurationFrames;
      }
      for (let point = 0; point <= maxScalePoints; point += 1 / 10) {
        newScalePoints.push(point.toFixed(2));
      }
      setScalePoints(newScalePoints);
    }
  };

  const extentScalePoints = () => {
    const newScalePoints = [];
    let maxScalePoints = Number(scalePoints[scalePoints.length - 1]);
    maxScalePoints += 3;
    for (let point = 0; point <= maxScalePoints; point += 1 / 10) {
      newScalePoints.push(point.toFixed(2));
    }
    setScalePoints(newScalePoints);

  }

  const alignLayersInCanvas = (scene: any) => {
    scene?.staticTextLayers.forEach((textLayer) => {
      if (
        Number(textLayer.display.to) > 0 &&
        Number(textLayer.display.from) <= 0
      ) {
        drawText(textLayer as IStaticImage);
      }
    });

    scene?.staticImageLayers.forEach((imageLayer) => {
      if (
        Number(imageLayer.display.to) > 0 &&
        Number(imageLayer.display.from) <= 0
      ) {
        drawImage(imageLayer as IStaticImage);
      }
    });

    getCurrentVideoLayers().forEach((videoLayer) => {
      const selectedCameraView = videoLayer[selectedVideoType]
        ? selectedVideoType
        : "videoWideAngle";
      if (
        Number(videoLayer[selectedCameraView].display.to) > 0 &&
        Number(videoLayer[selectedCameraView].display.from) <= 0
      ) {
        drawVideo(videoLayer[selectedCameraView] as IStaticVideo);
      }
    });

    setTimeout(() => {
      reAlignObjectsBasedOnSize(true);
      stopScaling();
    }, 500);
  };

  const getCurrentVideoLayers = () => {
    return (scene?.videoLayers || []).filter((l) => l[selectedVideoType]);
  };

  const initialScaleCalculationForDefaultLayers = async () => {
    scene!.frame = {
      width: canvasWidth,
      height: canvasHeight,
    };
    const videoPromises = getCurrentVideoLayers().map(async (videoLayer) => {
      const cameraView = videoLayer[selectedVideoType]
        ? selectedVideoType
        : "videoWideAngle";
      // if (!videoLayer.width) {
      const { width, height, url } = await getImageMetadata(
        videoLayer[cameraView].preview,
      );
      const widthFactor = canvasWidth / width;
      const heightFactor = canvasHeight / height;
      const minFactor = Math.min(widthFactor, heightFactor);
      videoLayer[cameraView].width = width;
      videoLayer[cameraView].height = height;
      videoLayer[cameraView].scaleX = minFactor;
      videoLayer[cameraView].scaleY = minFactor;
      // }
    });

    const imagePromises = (scene?.staticImageLayers || []).map(
      async (staticImageLayer) => {
        if (staticImageLayer.subType == "Produced_By") {
          const { width, height, url } = await getImageMetadata(
            staticImageLayer.src,
          );
          const widthFactor = canvasWidth / width;
          const heightFactor = canvasHeight / height;
          const maxFactor = Math.max(widthFactor, heightFactor);
          const scaleFactor = Math.min(1, maxFactor);
          const scaledWidth = width * scaleFactor;
          const scaledHeight = height * scaleFactor;
          staticImageLayer.width = width;
          staticImageLayer.height = height;
          staticImageLayer.scaleX = scaleFactor;
          staticImageLayer.scaleY = scaleFactor;
          staticImageLayer.left = (canvasWidth - scaledWidth) / 2;
          staticImageLayer.top = (canvasHeight - scaledHeight) / 2;
        }
      },
    );

    await Promise.all([...videoPromises, ...imagePromises]);

    for (const staticTextLayer of scene?.staticTextLayers || []) {
      if (staticTextLayer.subType == "Directed_By") {
        // Create a fabric text object
        const text = new fabric.Text(staticTextLayer.text, {
          textAlign: "center", // Center the text horizontally
          originX: "center", // Set the origin point to the center horizontally
          originY: "center", // Set the origin point to the center vertically
        });

        staticTextLayer.left = canvasWidth / 2 - text.width / 2;
        staticTextLayer.top = canvasHeight / 2 - text.height / 2;
      }
    }

    setScene({ scene: scene, updateSceneInDB: false });

    setTimeout(() => {
      alignLayersInCanvas(scene);
    }, 1000);
  };

  useEffect(() => {
    const asyncEffect = async () => {
      await initialScaleCalculationForDefaultLayers();
    };
    if (canvasWidth && scene) asyncEffect();
  }, [canvasWidth, scene]);

  const layerUpdated = () => {
    canvas?.clear();
    canvas?.renderAll();
    setUpdateLayer(true);
  };

  useEffect(() => {
    if (scene) {
      setOldFrame({ width: scene.frame?.width, height: scene.frame?.height });
    }
  }, [scene]);

  useEffect(() => {
    if (reRenderState) {
      reRenderTimeline(false);
    }
  }, [reRenderState]);

  useEffect(() => {
    setClipWidth(getCurrentVideoLayers(), true);
    setVideoLayer(getCurrentVideoLayers());
    setCameraShotCurves(scene?.cameraShotCurves);
    setClipWidth(scene?.audioLayers);
    setAudioLayer(scene?.audioLayers);
    setClipWidth(scene?.staticImageLayers);
    setImageLayer(scene?.staticImageLayers);
    setClipWidth(scene?.staticTextLayers);
    setTextLayer(scene?.staticTextLayers);
    if (reRenderClips) {
      reRenderAllClips(false);
    }
  }, [scalePoint, reRenderClips]);

  const handleKeyPress = (event) => {
    const scriptContainer = document.getElementById("script-container");
    let isCursorInDiv;

    if (scriptContainer)
      isCursorInDiv = scriptContainer.contains(document.activeElement);

    if (
      !isCursorInDiv &&
      currentLayer?.type != "Camera" &&
      videoEditorMode != "EDIT" &&
      currentLayer?.isDeletable &&
      (event.key === "Backspace" || event.key === "Delete")
    ) {
      setDeletedLayerId(currentLayer.id);
      handleClick();
      const object =
        canvas?._objects.find((o) => o["id"] == currentLayer.id) ||
        new fabric.Object();
      canvas?.remove(object);
      setForceReloadFrames(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentLayer, videoEditorMode]);

  const handleClick = () => {
    switch (currentLayer?.type) {
      case "StaticVideo":
        const updatedVideoLayer = scene.videoLayers.filter(
          (item) =>
            ![
              item["videoWideAngle"]?.id,
              item["video360"]?.id,
              item["videoPrevis"]?.id,
            ].includes(currentLayer.id),
        );
        scene.videoLayers = updatedVideoLayer;
        updateStaticLayer(scene, setFitTimeline, selectedVideoType);
        setScene(scene);
        setVideoLayer(getCurrentVideoLayers());
        break;
      case "StaticImage":
        const updatedImageLayer = scene.staticImageLayers.filter(
          (item) => item.id !== currentLayer.id,
        );
        scene.staticImageLayers = updatedImageLayer;
        updateStaticLayer(scene, setFitTimeline, selectedVideoType);
        setScene(scene);
        setImageLayer(scene.staticImageLayers);
        break;
      case "StaticText":
        const updatedTextLayer = scene.staticTextLayers.filter(
          (item) => item.id !== currentLayer.id,
        );
        scene.staticTextLayers = updatedTextLayer;
        updateStaticLayer(scene, setFitTimeline, selectedVideoType);
        setScene(scene);
        setTextLayer(scene.staticTextLayers);
        break;
      case "StaticAudio":
        const updatedAudioLayer = scene.audioLayers.filter(
          (item) => item.id !== currentLayer.id,
        );
        scene.audioLayers = updatedAudioLayer;
        updateStaticLayer(scene, setFitTimeline, selectedVideoType);
        setScene(scene);
        setAudioLayer(scene.audioLayers);
        break;
      case "Camera":
        const shotCurveIndex = scene?.cameraShotCurves.findIndex(
          (item) => item.id !== currentLayer.id,
        );
        scene.cameraShotCurves[shotCurveIndex].isDeleted = true;
        updateStaticLayer(scene, setFitTimeline, selectedVideoType);
        setScene(scene);
        setCameraShotCurves(scene?.cameraShotCurves);
        break;
      default:
        break;
    }
    setUpdateLayer(true);
    setLayersAddedDeleted(true);
    setForceReloadFrames(true);
    setTimeout(() => {
      setVideoEditorMode("VIEW");
    }, 2000);
    // layerUpdated()
  };

  useEffect(() => {
    setTimelineMarketHeight(composerRef?.current?.scrollHeight);
  }, [scene]);

  useEffect(() => {
    if (composerRef.current) {
      const value = Math.floor((time / 1000).toFixed(2) * 10) / 10;
      let timeValue = "";
      if (value % 1 !== 0) {
        timeValue = `${value}0`;
      } else {
        timeValue = `${value}.00`;
      }

      const scrollThreshold = composerRef?.current?._container.offsetWidth;
      const maxScrollableWidth =
        composerRef?.current?._container.scrollWidth -
        composerRef?.current?._container.offsetWidth;
      const videoPlaybackTime = time / (scene?.duration * 1000);
      // Calculate the marker position based on the video playback time and duration
      const markerPosition =
        scalePoint &&
          scalePoint?.pixelPoints &&
          Object.keys(scalePoint?.pixelPoints).length > 0
          ? scalePoint?.pixelPoints[Number(timeValue).toFixed(2)]
          : 0;
      // Calculate the scroll position to keep the marker within the visible area
      // Calculate the container's visible area range
      const visibleStart = composerRef?.current._container.scrollLeft;
      const visibleEnd =
        visibleStart + composerRef?.current._container.offsetWidth;

      // Check if the marker is within the visible area plus the scroll threshold
      if (markerPosition < visibleStart || markerPosition > visibleEnd) {
        // Calculate the scroll position to keep the marker within the visible area
        let scrollPosition =
          markerPosition - composerRef?.current._container.offsetWidth / 2;

        // Adjust the scroll position to ensure it stays within the scrollable range
        scrollPosition = Math.max(
          0,
          Math.min(scrollPosition, maxScrollableWidth),
        );

        // Scroll the container to the calculated position
        composerRef.current._container.scrollLeft = scrollPosition;
      }
    }
  }, [time]);

  const makeSelectedKeyFrameActive = () => {
    const shotCurveIndex = scene?.cameraShotCurves.findIndex(csc => !!csc.canvasKeyframes.find(ckf => ckf.id == selectedCameraKeyframeId))
    const keyFrameIndex = scene?.cameraShotCurves[shotCurveIndex]?.canvasKeyframes.findIndex(ckf => ckf.id == selectedCameraKeyframeId);
    scene.cameraShotCurves[shotCurveIndex].canvasKeyframes[keyFrameIndex].isActive = true;
    if (keyFrameIndex > 0) {
      const currentSeconds = scene.cameraShotCurves[shotCurveIndex].canvasKeyframes[keyFrameIndex].seconds;
      const previousSeconds = scene.cameraShotCurves[shotCurveIndex].canvasKeyframes[keyFrameIndex - 1].seconds;
      const currentShotCurveId = scene.cameraShotCurves[shotCurveIndex].id;
      scene.cameraShotCurves.filter(csc => csc.id != currentShotCurveId && csc.canvasKeyframes.length)
        .forEach(csc => {
          csc.canvasKeyframes = csc.canvasKeyframes.map((ckf: any, i: number) => {
            if ((ckf.seconds < currentSeconds && ckf.seconds > previousSeconds) || (ckf.seconds == currentSeconds) || (currentSeconds < ckf.seconds && currentSeconds > csc.canvasKeyframes[i - 1].seconds))
              return { ...ckf, isActive: false }
            return ckf;
          })
        })
    } else if (keyFrameIndex == 0) {
      const currentShotCurveId = scene.cameraShotCurves[shotCurveIndex].id;
      scene.cameraShotCurves.filter(csc => csc.id != currentShotCurveId && csc.canvasKeyframes.length)
        .forEach(csc => {
          csc.canvasKeyframes[0].isActive = false
        })
    }
    setScene({ ...scene });
    reRenderTimeline(true);
    reRenderAllClips(true);
  }

  return (
    <>
      {scene && (
        <div style={{ position: "relative", height: "max-content" }}>
          <Grid container>
            <Grid item md={3} />
            <Grid item md={6} display="flex" justifyContent="center" alignItems="center" columnGap={12}>
              {props.mode == "SHOT-DESIGN" && <Button disabled={!selectedCameraKeyframeId} onClick={makeSelectedKeyFrameActive} size="small" variant="outlined" type="button" sx={{ padding: '0 0.8rem', fontSize: '0.8rem', height: '25px', borderWidth: '2px', mt: 2 }}>Make Active</Button>}
              {props.mode == 'SHOT-DESIGN' && <VideoTimelineControl mode={props.mode} />}
            </Grid>
            <Grid item md={3}>
              <Box display="flex" justifyContent="end" alignItems="center" mt={1}>
                {props.mode == "SHOT-DESIGN" && <Button disabled={props.publishInProgress} onClick={props.publishShotCurves} size="small" variant="outlined" type="button" sx={{ padding: '0 0.8rem', fontSize: '0.7rem', height: '20px', color: "text.secondary", borderColor: "text.secondary", }}>{props.publishInProgress ? 'Rendering...' : 'Render'}</Button>}
                <Select
                  sx={{
                    fontSize: "11px",
                    width: "75px",
                    ".MuiSelect-select": { p: 1, pr: 0 },
                    m: 2,
                  }}
                  defaultValue="25"
                  onChange={(e: SelectChangeEvent) => {
                    setScaleSize(Number(e.target.value));
                  }}
                >
                  <MenuItem value="25">25%</MenuItem>
                  <MenuItem value="50">50%</MenuItem>
                  <MenuItem value="75">75%</MenuItem>
                  <MenuItem value="100">100%</MenuItem>
                </Select>
              </Box>
            </Grid>
            {props.mode == "SHOT-DESIGN" && <Grid item md={12} display="flex" justifyContent="flex-end" pr={1}>
              <ButtonBase onClick={extentScalePoints}><AddCircleOutline sx={{ color: "text.secondary" }} /></ButtonBase>
            </Grid>}
          </Grid>

          {(props.showLoader ||
            props.disableButton ||
            sceneParsedOrRendered) && <TimelineLoader loader={true} />}
          <Composer>
            <PerfectScrollbar ref={composerRef}>
              {(props.mode == "COMPOSER" || props.mode == "SHOT-DESIGN") && <TimelineMarker mode={props.mode} />}
              <Box sx={{ width: "fit-content", height: "100%" }}>
                <div id="timelineScale">
                  <Scale
                    scene={scene}
                    key={scene.id}
                    scalePoints={scalePoints}
                    setTime={setTime}
                    setPlaybackSeeked={setPlaybackSeeked}
                    mode={props.mode}
                  />
                </div>
                <Box sx={{ position: "relative", height: "100%" }}>
                  <>
                    {(projectRoles?.includes("Viewer") ||
                      canvasLoader ||
                      displayPlayback) && (
                        <TimelineLoader loader={false} zIndex={1} />
                      )}

                    <Divider
                      sx={{
                        "& .MuiDivider-wrapper": { px: 4 },
                        mt: (theme) => `${theme.spacing(2)} !important`,
                        mb: (theme) => `${theme.spacing(3)} !important`,
                      }}
                    ></Divider>
                    <Accordion
                      expanded={props.layersVisibility}
                      style={{
                        margin: 0,
                        boxShadow: "none",
                      }}
                    >
                      <AccordionSummary
                        style={{ display: "none" }}
                      ></AccordionSummary>
                      {props.mode == "COMPOSER" && (
                        <>
                          <div id="textTimeLine">
                            <TextTimeline
                              textLayers={textLayers}
                              width={props.width}
                              deleteClip={handleClick}
                            />
                          </div>
                          <div id="imageTimeLine">
                            <ImageTimeline
                              imageLayers={imageLayers}
                              width={props.width}
                              deleteClip={handleClick}
                            />
                          </div>
                          <div id="audioTimeLine">
                            <AudioTimeline
                              audioLayers={audioLayers}
                              width={props.width}
                              deleteClip={handleClick}
                            />
                          </div>
                        </>
                      )}
                    </Accordion>
                    {props.mode == "COMPOSER" && (
                      <>
                        <div id="videoTimeLine">
                          <VideoTimeline
                            videoLayers={videoLayers}
                            width={props.width}
                            deleteClip={handleClick}
                          />
                        </div>
                      </>
                    )}
                    {props.mode == "SHOT-DESIGN" && (
                      <>
                        <div id="cameraTimeLine">
                          <CameraTimeline
                            cameraShotCurves={cameraShotCurves}
                            width={props.width}
                            deleteClip={handleClick}
                            editor={props?.editor}
                            setSelectedLayerId={props?.setSelectedLayerId}
                            setMinimizedElements={props?.setMinimizedElements}
                            layersVisibility={props.layersVisibility}
                          />
                        </div>
                      </>
                    )}
                  </>
                </Box>
              </Box>
            </PerfectScrollbar>
          </Composer>
        </div>
      )}
    </>
  );
};
export default MasterTimeline;
