import { createSlice } from "@reduxjs/toolkit";

export const AssetLibraryActiveTabs = {
  CharacterTab: 1,
  AssetsTab: 2,
  RPM: 3,
  ActionTab: 4,
  All: 5,
  PCG: 6,
};

export const scriptsSlice = createSlice({
  name: "scriptsSlice",
  initialState: {
    sceneScript: "",
    assetTagFilter: "lowpoly",
    isChangeActorsAndObjectsEnabled: true,
    assetLibraryActiveTab: AssetLibraryActiveTabs.CharacterTab,
    parseSceneObject: null,
    parseSceneActor: {},
    shaDigestChangeValue: "",
    toScrollEvent: true,
    selectedObjectActorText: "",
    selectedObjectActorParsedText: "",
    avatarURL: "",
    selectedParsedRpmAvatar: null,
    selectedParsedObject: null,
    isLLMEnabled: false,
  },
  reducers: {
    setSceneScript: (state, action) => {
      state.sceneScript = action.payload;
    },
    setObjectAssetTagFilter: (state, action) => {
      state.assetTagFilter = action.payload;
    },
    reducerSetIsChangeActorsAndObjectsEnabled: (state, action) => {
      state.isChangeActorsAndObjectsEnabled = action.payload;
    },
    reducerSetParseSceneObject: (state, action) => {
      state.parseSceneObject = action.payload;
    },
    reducerSetParseSceneActor: (state, action) => {
      state.parseSceneActor = action.payload;
    },
    reducerSetssetLibraryActiveTab: (state, action) => {
      state.assetLibraryActiveTab = action.payload;
    },
    reducerSetShaDigestChangeValue: (state, action) => {
      state.shaDigestChangeValue = action.payload;
    },
    reducerSetToScrollEvent: (state) => {
      state.toScrollEvent = !state.toScrollEvent;
    },
    reducerSetSelectedObjectActorText: (state, action) => {
      state.selectedObjectActorText = action.payload;
    },
    reducerSetAvatarURL: (state, action) => {
      state.avatarURL = action.payload;
    },
    reducerSetSelectedObjectActorParsedText: (state, action) => {
      state.selectedObjectActorParsedText = action.payload;
    },
    reducerSetParsedRpmAvatar: (state, action) => {
      state.selectedParsedRpmAvatar = action.payload;
    },
    reducerSetParsedObject: (state, action) => {
      state.selectedParsedObject = action.payload;
    },
    reducerSetLLMEnabled: (state, action) => {
      state.isLLMEnabled = action.payload;
    },
  },
});
export const {
  reducerSetSelectedObjectActorParsedText,
  reducerSetParsedRpmAvatar,
  reducerSetParsedObject,
  reducerSetLLMEnabled,
  reducerSetSelectedObjectActorText,
  reducerSetAvatarURL,
  reducerSetToScrollEvent,
  setSceneScript,
  setObjectAssetTagFilter,
  reducerSetIsChangeActorsAndObjectsEnabled,
  reducerSetParseSceneObject,
  reducerSetParseSceneActor,
  reducerSetssetLibraryActiveTab,
  reducerSetShaDigestChangeValue,
} = scriptsSlice.actions;
export default scriptsSlice.reducer;
