import { styled } from "@mui/material";

import SelectedClip from "public/images/pages/icons/SelectedClip";

const LeftHandle = styled("div")({
  height: "100%",
  backgroundColor: "#00ACEE",
  borderTopLeftRadius: "7px",
  borderBottomLeftRadius: "7px",
  position: "absolute",
  left: "-5px",
  width: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "10",
});

const RightHandle = styled("div")({
  height: "100%",
  backgroundColor: "#00ACEE",
  borderTopRightRadius: "7px",
  borderBottomRightRadius: "7px",
  position: "absolute",
  right: "-5px",
  width: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "10",
});

export const ResizeStartComponent = () => {
  return (
    <LeftHandle>
      <SelectedClip />
    </LeftHandle>
  );
};

export const ResizeEndComponent = () => {
  return (
    <RightHandle>
      <SelectedClip />
    </RightHandle>
  );
};
