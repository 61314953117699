const BracketIcon = (props: any) => {
    const color = props.color ?? '#A7A7A7'
    return (
        <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_3363)">
                <path d="M17.5815 0.416016C21.3193 0.997423 24.672 6.96853 24.5244 11.7693C24.3749 16.6132 21.0801 22.0776 17.7441 22.3749C23.0741 15.0933 23.1676 7.87523 17.5815 0.416016Z" fill={color} />
                <path d="M7.4021 22.4156C3.80453 21.9968 0.534011 16.3921 0.530273 11.4717C0.530273 6.58861 3.78957 0.950266 7.30678 0.47168C2.04405 7.76638 1.76559 14.9713 7.4021 22.4156Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_8_3363">
                    <rect width="24" height="22" fill="white" transform="translate(0.52832 0.416016)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default BracketIcon