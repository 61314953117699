import { useVeltClient } from "@veltdev/react";
import * as Velt from "@veltdev/types";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { LayoutProps } from "src/@core/layouts/types";
import { generateVeltAuthToken } from "src/config/api";
import { useAuth } from "src/config/auth";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";

import HorizontalLayout from "./HorizontalLayout";
import VerticalLayout from "./VerticalLayout";

const Layout = (props: LayoutProps) => {
  const { children, settings } = props;

  const router = useRouter();
  const { userData } = useAuth();
  const { client } = useVeltClient();
  const { project } = useProjectContext();
  const { scene, sceneNumericId } = useDesignEditorContext();
  const [veltToken, setVeltToken] = useState<string>("");
  const [veltUser, setVeltUser] = useState<Velt.User | null>(null);

  useEffect(() => {
    if (scene && veltUser && client && veltToken) {
      client.updateUser(veltUser);
      let documentType = "";
      if (router.pathname.includes("script")) {
        documentType = "script";
      } else if (router.pathname.includes("previs")) {
        documentType = "previs";
      } else if (router.pathname.includes("storyboard")) {
        documentType = "storyboard";
      }
      client.setDocumentId(
        `${process.env.NODE_ENV}_${documentType}_${project?.id}_${sceneNumericId}`,
      );
    }
  }, [
    client,
    project?.id,
    router.pathname,
    scene,
    sceneNumericId,
    veltToken,
    veltUser,
  ]);

  useEffect(() => {
    if (!project?.id || !userData) return;

    const veltUserData: Velt.User = {
      userId: userData.user.id.toString(),
      name: `${userData.user.firstName} ${userData.user.lastName}`,
      email: userData.user.email,
      photoUrl: userData.user.profilePictureLink,
    };

    (async () => {
      try {
        const response = await generateVeltAuthToken(project?.id);
        await client.identify(veltUserData, { authToken: response.token });
        setVeltToken(response.token);
        setVeltUser(veltUserData);
      } catch (error) {
        console.error("Failed to authenticate with Velt", error);
      }
    })();
  }, [client, project?.id, userData]);

  if (settings.layout === "horizontal") {
    return <HorizontalLayout {...props}>{children}</HorizontalLayout>;
  }

  return <VerticalLayout {...props}>{children}</VerticalLayout>;
};

export default Layout;
