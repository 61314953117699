import { Box, Button, styled } from "@mui/material";

import axios from "axios";
import { useRef } from "react";
import { v4 } from "uuid";

import { IStaticAudio } from "src/@core/context/DesignEditorContext/layers";
import { createAudioUploadUrl } from "src/config/api";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";

import AudioIcon from "public/images/pages/icons/AudioIcon";

const BackgroundLayer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const IconContainer = styled(Button)({
  width: "100%",
  flex: 1,
});

const Audio = (props: any) => {
  const {
    scene,
    setScene,
    scalePoint,
    updateScale,
    setLayersChanged,
    setLayersAddedDeleted,
  } = useDesignEditorContext();
  const fileUploadRef = useRef(null);
  const { projectRoles, project } = useProjectContext();
  const { canvasWidth, canvasHeight } = useCanvasContext();

  const addAudioFile = () => {
    fileUploadRef.current?.click();
  };

  const getAudioMetadata = async (url: string) => {
    return new Promise<{ duration: number; url: string }>((resolve, reject) => {
      const audio = document.createElement("audio");
      audio.onloadedmetadata = () => {
        resolve({ duration: audio.duration, url });
      };
      audio.onerror = reject;
      audio.src = url;
    });
  };

  const onAudioUpload = async (e) => {
    props.setLoaderVisibility(true);

    const file = e.target.files[0];
    if (!file) return;

    const createAudioUploadUrlResp = await createAudioUploadUrl({
      projectId: project.id,
      fileName: file.name,
      contentType: file.type,
    });
    await axios.put(createAudioUploadUrlResp.uploadUrl, file, {
      headers: createAudioUploadUrlResp.headers,
    });
    const url = createAudioUploadUrlResp.destObjectUrl;
    const audioMetadata = await getAudioMetadata(url);

    const audioLayer: IStaticAudio = {
      id: v4(),
      type: "StaticAudio",
      src: audioMetadata.url,
      name: file.name,
      preview: "",
      display: {
        from: 0,
        to: Math.ceil(audioMetadata.duration),
      },
      cut: {
        from: 0,
        to: Math.ceil(audioMetadata.duration),
      },
      width: canvasWidth,
      height: canvasHeight,
      duration: Math.ceil(audioMetadata.duration),
      isDeletable: true,
    };

    // if(Number(scene?.duration) < Number(audioLayer.duration)){
    scene.duration = audioLayer.duration;
    // }

    updateScale(true);
    audioLayer.startingPointPixel =
      scalePoint?.pixelPoints[audioLayer.display.from];
    audioLayer.pixelWidth =
      scalePoint?.distanceBetweenTwoScalePoints * audioLayer.duration;
    audioLayer.endingPointPixel =
      scalePoint?.pixelPoints[audioLayer.display.to];

    scene?.audioLayers.push(audioLayer);
    // if(Number(scene?.duration) < Number(audioLayer.duration)){
    scene.duration = audioLayer.duration;
    // }
    fileUploadRef.current!.value = null;
    setLayersChanged(true);
    setLayersAddedDeleted(true);
    props.setLoaderVisibility(false);
    setScene(scene);
  };

  return (
    <IconContainer onClick={() => addAudioFile()}>
      <Box>
        <BackgroundLayer>
          {/* <RectangleBackgroundLayer /> */}
          <AudioIcon />
          <input
            type="file"
            ref={fileUploadRef}
            id="fabric-audio-upload"
            accept="audio/*"
            onChange={onAudioUpload}
            style={{ display: "none" }}
          />
        </BackgroundLayer>
      </Box>
    </IconContainer>
  );
};

export default Audio;
