import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Grid, styled, Tab } from "@mui/material";
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  VeltComments,
  VeltCommentsSidebar,
  VeltCursor,
  VeltHuddle,
  VeltProvider,
} from "@veltdev/react";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";

import Spinner from "src/@core/components/spinner";
import { useSettings } from "src/@core/hooks/useSettings";
import Layout from "src/@core/layouts/Layout";
import { withdrawRenderRequestToQueue } from "src/config/api";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";
import HorizontalAppBarContent from "src/layouts/components/horizontal/AppBarContent";
import ServerSideHorizontalNavItems from "src/layouts/components/horizontal/ServerSideNavItems";
import VerticalAppBarContent from "src/layouts/components/vertical/AppBarContent";
import ServerSideVerticalNavItems from "src/layouts/components/vertical/ServerSideNavItems";

import Timeline from "./Storyboard/Timeline";

interface Props {
  children: ReactNode;
  contentHeightFixed?: boolean;
  getTopNavMenu: () => void;
}

const LowerLayer = styled(Grid)({
  position: "relative",
  height: "auto",
});

const ProjectTab = styled(Tab)({
  whiteSpace: "nowrap !important",
  width: "fit-content !important",
  maxWidth: "fit-content !important",
  minWidth: "fit-content !important",
  minHeight: "fit-content !important",
  padding: "0 8px",
  fontSize: "13px",
});

export default function ProjectLayout(layoutProps: Props) {
  // ** Hooks
  const [showChildren, setVisibilityOfChildren] = useState(false);
  const { settings, saveSettings } = useSettings();
  const router = useRouter();
  const { scene, setScene, setSelectedLayerId, onTipTapEditor } =
    useDesignEditorContext();
  const [value, setValue] = useState<string>(router.asPath.split("/").pop());

  const [openDialog, setOpenDialog] = useState(false);
  const [timelineMode, setTimelineMode] = useState("COMPOSER");
  const { loadingScene } = useProjectContext();

  useEffect(() => {
    const currentRoute = router.asPath.split("/").pop();
    setValue(currentRoute);
    if (currentRoute === "previs") {
      setTimelineMode(null);
    } else if (currentRoute === "script") {
      setTimelineMode("COMPOSER");
    } else {
      setTimelineMode(null);
    }
  }, [router.route]);

  const routeTo = (route: string) => {
    router
      .push(
        `/projects/${router.query.id}/scene/${router.query.scene_id}/${route}`,
      )
      .then(() => setValue(route));
  };

  const getTopNavMenu = () => {
    return (
      <Box ml={4.5}>
        <TabContext value={value}>
          <TabList
            variant="fullWidth"
            aria-label="full width tabs example"
            sx={{
              minHeight: "fit-content !important",
              ".MuiTabs-scroller": {
                height: "26px !important",
              },
            }}
          >
            <ProjectTab
              value="script"
              label="Scripts"
              onClick={() => {
                routeTo("script");
              }}
            />
            {/* <ProjectTab value='previs' label='Previs' onClick={() => { routeTo('previs') }} /> */}
            {/* <ProjectTab value='shot-design' label='Shot Design' onClick={() => { routeTo('shot-design') }} /> */}
            <ProjectTab
              value="assets"
              label="Assets"
              onClick={() => {
                routeTo("assets");
              }}
            />
            <ProjectTab
              value="previs"
              label="Previs"
              onClick={() => {
                routeTo("previs");
              }}
            />
            <ProjectTab
              value="storyboard"
              label="Storyboard"
              onClick={() => {
                routeTo("storyboard");
              }}
            />
            <ProjectTab
              value="script-breakdown"
              label="Script Breakdown"
              onClick={() => {
                routeTo("script-breakdown");
              }}
            />
            <ProjectTab
              value="stripboard"
              label="Stripboard"
              onClick={() => {
                routeTo("stripboard");
              }}
            />
            {/* <ProjectTab value='view' label='View' onClick={() => { routeTo('view') }} /> */}
          </TabList>
        </TabContext>
      </Box>
    );
  };
  // ** Vars for server side navigation
  const { menuItems: verticalMenuItems } = ServerSideVerticalNavItems();
  const { menuItems: horizontalMenuItems } = ServerSideHorizontalNavItems();

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  if (hidden && settings.layout === "horizontal") {
    settings.layout = "vertical";
  }
  useEffect(() => {
    if (verticalMenuItems.length > 0) {
      setVisibilityOfChildren(true);
    }
  }, [verticalMenuItems]);

  const handleDialogClose = () => {
    setOpenDialog(false);
    scene!.status = "Withdraw";
    setScene({ scene: { ...scene }, updateSceneInDB: false });
  };

  const withdraw = async () => {
    try {
      const withdrawResp = await withdrawRenderRequestToQueue(scene?.id);
      if (withdrawResp.success) setOpenDialog(true);
    } catch (e) {}
  };

  return (
    <>
      <VeltHuddle />
      {!onTipTapEditor && <VeltComments darkMode={true} />}
      <VeltCommentsSidebar />
      {showChildren ? (
        <div>
          {!onTipTapEditor && <VeltCursor />}
          <Layout
            isTimelineVisible={!!timelineMode}
            hidden={hidden}
            settings={settings}
            saveSettings={saveSettings}
            contentHeightFixed={layoutProps.contentHeightFixed}
            verticalLayoutProps={{
              navMenu: {
                // navItems: VerticalNavItems()
                // Uncomment the below line when using server-side menu in vertical layout and comment the above line
                horizontalActiveTab: value,
                navItems: verticalMenuItems,
              },
              appBar: {
                content: (props) => (
                  <VerticalAppBarContent
                    hidden={hidden}
                    settings={settings}
                    saveSettings={saveSettings}
                    toggleNavVisibility={props.toggleNavVisibility}
                    getTopNavMenu={getTopNavMenu}
                  />
                ),
              },
            }}
            {...(settings.layout === "horizontal" && {
              horizontalLayoutProps: {
                navMenu: {
                  // navItems: HorizontalNavItems()

                  // Uncomment the below line when using server-side menu in horizontal layout and comment the above line
                  navItems: horizontalMenuItems,
                },
                appBar: {
                  content: () => (
                    <HorizontalAppBarContent
                      settings={settings}
                      saveSettings={saveSettings}
                    />
                  ),
                },
              },
            })}
          >
            {layoutProps.children}
          </Layout>{" "}
          {timelineMode && (
            <LowerLayer container spacing={5.5} className="hello match-height">
              <Grid item xs={12} md={12}>
                {loadingScene && (
                  <div
                    style={{
                      position: "absolute",
                      width: "98%",
                      height: "92%",
                      background: "black",
                      opacity: "50%",
                      zIndex: 9999,
                    }}
                  ></div>
                )}
                <Timeline
                  mode={timelineMode}
                  disableButton={false}
                  setSelectedLayerId={setSelectedLayerId}
                />
              </Grid>
            </LowerLayer>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}
