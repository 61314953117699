import { Box } from "@mui/material";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";

const KeyframeClip = (props: any) => {
  const { setSelectedCameraKeyframeId, selectedCameraKeyframeId, selectedLayerId, cameraSelection } = useDesignEditorContext();
  return (
    <Box onClick={() => cameraSelection && cameraSelection(props.layerId)}>
      <Box sx={{
        width: 10,
        height: 10,
        zIndex: 3,
        backgroundColor: props.layerId == selectedLayerId ? '#4DA9E8' : '#333539',
        top: '50%',
        position: 'absolute',
        left: props.pixelPoints[props.seconds.toFixed(2)],
        transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
        cursor: 'pointer'
      }}
        onClick={() => setSelectedCameraKeyframeId(props.id)} />
      {selectedCameraKeyframeId == props.id && <Box sx={{
        width: 12,
        height: 12,
        zIndex: 1,
        backgroundColor: 'white',
        top: '50%',
        position: 'absolute',
        left: props.pixelPoints[props.seconds.toFixed(2)],
        transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
        cursor: 'pointer'
      }} />}
      {props.isActive && <Box sx={{
        width: 12,
        height: 12,
        zIndex: 2,
        backgroundColor: 'orange',
        opacity: props.layerId == selectedLayerId ? 1 : 0.3,
        top: '55%',
        position: 'absolute',
        left: props.pixelPoints[props.seconds.toFixed(2)],
        transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
        cursor: 'pointer'
      }} />}
      {props.nextKeyFrame && props.isActive &&
        <Box sx={{
          position: 'absolute',
          backgroundColor: '#4DA9E8',
          opacity: props.layerId == selectedLayerId ? 1 : 0.3,
          top: '50%',
          transform: 'translateY(-50%)',
          left: props.pixelPoints[props.seconds.toFixed(2)],
          height: 4,
          width: props.pixelPoints[props.nextKeyFrame.seconds.toFixed(2)] - props.pixelPoints[props.seconds.toFixed(2)]
        }} />
      }
      {props.nextKeyFrame && !props.isActive &&
        <Box sx={{
          position: 'absolute',
          backgroundColor: '#333539',
          opacity: 0.3,
          top: '50%',
          transform: 'translateY(-50%)',
          left: props.pixelPoints[props.seconds.toFixed(2)],
          height: 4,
          width: props.pixelPoints[props.nextKeyFrame.seconds.toFixed(2)] - props.pixelPoints[props.seconds.toFixed(2)]
        }} />
      }
    </Box>
  )
}

export default KeyframeClip;