import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { VeltUserRequestTool } from "@veltdev/react";
import { useRouter } from "next/router";
import { Fragment, SyntheticEvent, useEffect, useState } from "react";

import Icon from "src/@core/components/icon";
import { Settings } from "src/@core/context/settingsContext";
import { useAuth } from "src/config/auth";

interface Props {
  settings: Settings;
}

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // ** Hooks
  const router = useRouter();
  const { signOut, userSession, getUserData, userData } = useAuth();

  // ** Vars
  const { direction } = settings;

  useEffect(() => {
    getUserData();
  }, []);

  const firstName = userData?.user ? userData?.user.firstName : "";
  const lastName = userData?.user ? userData?.user.lastName : "";
  const email = userData?.user ? userData?.user.email : "";

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url);
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      mr: 2,
      fontSize: "1.375rem",
      color: "text.primary",
    },
  };

  const handleLogout = () => {
    signOut();
    handleDropdownClose();
  };

  return (
    <Fragment>
      {userData && (
        <Badge
          overlap="circular"
          onClick={handleDropdownOpen}
          sx={{ cursor: "pointer" }}
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          {userData?.user?.profilePictureLink ? (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                color: "#fff",
                backgroundColor: "#7b4040",
                cursor: "pointer",
              }}
              alt="User Profile"
              src={userData?.user?.profilePictureLink}
            ></Avatar>
          ) : firstName ? (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontSize: "14px",
              }}
            >
              {firstName.charAt(0).toUpperCase()}
              {lastName.charAt(0).toUpperCase()}
            </Avatar>
          ) : (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                fontSize: "14px",
              }}
            >
              {email.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </Badge>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, mt: 4 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {userData?.user?.profilePictureLink ? (
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    color: "#fff",
                    backgroundColor: "#7b4040",
                    cursor: "pointer",
                  }}
                  alt="User Profile"
                  src={userData?.user?.profilePictureLink}
                ></Avatar>
              ) : firstName ? (
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                >
                  {firstName.charAt(0).toUpperCase()}
                  {lastName.charAt(0).toUpperCase()}
                </Avatar>
              ) : (
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                  }}
                >
                  {email.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </Badge>
            <Box
              sx={{
                display: "flex",
                ml: 3,
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {firstName ? `${firstName} ${lastName}` : email.split("@")[0]}
              </Typography>
              {/* <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                Admin
              </Typography> */}
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: "0 !important" }} />
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/user-profile/profile")}
        >
          <Box sx={styles}>
            <Icon icon="mdi:account-outline" />
            Profile
          </Box>
        </MenuItem>
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <Icon icon='mdi:email-outline' />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <Icon icon='mdi:message-outline' />
            Chat
          </Box>
        </MenuItem> */}
        {userData?.user?.isSuperuser && (
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose("/")}>
            <Box sx={styles}>
              <Icon icon="iconoir:media-image" />
              My Projects
            </Box>
          </MenuItem>
        )}

        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/account-settings/account')}>
          <Box sx={styles}>
            <Icon icon='mdi:cog-outline' />
            Settings
          </Box>
        </MenuItem> */}

        {userData?.user?.isSuperuser && (
          <MenuItem
            sx={{ p: 0 }}
            onClick={() => handleDropdownClose("/render-requests")}
          >
            <Box sx={styles}>
              <Icon icon="streamline:ai-generate-portrait-image-spark" />
              Render Requests
            </Box>
          </MenuItem>
        )}

        {userData?.user?.isSuperuser && (
          <MenuItem
            sx={{ p: 0 }}
            onClick={() => handleDropdownClose("/assets-requests")}
          >
            <Box sx={styles}>
              <Icon icon="streamline:ai-generate-portrait-image-spark" />
              Assets Requests
            </Box>
          </MenuItem>
        )}

        {/* {userData?.user?.isSuperuser &&
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/subscriptions')}>
            <Box sx={styles}>
              <Icon icon='streamline:subscription-cashflow' />
              Subscriptions
            </Box>
          </MenuItem>
        } */}

        {userData?.user?.isSuperuser && (
          <MenuItem
            sx={{ p: 0 }}
            onClick={() => handleDropdownClose("/superadmin-dashboard")}
          >
            <Box sx={styles}>
              <Icon icon="streamline:subscription-cashflow" />
              Super Admin
            </Box>
          </MenuItem>
        )}

        {userData?.user?.isSuperuser && (
          <MenuItem
            sx={{ p: 0 }}
            onClick={() =>
              handleDropdownClose("/scene-hdr-background-requests")
            }
          >
            <Box sx={styles}>
              <Icon icon="material-symbols:background-replace-outline" />
              Scene Backgrounds
            </Box>
          </MenuItem>
        )}

        {userData?.user?.isSuperuser && (
          <MenuItem
            sx={{ p: 0 }}
            onClick={() => handleDropdownClose("/candidate-job-application")}
          >
            <Box sx={styles}>
              <Icon icon="mdi:file-account" />
              Job Applications
            </Box>
          </MenuItem>
        )}

        {/* {
            '& .s-user-request-tool-button-text': styles, '& svg': {
              width: '24px',
              height: '30px',
              ''
            }
          } */}

        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={{ ...styles, pl: 0 }}>
            <VeltUserRequestTool />
          </Box>
        </MenuItem>

        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={{ ...styles, pl: 0 }}>
            <VeltUserRequestTool type="reportBug" />
          </Box>
        </MenuItem>

        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <Icon icon='mdi:currency-usd' />
            Pricing
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <Icon icon='mdi:help-circle-outline' />
            FAQ
          </Box>
        </MenuItem> */}
        {/* <Divider /> */}
        <MenuItem
          onClick={handleLogout}
          sx={{
            py: 2,
            "& svg": { mr: 2, fontSize: "1.375rem", color: "text.primary" },
          }}
        >
          <Icon icon="mdi:logout-variant" />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
