const ImageIconBordered = (props: any) => {
    const color = props.color ?? '#A7A7A7'
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5527 10.6878C22.9601 10.6878 23.2317 10.4163 23.2317 10.0089V5.39203C23.2317 2.47254 20.8554 0.0283203 17.868 0.0283203H5.78266C2.86317 0.0283203 0.418945 2.40465 0.418945 5.39203V17.4774C0.418945 20.3968 2.79527 22.8411 5.71476 22.8411H17.8001C20.7196 22.8411 23.1638 20.4647 23.1638 17.4774V14.3542C23.1638 14.0826 23.028 13.8789 22.8243 13.7431C22.6206 13.6073 22.3491 13.6073 22.1454 13.7431L18.0717 16.1194L12.2327 10.8236C11.9611 10.62 11.6216 10.62 11.35 10.8236L5.44318 15.3726C5.1716 15.5763 5.1037 16.0516 5.30739 16.3231C5.51107 16.5947 5.98634 16.6626 6.25792 16.4589L11.6895 12.2494L17.4606 17.4774C17.6643 17.681 18.0038 17.681 18.2753 17.5452L21.738 15.5084V17.4095C21.738 19.5821 19.9727 21.4153 17.8001 21.4153C17.8001 21.4153 17.8001 21.4153 17.7322 21.4153H5.78266C3.61001 21.4153 1.77685 19.65 1.77685 17.4095V5.39203C1.77685 3.21939 3.54212 1.38622 5.78266 1.38622H17.868C20.0406 1.38622 21.8738 3.15149 21.8738 5.39203V10.0089C21.8738 10.4163 22.1454 10.6878 22.5527 10.6878Z" fill={color}/>
        <path d="M14.2017 6.81736C14.2017 8.31106 15.4238 9.53317 16.9175 9.53317C18.4112 9.53317 19.6333 8.31106 19.6333 6.81736C19.6333 5.32367 18.4112 4.10156 16.9175 4.10156C15.4238 4.10156 14.2017 5.32367 14.2017 6.81736ZM18.3433 6.81736C18.3433 7.56421 17.7322 8.17527 16.9854 8.17527C16.2385 8.17527 15.6275 7.56421 15.6275 6.81736C15.6275 6.07052 16.2385 5.45946 16.9854 5.45946C17.6643 5.52736 18.2754 6.07052 18.3433 6.81736Z" fill={color}/>
        </svg>



    )
}
export default ImageIconBordered