import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

import { IScene } from "../DesignEditorContext/scene";

interface Project {
  id: number;
  name: string;
  accountId: number;
  users: any;
  account: any;
  scenes: IScene[];
  isDemoProject: boolean;
  createdAt: Date;
  updatedAt: Date;
}

interface IProductContext {
  project: Project | null;
  projectRoles: Array<string> | null;
  setProjectRoles: Dispatch<SetStateAction<Array<string> | null>>;
  setProject: Dispatch<SetStateAction<Project | null>>;
  loadingScene: boolean;
  setLoadingScene: Dispatch<SetStateAction<boolean>>;
}

export const ProjectContext = createContext<IProductContext>({
  project: null,
  projectRoles: null,
  setProjectRoles: () => {},
  setProject: () => {},
  loadingScene: false,
  setLoadingScene: () => {},
});

export const ProjectContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [project, setProject] = useState<Project | null>(null);
  const [projectRoles, setProjectRoles] = useState<Array<string> | null>(null);
  const [loadingScene, setLoadingScene] = useState<boolean>(false);

  return (
    <ProjectContext.Provider
      value={{
        project,
        projectRoles,
        setProjectRoles,
        setProject,
        loadingScene,
        setLoadingScene,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
