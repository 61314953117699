const PlayButton = (props: any) => {
    const color = props.color ?? '#999999'
    const width = props.width || 20;
    return (
        <svg width={width} height={width} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6807 33.457C7.48508 33.457 0.00488281 25.9761 0.00488281 16.7812C0.00488281 7.58638 7.48508 0.105469 16.6807 0.105469C25.8763 0.105469 33.3564 7.58638 33.3564 16.7812C33.3564 25.9761 25.8763 33.457 16.6807 33.457ZM16.6807 1.4951C8.25184 1.4951 1.39451 8.35242 1.39451 16.7812C1.39451 25.2101 8.25184 32.0674 16.6807 32.0674C25.1095 32.0674 31.9668 25.2101 31.9668 16.7812C31.9668 8.35242 25.1095 1.4951 16.6807 1.4951Z" fill={color} />
            <path d="M23.31 17.3653L13.5825 23.6188C13.3694 23.7578 13.0939 23.7653 12.8741 23.6445C12.6501 23.5224 12.5117 23.289 12.5117 23.0345V10.5277C12.5117 10.2732 12.6501 10.0398 12.8741 9.91765C12.9772 9.8613 13.0926 9.83283 13.2066 9.83283C13.3368 9.83283 13.4685 9.86951 13.5825 9.94344L23.31 16.1969C23.5082 16.3244 23.6289 16.545 23.6289 16.7811C23.6289 17.0172 23.5082 17.2377 23.31 17.3653ZM13.9013 11.8006V21.7616L21.6489 16.7811L13.9013 11.8006Z" fill={color} />
        </svg>
    )
}
export default PlayButton