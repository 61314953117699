import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Dispatch } from "redux";

import { UsersType } from "src/types/apps/userTypes";

interface DataParams {
  q: string;
  role: string;
  status: string;
  currentPlan: string;
}

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

// ** Fetch Users
export const fetchData = createAsyncThunk(
  "appUsers/fetchData",
  async (params: DataParams) => {
    const usersListArray: UsersType[] = [];

    return {
      allData: usersListArray,
      users: usersListArray,
      params: params,
      total: usersListArray.length,
    };
  },
);

// ** Add User
export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (
    data: { [key: string]: number | string },
    { getState, dispatch }: Redux,
  ) => {
    const response = await axios.post("/apps/users/add-user", {
      data,
    });
    dispatch(fetchData(getState().user.params));

    return response.data;
  },
);

// ** Delete User
export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id: number | string, { getState, dispatch }: Redux) => {
    const response = await axios.delete("/apps/users/delete", {
      data: id,
    });
    dispatch(fetchData(getState().user.params));

    return response.data;
  },
);

// ** Edit User
export const editUser = (projectId: number, sceneId: number) => {
  debugger;
};

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.users;
      state.total = action.payload.total;
      state.params = action.payload.params;
      state.allData = action.payload.allData;
    });
  },
});

export default appUsersSlice.reducer;
