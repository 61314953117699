import { Box, Divider, useTheme } from "@mui/material";

import React, { createRef, useEffect, useRef, useState } from "react";

import { IStaticText } from "src/@core/context/DesignEditorContext/layers";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import { closest } from "src/utils/closest";

import Clip from "./Clip";

const ImageTimeline = (props: any) => {
  const { scene, setScene, updateScale, scaleSize, scalePoint } =
    useDesignEditorContext();
  const [distanceBetweenTwoScalePoints, setDistanceBetweenTwoScalePoints] =
    useState(scalePoint?.distanceBetweenTwoScalePoints || 0);
  const [pixelPoints, setPixelPoints] = useState(scalePoint?.pixelPoints || {});
  const [canvasWidth, setCanvasWidth] = useState(500);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const theme = useTheme();

  useEffect(() => {
    setPixelPoints(scalePoint?.pixelPoints);
    setDistanceBetweenTwoScalePoints(
      scalePoint?.distanceBetweenTwoScalePoints || 0,
    );
  }, [scene, scalePoint, scalePoint?.pixelPoints, scaleSize]);

  // Array storing element references of text layer Rnd components.
  let rndRefs: Array<any> = [];
  rndRefs =
    scene?.staticImageLayers.map((_, i) => rndRefs[i] ?? createRef()) || [];

  const onResizeStop = (
    dir: string,
    d: { height: number; width: number },
    refToElement: any,
    position: { x: any; y: number },
    layer: Partial<IStaticText>,
    i: number,
    setWidth: any,
  ) => {
    if (layer.display) {
      switch (dir) {
        case "left":
          layer.startingPointPixel =
            pixelPoints[Number(layer.display.from).toFixed(2)];
          // if((layer.startingPointPixel - Math.abs(position.x) )< pixelPoints['0.00']){
          //   layer.display.from = '0.00'
          //   layer.startingPointPixel = pixelPoints['0.00']
          // }
          // else{
          const displayFrom = closest(
            pixelPoints,
            Math.abs(d.width - layer.startingPointPixel),
          );
          layer.display.from = displayFrom;
          layer.startingPointPixel = pixelPoints[layer.display.from];
          // }
          break;
        case "right":
          layer.endingPointPixel =
            pixelPoints[Number(layer.display.to).toFixed(2)];
          const displayTo = closest(
            pixelPoints,
            Math.abs(d.width + layer.endingPointPixel),
          );
          layer.display.to = displayTo;
          layer.endingPointPixel = pixelPoints[layer.display.to];
          break;
      }
    }

    if (Number(layer.display.from) < 0 && Number(layer.display.to) > 0) {
      layer.display.from = "0.00";
      layer.startingPointPixel = pixelPoints["0.00"];
      rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    }

    if (Number(layer.display.from) + 1 / 10 >= Number(layer.display.to)) {
      layer.startingPointPixel =
        pixelPoints[Number(layer.display.from).toFixed(2)];
      layer.display.to = (Number(layer.display.from) + 3 / 10).toFixed(2);
      layer.endingPointPixel = pixelPoints[Number(layer.display.to).toFixed(2)];
    }

    layer.startingPointPixel =
      pixelPoints[Number(layer.display.from).toFixed(2)];
    rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    layer.pixelWidth =
      pixelPoints[Number(layer.display.to).toFixed(2)] -
      pixelPoints[Number(layer.display.from).toFixed(2)];
    if (scene.duration < Number(layer.display.to)) {
      scene.duration = Number(layer.display.to);
      updateScale(true);
    }
    setWidth(layer.pixelWidth);
    if (scene) setScene(scene);
  };

  const onDragStop = (
    event: React.DragEvent<HTMLDivElement>,
    d: { x: number; y: number; lastX: number; lastY: number; width: number },
    layer: Partial<IStaticText>,
    i: number,
  ) => {
    if (!d.lastX) {
      return;
    }
    const target = event.target as HTMLDivElement;
    const targetRect = target.getBoundingClientRect();
    const canvasRect = canvasRef.current?.getBoundingClientRect();
    if (d.x < 20) {
      d.x = 20;
    }
    if (targetRect.right > canvasRect!.right) {
      setCanvasWidth(canvasWidth + targetRect.right - canvasRect!.right);
    }
    rndRefs[i].current.updatePosition({ y: 0 });

    const diff = scaleSize;
    layer.display.from = Number(
      (Math.round(d.lastX / diff) / 10).toFixed(2) - 1 / Number(10),
    ).toFixed(2);
    if (d.lastX < d.x) {
      layer.display.to = Number(
        (
          Math.round(
            (d.lastX +
              rndRefs[i].current.resizableElement.current.offsetWidth) /
              diff,
          ) /
            10 -
          1 / Number(10)
        ).toFixed(2),
      ).toFixed(2);
    } else {
      layer.display.to = Number(
        (
          Math.round(
            (d.lastX +
              rndRefs[i].current.resizableElement.current.offsetWidth) /
              diff,
          ) /
            10 -
          1 / Number(10)
        ).toFixed(2),
      ).toFixed(2);
    }

    if (Number(layer.display.from) < 0 && Number(layer.display.to) < 0) {
      layer.display.to = (
        Number(
          closest(
            pixelPoints,
            pixelPoints[Number(Math.abs(layer.display.from)).toFixed(2)] -
              pixelPoints[Number(Math.abs(layer.display.to)).toFixed(2)],
          ),
        ) +
        1 / 10
      ).toFixed(2);
      layer.endingPointPixel = pixelPoints[layer.display.to];
      layer.display.from = "0.00";
      layer.startingPointPixel = pixelPoints["0.00"];
      rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    }

    if (Number(layer.display.from) < 0 && Number(layer.display.to) > 0) {
      layer.display.to = (
        Number(
          closest(
            pixelPoints,
            pixelPoints[Number(Math.abs(layer.display.from)).toFixed(2)] +
              pixelPoints[Number(Math.abs(layer.display.to)).toFixed(2)],
          ),
        ) -
        1 / 10
      ).toFixed(2);
      layer.endingPointPixel = pixelPoints[layer.display.to];
      layer.display.from = "0.00";
      layer.startingPointPixel = pixelPoints["0.00"];
      rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    }

    if (scene.duration < Number(layer.display.to)) {
      scene.duration = Number(layer.display.to);
      updateScale(true);
    }
    if (scene) setScene(scene);
  };

  return (
    <>
      {scene && scalePoint && pixelPoints && props.imageLayers?.length == 0 && (
        <div>
          <Box
            sx={{
              position: "relative",
              display: "block",
              height: "40px !important",
              margin: "0px",
            }}
          ></Box>
          <Divider
            sx={{
              "& .MuiDivider-wrapper": { px: 4 },
              mt: (theme) => `${theme.spacing(2)} !important`,
              mb: (theme) => `${theme.spacing(3)} !important`,
            }}
          ></Divider>
        </div>
      )}
      {scene &&
        scalePoint &&
        pixelPoints &&
        Object.keys(pixelPoints).length > 0 &&
        props.imageLayers.map((layer: any, i: number) => (
          <div key={layer.id}>
            <Box
              sx={{
                position: "relative",
                display: "block",
                height: "40px !important",
                margin: "0px",
              }}
            >
              <canvas
                ref={canvasRef}
                width={canvasWidth}
                height="50"
                style={{ left: 0, top: 0 }}
              ></canvas>
              <Clip
                rndRef={rndRefs[i]}
                layer={layer}
                pixelPoints={pixelPoints}
                width={layer.pixelWidth}
                distanceBetweenTwoScalePoints={distanceBetweenTwoScalePoints}
                index={i}
                onResizeStop={onResizeStop}
                onDragStop={onDragStop}
                deleteClip={props.deleteClip}
                key={i}
              />
            </Box>
            <Divider
              key={layer.id + "_Key"}
              sx={{
                "& .MuiDivider-wrapper": { px: 4 },
                mt: (theme) => `${theme.spacing(2)} !important`,
                mb: (theme) => `${theme.spacing(3)} !important`,
              }}
            ></Divider>
          </div>
        ))}
    </>
  );
};
export default ImageTimeline;
