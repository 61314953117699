const NewScene = (props: any) => {
    const color = props.color ?? '#A7A7A7'
    return (
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_3366)">
                <path d="M9.78892 23.9159C6.99881 23.9159 4.20869 23.9159 1.41858 23.9159C1.25607 23.9211 1.09367 23.9021 0.936625 23.8595C0.492654 23.7217 0.246004 23.301 0.299774 22.8259C0.324439 22.6116 0.42129 22.4125 0.574028 22.2621C0.726767 22.1117 0.926068 22.019 1.13839 21.9998C1.24149 21.9894 1.34607 21.9884 1.44966 21.9884C7.01476 21.9884 12.5799 21.9884 18.145 21.9884C18.3078 21.9827 18.4706 21.9995 18.6289 22.0383C19.0729 22.164 19.3348 22.5873 19.2889 23.0579C19.2693 23.2763 19.1746 23.4807 19.0214 23.6357C18.8682 23.7907 18.6661 23.8865 18.4503 23.9064C18.3394 23.9161 18.2281 23.9201 18.1168 23.9184L9.78892 23.9159Z" fill={color} />
                <path d="M0.385742 20.0435V19.9672C0.415717 19.9162 0.442727 19.8636 0.466641 19.8095C2.42209 14.5739 4.37688 9.33836 6.33101 4.10281C6.72565 3.04073 7.12029 1.97781 7.51493 0.914062H11.9458C11.9556 0.937021 11.966 0.95948 11.9749 0.982439C14.2273 6.84419 16.48 12.7059 18.7331 18.5677C18.9196 19.0523 19.1041 19.538 19.2964 20.0415H19.0937C17.5492 20.0415 16.0046 20.0415 14.4606 20.0455C14.3126 20.0455 14.2495 20.0016 14.1987 19.8614C13.7483 18.6136 13.2875 17.3704 12.8357 16.1231C12.7928 16.0033 12.737 15.9659 12.6127 15.9664C10.7053 15.9704 8.79784 15.9704 6.89041 15.9664C6.75574 15.9664 6.70247 16.0103 6.66004 16.1346C6.23432 17.3779 5.79923 18.6181 5.37746 19.8619C5.32813 20.0056 5.264 20.046 5.11699 20.0455C3.53596 20.041 1.96085 20.0435 0.385742 20.0435ZM9.69927 7.41932C9.18032 8.91313 8.67764 10.361 8.17004 11.8189H11.2561C10.7406 10.3595 10.2291 8.91512 9.69927 7.41932Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_8_3366">
                    <rect width="19" height="23" fill="white" transform="translate(0.29248 0.916016)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default NewScene