const RectangleBackgroundLayer = (props: any) => {
    const color = props.color ?? '#3C3E42'
    const width = props.width ?? '44'
    const height = props.height ?? '44'
    return (
        <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.4" x="0.00585938" y="0.287109" width="43.6393" height="43.6393" rx="10" fill={color}/>
        </svg>
    )
}
export default RectangleBackgroundLayer