import { DialogTitle, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import axios from "axios";
import { useRouter } from "next/router";
import { Fragment, SyntheticEvent, useState } from "react";

import Icon from "src/@core/components/icon";
import { Settings } from "src/@core/context/settingsContext";
import ShareProject from "src/components/Projects/ShareProject";
import { getExportedUsdFile, renderVideo } from "src/config/api";
import { useAuth } from "src/config/auth";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";

interface Props {
  settings: Settings;
}

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const ShareProjectDropdown = (props: Props) => {
  // ** Props
  const { settings } = props;
  const { scene, setScene } = useDesignEditorContext();
  const { project } = useProjectContext();
  const { userData } = useAuth();

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [exportBtnText, setExportBtnText] = useState("Video");
  const [useBtnText, setUsdBtnText] = useState("USD");

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [showSubscribePopup, setShowSubscribePopup] = useState<boolean>(false);
  const [showWaitListPopup, setShowWaitListPopup] = useState(false);

  const [show, setShow] = useState<boolean>(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupSubTitle, setPopupSubTitle] = useState("");

  // ** Hooks
  const router = useRouter();

  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url);
    }
    setAnchorEl(null);
  };

  const handleShareClick = () => {
    setShow(true);
    handleDropdownClose();
  };

  const exportVideo = async () => {
    setExportBtnText("Please wait...");
    try {
      await renderVideo(scene?.id);
      setPopupTitle("Export Scheduled");
      setPopupSubTitle("You will receive an email once your video is ready");
      setPopupOpen(true);
      setAnchorEl(null);
    } catch (e) {
    } finally {
      setExportBtnText("Video");
    }
  };

  const exportUSDFile = async () => {
    // Function to handle the export and download process
    const handleExportAndDownload = async () => {
      setUsdBtnText("Please wait...");
      try {
        const exportUsdFileResp = await getExportedUsdFile(scene?.id);
        const s3FileResp = await axios.get(exportUsdFileResp.data.usdFileUrl, {
          responseType: "arraybuffer",
        });
        const blob = new Blob([s3FileResp.data], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${project.name}-${scene?.name}.usdz`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        scene!.glbFileURL = exportUsdFileResp.data.glbFileURL || "";
        setScene({ scene: scene, updateSceneInDB: false });
        setPopupTitle("USD Exported");
        setPopupSubTitle("Check your downloads folder for USD.");
        setPopupOpen(true);
      } catch (error) {
        console.error("failed to export USD file", error);
        // Consider adding error handling logic here
      } finally {
        setUsdBtnText("USD");
        setAnchorEl(null);
      }
    };

    await handleExportAndDownload();

    // Check user subscription status
    // let subscriptionStatus;
    // if (userData.lastUsedAccount.account.subscriptions.length > 0) {
    //     subscriptionStatus = userData.lastUsedAccount.account.subscriptions[0].status;
    // } else {
    //     setShowSubscribePopup(true);
    // }

    // if (userData.user.isSuperuser || subscriptionStatus === "Active") {
    //     await handleExportAndDownload();
    // } else if (subscriptionStatus === "WaitList") {
    //     setShowWaitListPopup(true)
    // }
  };

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      mr: 2,
      fontSize: "1.375rem",
      color: "text.primary",
    },
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const handleSubscribeClick = () => {
    router.push("/subscription");
  };

  return (
    <>
      <Fragment>
        <Box sx={{ marginRight: "14px" }}>
          <Tooltip title={"Share Project"}>
            <IconButton
              sx={{ p: 0 }}
              color="inherit"
              onClick={handleShareClick}
            >
              <Icon
                icon="material-symbols:share"
                style={{ height: "20px", width: "20px" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <Tooltip title={"Export"}>
            <IconButton
              sx={{ p: 0, marginRight: "6px" }}
              color="inherit"
              onClick={handleDropdownOpen}
            >
              <Icon
                icon="ph:export-bold"
                style={{ height: "20px", width: "20px" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ "& .MuiMenu-paper": { width: 230, mt: 4 } }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: direction === "ltr" ? "right" : "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: direction === "ltr" ? "right" : "left",
          }}
        >
          <MenuItem
            sx={{ p: 0 }}
            disabled={exportBtnText == "Please wait..."}
            onClick={() => exportVideo()}
          >
            <Box sx={styles}>
              <Icon icon="ph:video" />
              {exportBtnText}
            </Box>
          </MenuItem>
          <MenuItem
            sx={{ p: 0 }}
            disabled={useBtnText == "Please wait..."}
            onClick={() => exportUSDFile()}
          >
            <Box sx={styles}>
              <Icon icon="mdi:tray-arrow-down" />
              {useBtnText}
            </Box>
          </MenuItem>
        </Menu>
      </Fragment>
      {/* {openNotification && <NotificationPopup Title={popupTitle}></NotificationPopup>} */}
      {/* <Snackbar
                open={popupOpen}
                autoHideDuration={5000}
                onClose={closePopup}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert
                    style={alertStyle}
                    onClose={closePopup}
                    severity="success">
                    Your video is exported successfully!
                </Alert>
            </Snackbar> */}
      {/* new popup tryial starts */}

      <Dialog fullWidth maxWidth="xs" open={popupOpen} onClose={closePopup}>
        <DialogContent
          sx={{
            pb: (theme) => `${theme.spacing(6)} !important`,
            px: (theme) => [
              `${theme.spacing(5)} !important`,
              `${theme.spacing(15)} !important`,
            ],
            pt: (theme) => [
              `${theme.spacing(8)} !important`,
              `${theme.spacing(12.5)} !important`,
            ],
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              "& svg": {
                mb: 8,
                color: "success.main",
              },
            }}
          >
            <Icon fontSize="5.5rem" icon={"mdi:check-circle-outline"} />
            <Typography variant="h4" sx={{ mb: 5 }}>
              {popupTitle}
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              {popupSubTitle}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            px: (theme) => [
              `${theme.spacing(5)} !important`,
              `${theme.spacing(15)} !important`,
            ],
            pb: (theme) => [
              `${theme.spacing(8)} !important`,
              `${theme.spacing(12.5)} !important`,
            ],
          }}
        >
          <Button variant="contained" onClick={closePopup}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* New popup end */}

      <ShareProject show={show} setShow={setShow} />

      <Dialog
        open={showSubscribePopup}
        onClose={() => {
          setShowSubscribePopup(false);
        }}
        aria-labelledby="subscribe-dialog-title"
      >
        <DialogTitle id="subscribe-dialog-title" textAlign={"center"}>
          Please Subscribe
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              color: "text.secondary",
              height: 50,
              mb: 2,
              maxHeight: 60,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            Please subscribe to our payment plan for creating custom project.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSubscribePopup(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubscribeClick}
            color="primary"
            autoFocus
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={showWaitListPopup}
        onClose={() => {
          setShowWaitListPopup(false);
        }}
      >
        <DialogContent
          sx={{
            pb: (theme) => `${theme.spacing(6)} !important`,
            px: (theme) => [
              `${theme.spacing(5)} !important`,
              `${theme.spacing(15)} !important`,
            ],
            pt: (theme) => [
              `${theme.spacing(8)} !important`,
              `${theme.spacing(12.5)} !important`,
            ],
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              "& svg": {
                mb: 8,
                color: "warning.main",
              },
            }}
          >
            <Icon fontSize="5.5rem" icon={"mdi:clock-outline"} />
            <Typography variant="h4" sx={{ mb: 5 }}>
              {"You are on wait list."}
            </Typography>
            <Typography sx={{ textAlign: "center" }}>
              {"You are on wait list. Please wait."}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            px: (theme) => [
              `${theme.spacing(5)} !important`,
              `${theme.spacing(15)} !important`,
            ],
            pb: (theme) => [
              `${theme.spacing(8)} !important`,
              `${theme.spacing(12.5)} !important`,
            ],
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setShowWaitListPopup(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareProjectDropdown;
