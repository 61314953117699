import { useContext } from "react";

import { ProjectContext } from "src/@core/context/ProjectContext/ProjectContext";

const useProjectContext = () => {
  const {
    project,
    setProject,
    projectRoles,
    setProjectRoles,
    loadingScene,
    setLoadingScene,
  } = useContext(ProjectContext);
  return {
    project,
    setProject,
    projectRoles,
    setProjectRoles,
    loadingScene,
    setLoadingScene,
  };
};

export default useProjectContext;
