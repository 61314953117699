import IconButton from "@mui/material/IconButton";

import Icon from "src/@core/components/icon";
import { Settings } from "src/@core/context/settingsContext";
import { Mode } from "src/@core/layouts/types";

interface Props {
  settings: Settings;
  saveSettings: (values: Settings) => void;
}

const ModeToggler = (props: Props) => {
  // ** Props
  const { settings, saveSettings } = props;

  const handleModeChange = (mode: Mode) => {
    saveSettings({ ...settings, mode: mode });
  };

  const handleModeToggle = () => {
    if (settings.mode === "light") {
      handleModeChange("dark" as Mode);
    } else {
      handleModeChange("light" as Mode);
    }
  };

  return (
    <IconButton
      sx={{ p: 0 }}
      color="inherit"
      aria-haspopup="true"
      onClick={handleModeToggle}
    >
      <Icon
        style={{ width: "22px", height: "22px", marginBottom: "-2px" }}
        icon={
          settings.mode === "dark" ? "mdi:weather-sunny" : "mdi:weather-night"
        }
      />
    </IconButton>
  );
};

export default ModeToggler;
