const ThinkingCloud = (props: any) => {
    const color = props.color ?? '#A7A7A7'
    return (
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_3358)">
                <path d="M20.7839 8.22008V9.41681C20.7679 9.45912 20.7559 9.50284 20.7479 9.54741C20.6364 10.8367 20.2027 12.0751 19.4883 13.144C18.3983 14.7906 16.8872 15.877 15.0661 16.5167C12.6278 17.3728 10.1483 17.4133 7.64898 16.8248C7.51123 16.7923 7.42565 16.8104 7.32338 16.9101C6.66951 17.5529 5.86379 18.0117 4.98475 18.2417C4.11441 18.473 3.22894 18.4064 2.34556 18.4016C2.3393 18.4016 2.33252 18.383 2.31373 18.3547C2.63463 18.0306 2.9597 17.706 3.2806 17.3771C3.77734 16.868 4.07789 16.2539 4.21981 15.5529C4.2506 15.3989 4.20937 15.3088 4.07893 15.2075C3.6855 14.9036 3.28738 14.5987 2.93361 14.248C1.6568 12.9825 0.909087 11.4435 0.798991 9.61991C0.691503 7.83947 1.19294 6.21949 2.17076 4.75463C3.40739 2.90169 5.11363 1.68737 7.18302 0.987991C8.9957 0.374967 10.8522 0.295006 12.7332 0.543947C14.1457 0.729987 15.4898 1.13778 16.7249 1.87181C18.7186 3.05735 20.0643 4.75729 20.5924 7.07879C20.6764 7.4562 20.7207 7.83894 20.7839 8.22008Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_8_3358">
                    <rect width="20" height="18" fill="white" transform="translate(0.783203 0.416016)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default ThinkingCloud