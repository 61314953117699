import { debounce } from "lodash";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";

import {
  updatePcgVolumes,
  updateScene,
  updateSceneEntityTransforms,
  updateSceneObjectProperties,
  updateSceneShapes,
} from "src/config/api";
import useProjectContext from "src/hooks/useProjectContext";

import { ILayer } from "./layers";
import { CanvasKeyframe, IScalePoint, IScene } from "./scene";

interface ISceneEditorContext {
  scene: IScene | null;
  setScene: (
    value: ((prevState: IScene, updateSceneInDB: boolean) => IScene) | IScene,
  ) => void;
  currentLayer: ILayer | null;
  setCurrentLayer: Dispatch<SetStateAction<ILayer | null>>;
  currentKeyFrame: CanvasKeyframe | null;
  setCurrentKeyFrame: Dispatch<SetStateAction<CanvasKeyframe | null>>;
  currentScriptComponentId: string | null;
  setCurrentScriptComponentId: Dispatch<SetStateAction<string | null>>;
  currentShotId: number | null;
  setCurrentShotId: Dispatch<SetStateAction<number | null>>;
  scalePoint: IScalePoint | null;
  setScalePoint: (
    value: ((prevState: IScalePoint) => IScalePoint) | IScalePoint,
  ) => void;
  displayPlayback: boolean;
  setDisplayPlayback: Dispatch<SetStateAction<boolean>>;
  displayPreview: boolean;
  setDisplayPreview: Dispatch<SetStateAction<boolean>>;
  playbackSeeked: boolean;
  setPlaybackSeeked: Dispatch<SetStateAction<boolean>>;
  playbackVisibility: boolean;
  setPlaybackVisibility: Dispatch<SetStateAction<boolean>>;
  videoEditorMode: string;
  setVideoEditorMode: Dispatch<SetStateAction<string>>;
  forceReloadFrames: boolean;
  setForceReloadFrames: Dispatch<SetStateAction<boolean>>;
  fullScreenMode: boolean;
  setFullScreenMode: Dispatch<SetStateAction<boolean>>;
  selectedCameraKeyframeId: string | null;
  setSelectedCameraKeyframeId: Dispatch<SetStateAction<string | null>>;
  screenShotTaken: boolean;
  setScreenShotTaken: Dispatch<SetStateAction<boolean>>;
  addNewShot: boolean;
  setAddNewShot: Dispatch<SetStateAction<boolean>>;

  addNewScene: boolean;
  setAddNewScene: Dispatch<SetStateAction<boolean>>;

  addNewCameraSettings: null;
  setAddNewCameraSettings: Dispatch<SetStateAction<any>>;
  maxTime: number;
  setMaxTime: Dispatch<SetStateAction<number>>;
  reRenderState: boolean;
  reRenderTimeline: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  scaleSize: number | 50;
  setScaleSize: Dispatch<SetStateAction<number | 10>>;

  cameraSelection: (id: number) => void;
  setCameraSelection: Dispatch<SetStateAction<any>>;

  reRenderClips: false;
  reRenderAllClips: Dispatch<SetStateAction<boolean | false>>;

  updateScalePoint: false;
  updateScale: Dispatch<SetStateAction<boolean | false>>;

  fitTimeline: false;
  setFitTimeline: Dispatch<SetStateAction<boolean | false>>;

  scaleRenderingProgress: "";
  setScaleRenderingProgress: Dispatch<SetStateAction<string | "">>;

  layersChanged: false;
  setLayersChanged: Dispatch<SetStateAction<boolean | false>>;
  layersAddedDeleted: false;
  setLayersAddedDeleted: Dispatch<SetStateAction<boolean | false>>;

  sceneParsedOrRendered: false;
  setSceneParsedOrRendered: Dispatch<SetStateAction<boolean | false>>;

  deletedLayerId: null;
  setDeletedLayerId: Dispatch<SetStateAction<string | null>>;

  pcgVolumes: Array<any>;
  setPcgVolumes: (
    value:
      | ((prevState: Array<any>, updateInDB: boolean) => Array<any>)
      | Array<any>,
  ) => void;

  sceneShapes: Array<any>;
  setSceneShapes: Dispatch<SetStateAction<any | null>>;

  selectedPcgVolume: null;
  setSelectedPcgVolume: Dispatch<SetStateAction<any | null>>;

  sceneEntityTransforms: null;
  setSceneEntityTransforms: Dispatch<SetStateAction<any | null>>;

  sceneObjectProperties: null;
  setSceneObjectProperties: Dispatch<SetStateAction<any | null>>;

  selectedLayerId: null;
  setSelectedLayerId: Dispatch<SetStateAction<any | null>>;

  sceneNumericId: null;
  setSceneNumericId: Dispatch<SetStateAction<any | null>>;

  selectedVideoType: null;
  setSelectedVideoType: Dispatch<SetStateAction<any | null>>;

  onTipTapEditor: false;
  setOnTipTapEditor: Dispatch<SetStateAction<any | null>>;

  playbackWaiting: false;
  setPlaybackWaiting: Dispatch<SetStateAction<boolean | false>>;
}

export const DesignEditorContext = createContext<ISceneEditorContext>({
  scene: null,
  setScene: () => { },
  currentLayer: null,
  setCurrentLayer: () => { },
  currentKeyFrame: null,
  setCurrentKeyFrame: () => { },
  currentScriptComponentId: null,
  setCurrentScriptComponentId: () => { },
  currentShotId: null,
  setCurrentShotId: () => { },
  scalePoint: null,
  setScalePoint: () => { },
  displayPlayback: false,
  setDisplayPlayback: () => { },
  displayPreview: false,
  setDisplayPreview: () => { },
  playbackSeeked: false,
  setPlaybackSeeked: () => { },
  playbackVisibility: false,
  setPlaybackVisibility: () => { },
  videoEditorMode: "VIEW",
  setVideoEditorMode: () => { },
  forceReloadFrames: false,
  setForceReloadFrames: () => { },
  fullScreenMode: false,
  setFullScreenMode: () => { },
  selectedCameraKeyframeId: null,
  setSelectedCameraKeyframeId: () => { },
  screenShotTaken: false,
  setScreenShotTaken: () => { },
  addNewShot: false,
  setAddNewShot: () => { },
  onTipTapEditor: false,
  setOnTipTapEditor: () => { },

  addNewScene: false,
  setAddNewScene: () => { },

  addNewCameraSettings: null,
  setAddNewCameraSettings: () => { },
  maxTime: 0,
  setMaxTime: () => { },
  reRenderState: false,
  reRenderTimeline: () => { },
  scaleSize: 0,
  setScaleSize: () => { },
  cameraSelection: (id: number) => { },
  setCameraSelection: () => { },
  reRenderClips: false,
  reRenderAllClips: () => { },
  updateScalePoint: false,
  updateScale: () => { },
  fitTimeline: false,
  setFitTimeline: () => { },
  scaleRenderingProgress: "",
  setScaleRenderingProgress: () => { },
  layersChanged: false,
  setLayersChanged: () => { },
  sceneParsedOrRendered: false,
  setSceneParsedOrRendered: () => { },
  layersAddedDeleted: false,
  setLayersAddedDeleted: () => { },
  deletedLayerId: null,
  setDeletedLayerId: () => { },
  pcgVolumes: [],
  setPcgVolumes: () => { },
  sceneShapes: [],
  setSceneShapes: () => { },
  selectedPcgVolume: null,
  setSelectedPcgVolume: () => { },
  sceneEntityTransforms: null,
  setSceneEntityTransforms: () => { },
  sceneObjectProperties: null,
  setSceneObjectProperties: () => { },
  selectedLayerId: null,
  setSelectedLayerId: () => { },
  sceneNumericId: null,
  setSceneNumericId: () => { },
  selectedVideoType: null,
  setSelectedVideoType: () => { },
  playbackWaiting: false,
  setPlaybackWaiting: () => { },
});

export const DesignEditorProvider = ({ children }: { children: ReactNode }) => {
  const [scene, setSceneObj] = useState<IScene>();
  const [reRenderState, reRenderTimeline] = useState<boolean>();
  const [currentLayer, setCurrentLayer] = useState<ILayer | null>(null);
  const [currentKeyFrame, setCurrentKeyFrame] = useState<CanvasKeyframe | null>(
    null,
  );
  const [currentScriptComponentId, setCurrentScriptComponentId] = useState<
    string | null
  >(null);
  const [currentShotId, setCurrentShotId] = useState<number | null>(null);
  const [displayPlayback, setDisplayPlayback] = useState<boolean>(false);
  const [displayPreview, setDisplayPreview] = useState<boolean>(false);
  const [playbackSeeked, setPlaybackSeeked] = useState<boolean>(false);
  const [layersChanged, setLayersChanged] = useState<boolean>(false);
  const [sceneParsedOrRendered, setSceneParsedOrRendered] =
    useState<boolean>(false);
  const [addNewShot, setAddNewShot] = useState<boolean>(false);

  const [addNewScene, setAddNewScene] = useState<boolean>(false);

  const [addNewCameraSettings, setAddNewCameraSettings] = useState<any>(null);
  const [layersAddedDeleted, setLayersAddedDeleted] = useState<boolean>(false);
  const [deletedLayerId, setDeletedLayerId] = useState<string>("");
  const [pcgVolumes, setPcgVolumesObj] = useState<Array<any>>([]);
  const [sceneShapes, setSceneShapesObj] = useState<Array<any>>([]);
  const [sceneEntityTransforms, setSceneEntityTransformsObj] = useState<
    Array<any>
  >([]);
  const [sceneObjectProperties, setSceneObjectPropertiesObj] = useState<
    Array<any>
  >([]);
  const [selectedPcgVolume, setSelectedPcgVolume] = useState<any>(null);
  const [selectedLayerId, setSelectedLayerId] = useState<any>(null);
  const [sceneNumericId, setSceneNumericId] = useState<any>(null);
  const [playbackWaiting, setPlaybackWaiting] = useState<any>(null);
  const [selectedVideoType, setSelectedVideoType] =
    useState<string>("videoWideAngle");
  const { projectRoles } = useProjectContext();

  const [onTipTapEditor, setOnTipTapEditor] = useState(false);

  const [playbackVisibility, setPlaybackVisibility] = useState<boolean>(false);
  const [videoEditorMode, setVideoEditorMode] = useState<string>("VIEW");
  const [forceReloadFrames, setForceReloadFrames] = useState<boolean>(false);
  const [screenShotTaken, setScreenShotTaken] = useState<boolean>(false);
  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
  const [selectedCameraKeyframeId, setSelectedCameraKeyframeId] = useState<string | null>(null);
  const [scaleSize, setScaleSize] = useState<number>(10);

  const [cameraSelection, setCameraSelection] = useState<(id: number) => void>();

  // Added Debounce to avoid multiple calls to updateScene
  const setScene = useCallback(
    debounce(async (arg) => {
      const scene = arg.scene ? arg.scene : arg;
      const updateSceneInDB = arg.hasOwnProperty("updateSceneInDB")
        ? arg.updateSceneInDB
        : true;
      setSceneObj(scene);
      if (
        scene.id &&
        updateSceneInDB &&
        (projectRoles?.includes("Editor") || projectRoles?.includes("Owner"))
      ) {
        await updateScene(scene);
      }
    }, 1000),
    [projectRoles],
  );

  const setPcgVolumes = useCallback(
    debounce(async (arg) => {
      const pcgVolumes = arg.pcgVolumes ? arg.pcgVolumes : arg;
      const sceneId = arg.sceneId ? arg.sceneId : arg;

      const updateInDB = arg.hasOwnProperty("updateInDB")
        ? arg.updateInDB
        : true;
      setPcgVolumesObj([...pcgVolumes]);
      if (sceneId && updateInDB) await updatePcgVolumes(sceneId, pcgVolumes);
    }, 0),
    [],
  );

  const setSceneShapes = useCallback(
    debounce(async (arg) => {
      const sceneShapes = arg.sceneShapes ? arg.sceneShapes : arg;
      const sceneId = arg.sceneId ? arg.sceneId : arg;

      const updateInDB = arg.hasOwnProperty("updateInDB")
        ? arg.updateInDB
        : true;
      setSceneShapesObj([...sceneShapes]);
      if (sceneId && updateInDB) await updateSceneShapes(sceneId, sceneShapes);
    }, 0),
    [],
  );

  const setSceneEntityTransforms = useCallback(
    debounce(async (arg) => {
      const sceneEntityTransforms = arg.sceneEntityTransforms
        ? arg.sceneEntityTransforms
        : arg;
      const sceneId = arg.sceneId ? arg.sceneId : arg;

      const updateInDB = arg.hasOwnProperty("updateInDB")
        ? arg.updateInDB
        : true;
      setSceneEntityTransformsObj([...sceneEntityTransforms]);
      if (sceneId && updateInDB)
        await updateSceneEntityTransforms(sceneId, sceneEntityTransforms);
    }, 1000),
    [],
  );

  const setSceneObjectProperties = useCallback(
    debounce(async (arg) => {
      const sceneObjectProperties = arg.sceneObjectProperties
        ? arg.sceneObjectProperties
        : arg;
      const sceneId = arg.sceneId ? arg.sceneId : arg;

      const updateInDB = arg.hasOwnProperty("updateInDB")
        ? arg.updateInDB
        : true;
      setSceneObjectPropertiesObj([...sceneObjectProperties]);
      if (sceneId && updateInDB)
        await updateSceneObjectProperties(sceneId, sceneObjectProperties);
    }, 1000),
    [],
  );

  const [maxTime, setMaxTime] = useState(0);
  const [scalePoint, setScalePoint] = useState<IScalePoint>();
  const [reRenderClips, reRenderAllClips] = useState<boolean>();
  const [updateScalePoint, updateScale] = useState<boolean>();
  const [fitTimeline, setFitTimeline] = useState<boolean>(false);
  const [scaleRenderingProgress, setScaleRenderingProgress] =
    useState<string>("");

  const context = {
    scene,
    setScene,
    currentLayer,
    setCurrentLayer,
    currentKeyFrame,
    setCurrentKeyFrame,
    currentScriptComponentId,
    setCurrentScriptComponentId,
    currentShotId,
    setCurrentShotId,
    scalePoint,
    setScalePoint,
    displayPlayback,
    setDisplayPlayback,
    displayPreview,
    setDisplayPreview,
    playbackSeeked,
    setPlaybackSeeked,
    playbackVisibility,
    setPlaybackVisibility,
    videoEditorMode,
    setVideoEditorMode,
    forceReloadFrames,
    setForceReloadFrames,
    fullScreenMode,
    setFullScreenMode,
    selectedCameraKeyframeId,
    setSelectedCameraKeyframeId,
    screenShotTaken,
    setScreenShotTaken,
    addNewShot,
    setAddNewShot,

    addNewScene,
    setAddNewScene,

    addNewCameraSettings,
    setAddNewCameraSettings,
    maxTime,
    setMaxTime,
    reRenderState,
    reRenderTimeline,
    scaleSize,
    setScaleSize,
    cameraSelection,
    setCameraSelection,
    reRenderClips,
    reRenderAllClips,
    updateScalePoint,
    updateScale,
    fitTimeline,
    setFitTimeline,
    scaleRenderingProgress,
    setScaleRenderingProgress,
    layersChanged,
    setLayersChanged,
    sceneParsedOrRendered,
    setSceneParsedOrRendered,
    layersAddedDeleted,
    setLayersAddedDeleted,
    deletedLayerId,
    setDeletedLayerId,
    pcgVolumes,
    setPcgVolumes,
    sceneShapes,
    setSceneShapes,
    selectedPcgVolume,
    setSelectedPcgVolume,
    sceneEntityTransforms,
    setSceneEntityTransforms,
    sceneObjectProperties,
    setSceneObjectProperties,
    selectedLayerId,
    setSelectedLayerId,
    sceneNumericId,
    setSceneNumericId,
    selectedVideoType,
    setSelectedVideoType,
    onTipTapEditor,
    setOnTipTapEditor,
    playbackWaiting,
    setPlaybackWaiting,
    projectRoles,
  };
  return (
    <DesignEditorContext.Provider value={context}>
      {children}
    </DesignEditorContext.Provider>
  );
};
