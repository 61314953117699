import process from "process";

import { Box, Paper, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import type { EmotionCache } from "@emotion/cache";

import { CacheProvider } from "@emotion/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "@theatre/core";
import studio from "@theatre/studio";
import { VeltProvider } from "@veltdev/react";
import { Amplify } from "aws-amplify";
import Head from "next/head";
import { Router, useRouter } from "next/router";

import type { NextPage } from "next";
import type { AppProps } from "next/app";

import NProgress from "nprogress";
import { ReactNode, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

import AuthGuard from "src/@core/components/auth/AuthGuard";
import Spinner from "src/@core/components/spinner";
import {
  SettingsConsumer,
  SettingsProvider,
} from "src/@core/context/settingsContext";
import ReactHotToast from "src/@core/styles/libs/react-hot-toast";
import ThemeComponent from "src/@core/theme/ThemeComponent";
import { createEmotionCache } from "src/@core/utils/create-emotion-cache";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-tsx";
import "react-perfect-scrollbar/dist/css/styles.css";
import "src/iconify-bundle/icons-bundle-react";
import "../../styles/globals.css";
import Logo from "src/common/Logo";
import { AuthProvider } from "src/config/auth";
import themeConfig from "src/configs/themeConfig";

import "src/@fake-db";

import UserLayout from "src/layouts/UserLayout";
import { isSupportedDevice } from "src/libs/utils";
import { store } from "src/store";

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY || ""); // Replace with your Stripe publishable key

const PentoPixLogo = styled("img")({
  width: "90px",
});

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

type GuardProps = {
  authGuard: boolean;
  children: ReactNode;
};

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on("routeChangeStart", () => {
    NProgress.start();
  });
  Router.events.on("routeChangeError", () => {
    NProgress.done();
  });
  Router.events.on("routeChangeComplete", () => {
    NProgress.done();
  });
}

const Guard = ({ children, authGuard }: GuardProps) => {
  if (authGuard) {
    return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
  } else {
    return <>{children}</>;
  }
};

Amplify.configure({
  Auth: {
    region: process.env.COGNITO_REGION,
    userPoolId: process.env.COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_WEB_CLIENT_ID,
  },
});

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const theme = useTheme();
  const router = useRouter();

  const [supported, setSupported] = useState(true);

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  // Variables
  const contentHeightFixed = Component.contentHeightFixed ?? false;
  const getLayout =
    Component.getLayout ??
    ((page) => (
      <UserLayout contentHeightFixed={contentHeightFixed}>{page}</UserLayout>
    ));

  const setConfig = Component.setConfig ?? undefined;

  const authGuard = Component.authGuard ?? true;

  useEffect(() => {
    // Perform device and screen size check
    const userAgent = navigator.userAgent;
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    setSupported(isSupportedDevice(userAgent, screenWidth, screenHeight));

    // Optionally, handle window resize
    const handleResize = () => {
      setSupported(
        isSupportedDevice(userAgent, window.innerWidth, screenHeight),
      );
    };
    window.addEventListener("resize", handleResize);
    // studio.initialize();
    // studio.__experimental.__experimental_disblePlayPauseKeyboardShortcut();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (router.pathname.includes("previs")) {
      // if (studio.ui.isHidden)
      //   studio.ui.restore()
    } else {
      studio.ui.hide();
    }
  }, [router.pathname]);

  if (!supported) {
    return (
      <Provider store={store}>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>{`${themeConfig.templateName} | AI Video Generator | Script to animated 3D storyboard `}</title>
            <meta
              name="description"
              content={`${themeConfig.templateName}  | AI Video Generator | Script to animated 3D storyboard `}
            />
            <meta
              property="og:image"
              content="https://studio.pentopix.com/images/pages/pentopixLogo/pentopix_logo_light.png"
            />
            <meta
              name="keywords"
              content="Material Design, MUI, Admin Template, React Admin Template"
            />
            <meta
              name="viewport"
              content="initial-scale=1, width=device-width"
            />
          </Head>
          <VeltProvider apiKey={process.env.NEXT_PUBLIC_VELT_API_KEY as string}>
            <AuthProvider>
              <Elements stripe={stripePromise}>
                <SettingsProvider
                  {...(setConfig ? { pageSettings: setConfig() } : {})}
                >
                  <SettingsConsumer>
                    {({ settings }) => {
                      return (
                        <ThemeComponent settings={settings}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh",
                              bgcolor: "background.default",
                              p: 2, // padding
                            }}
                          >
                            <Paper
                              elevation={3}
                              sx={{
                                p: 5, // padding
                                maxWidth: 400,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                backgroundColor:
                                  theme.palette.customColors?.tableHeaderBg!,
                              }}
                            >
                              <Box sx={{ width: "100%" }}>
                                <Logo width={"3rem"} />
                              </Box>
                              <Box
                                sx={{
                                  width: 120,
                                  height: 120,
                                  bgcolor: "#f4f4f9",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  mb: 2, // margin-bottom
                                  borderRadius: "50%",
                                  // add your icon styles here
                                }}
                              >
                                <PentoPixLogo
                                  sx={{ boxShadow: "0px 4px 8px 0px #c4c2c2" }}
                                  src={`/images/ghost-loader.png`}
                                />
                              </Box>
                              <Typography
                                variant="h7"
                                gutterBottom
                                align="center"
                                color={theme.palette.customColors?.main!}
                              >
                                This screen size is not supported yet.
                              </Typography>
                              <Typography
                                variant="body2"
                                align="center"
                                color={theme.palette.customColors?.main!}
                              >
                                Please switch to your desktop or laptop to use
                                PentoPix.
                              </Typography>
                            </Paper>
                          </Box>
                        </ThemeComponent>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              </Elements>
            </AuthProvider>
          </VeltProvider>
        </CacheProvider>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>{`${themeConfig.templateName} | AI Video Generator | Script to animated 3D storyboard `}</title>
          <meta
            name="description"
            content={`${themeConfig.templateName}  | AI Video Generator | Script to animated 3D storyboard `}
          />
          <meta
            property="og:image"
            content="https://studio.pentopix.com/images/pages/pentopixLogo/pentopix_logo_light.png"
          />
          <meta
            name="keywords"
            content="Material Design, MUI, Admin Template, React Admin Template"
          />
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <VeltProvider apiKey={process.env.NEXT_PUBLIC_VELT_API_KEY as string}>
          <AuthProvider>
            <Elements stripe={stripePromise}>
              <SettingsProvider
                {...(setConfig ? { pageSettings: setConfig() } : {})}
              >
                <SettingsConsumer>
                  {({ settings }) => {
                    return (
                      <ThemeComponent settings={settings}>
                        <Guard authGuard={authGuard}>
                          {getLayout(<Component {...pageProps} />)}
                        </Guard>
                        <ReactHotToast>
                          <Toaster
                            position={settings.toastPosition}
                            toastOptions={{ className: "react-hot-toast" }}
                          />
                        </ReactHotToast>
                      </ThemeComponent>
                    );
                  }}
                </SettingsConsumer>
              </SettingsProvider>
            </Elements>
          </AuthProvider>
        </VeltProvider>
      </CacheProvider>
    </Provider>
  );
};

export default App;
