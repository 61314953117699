import AWS from "aws-sdk";
import axios from "axios";
import UAParser from "ua-parser-js";
import { v4 } from "uuid";

import { IScene } from "src/@core/context/DesignEditorContext/scene";

const crypto = require("crypto");
const algorithm = "aes-256-ctr";

const s3_media_client = new AWS.S3({
  region: process.env.PENTOPIX_MEDIA_BUCKET_REGION,
});

export const encrypt = (text: string) => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(
    algorithm,
    Buffer.from(process.env.CRYPTO_SECRET, "hex"),
    iv,
  );
  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

  return iv.toString("hex") + ":" + encrypted.toString("hex");
};

export const decrypt = (hash: any) => {
  const [iv, encrypted] = String(hash).split(":");
  const decipher = crypto.createDecipheriv(
    algorithm,
    Buffer.from(process.env.CRYPTO_SECRET, "hex"),
    Buffer.from(iv, "hex"),
  );
  const decrypted = Buffer.concat([
    decipher.update(Buffer.from(encrypted, "hex")),
    decipher.final(),
  ]);

  return decrypted.toString();
};

export const htmlToPlainText = (html) => {
  // Create a new DOMParser instance
  const parser = new DOMParser();
  // Parse the HTML string into a new document
  const doc = parser.parseFromString(html, "text/html");
  // Return the text content of the parsed document
  return doc.body.textContent || "";
};

export function extractTextFromScript(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const pElements = doc.querySelectorAll(
    'p[type="scene"], p[type="action"], p[type="character"], p[type="dialogue"]',
  );
  let sceneText = "";

  pElements.forEach((element) => {
    sceneText += element.textContent + " ";
  });

  return sceneText.trim();
}

export function createObjectsFromHTML(
  scene: IScene,
  acSceneObjects?: Array<any>,
  isParsing?: boolean,
) {
  const htmlString = scene.script;
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const elements = doc.querySelectorAll('p:not([type="predefined"])');
  let plainText2 = "";
  let characterName = "";
  let gender = "";
  const textTags = [];
  const sceneActors = [];
  const sceneObjects =
    (!isParsing &&
      (scene.sceneObjects || []).filter(
        (so) => !so.type || so.type != "copied",
      )) ||
    [];
  const dummyActors = [];
  let sceneDescription = "";

  function processElement(element, isNested = false) {
    let type = element.getAttribute("type");
    const subtype = element.getAttribute("subtype");

    if (!type) type = element.getAttribute("data-type");

    const dataUID = element.getAttribute("data-uid");
    let elementText = element.textContent.replace(/&ZeroWidthSpace;/g, "");

    if (type === "character") {
      characterName = elementText;
      elementText = elementText + ". ";
      plainText2 += elementText;
    } else if (type === "scene-description") {
      sceneDescription = elementText;
      return;
    } else if (!isNested) {
      elementText = elementText + " ";
      plainText2 += elementText;
    }

    const startIndex = plainText2.indexOf(elementText);
    const endIndex = startIndex + elementText.length;

    const obj = { begin: startIndex, end: endIndex };

    if (type === "dialogue") {
      obj.speech_name_tag = { actor_name: characterName, gender: gender };
      obj.dialogue_text = elementText;
    } else if (
      type === "scene" ||
      type === "action" ||
      type === "parentheses"
    ) {
      obj.prose_tag = {};
    } else if (type === "transition") {
      obj.transition_type = elementText.toLowerCase() === "fade out" ? 1 : 0;
    }

    if (dataUID && type == "actor") {
      obj.actor_tag = { actor_uid: dataUID };

      const sha512_digest = element.getAttribute("data-sha512_digest");
      const rpmAvatarUrl = element.getAttribute("data-avatarurl");
      const display_name = element.textContent;
      gender = element.getAttribute("gender");
      obj.gender = gender;
      const displayNameExists = sceneActors.some(
        (actor) => actor.display_name === display_name,
      );
      if (subtype === "dummyactor") {
        dummyActors.push({
          sha512_digest,
          uid: dataUID,
          display_name,
        });
      }
      if (!displayNameExists) {
        sceneActors.push({
          sha512_digest,
          uid: dataUID,
          display_name,
          gender,
          rpmAvatarUrl,
          subtype,
        });
      }
    } else if (dataUID && type == "object") {
      obj.object_tag = {
        object_uid: dataUID,
      };
      const sha512_digest = element.getAttribute("data-sha512_digest");
      const display_name = element.textContent;
      const displayNameExists = sceneObjects.some(
        (object) => object.display_name === display_name,
      );
      if (!displayNameExists) {
        sceneObjects.push({
          sha512_digest,
          uid: dataUID,
          display_name,
          isScriptObject: true
        });
      }
    }

    textTags.push(obj);

    // Process nested elements recursively
    element.childNodes.forEach((child) => {
      if (child.nodeType === 1) {
        // Check if child is an element
        processElement(child, true); // Mark as nested
      }
    });
  }

  elements.forEach((element) => {
    processElement(element);
  });

  return {
    textTags: textTags,
    currentScript: plainText2,
    sceneActors,
    sceneObjects,
    dummyActors,
    sceneDescription,
  };
}

// Core Functions
export const getActiveStyle = (styleName, activeObject) => {
  if (!activeObject) {
    return "";
  }
  return activeObject.getSelectionStyles && activeObject.isEditing
    ? activeObject.getSelectionStyles()[styleName] || ""
    : activeObject[styleName] || "";
};

export const setActiveStyle = (styleName, value, activeObject) => {
  if (!activeObject) {
    return;
  }

  if (activeObject.setSelectionStyles && activeObject.isEditing) {
    const style = {};
    style[styleName] = value;
    activeObject.setSelectionStyles(style);
    activeObject.setCoords();
  } else {
    activeObject.set(styleName, value);
  }

  activeObject.setCoords();
  activeObject.canvas?.renderAll();
};

export const getActiveProp = (name, activeObject) => {
  if (!activeObject) {
    return "";
  }
  return activeObject[name] || "";
};

export const setActiveProp = (name, value, activeObject) => {
  if (!activeObject) {
    return;
  }
  activeObject.set(name, value).setCoords();
  activeObject.canvas.renderAll();
};

export const convertMS = (milliseconds) => {
  // Calculate minutes, seconds, and milliseconds
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  const millisecond = Math.floor((milliseconds % 1000) / 100);

  // Convert values to strings and pad with leading zeros if necessary
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  const formattedMilliseconds = millisecond.toString();

  // Concatenate the formatted parts
  return (
    formattedMinutes + ":" + formattedSeconds + ":" + formattedMilliseconds
  );
};

export const correctEncodedCookies = () => {
  const cookies = document.cookie.split(";");
  const expirationDate = new Date(Date.now() + 31536000000);

  // Delete older cookies
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const key = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const value = eqPos > -1 ? cookie.substr(eqPos + 1) : "";
    // Set expiration date to a past date
    document.cookie = `${key}=${value};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
  }

  // Set corrected cookies
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const eqPos = cookie.indexOf("=");
    const key =
      eqPos > -1 ? decodeURIComponent(cookie.substr(0, eqPos)) : cookie;
    const value =
      eqPos > -1 ? decodeURIComponent(cookie.substr(eqPos + 1)) : "";
    const correctedKey = key.replace(/%2F/g, "/");
    const correctedValue = value.replace(/%3D/g, "=");

    // Set corrected cookie with new expiration date
    document.cookie = `${correctedKey}=${correctedValue};expires=${expirationDate.toUTCString()};path=/;`;
  }
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const key = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const value = eqPos > -1 ? cookie.substr(eqPos + 1) : "";
    document.cookie = `${key}=${value};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
  }
};

export const calculateLeastFourThreeRatio = (width: number, height: number) => {
  // Calculate the current aspect ratio
  const aspectRatio: number = width / height;

  // Calculate the best nearest 4:3 ratio
  if (aspectRatio > 4 / 3) {
    // The width is wider, adjust width based on height
    const newWidth: number = Math.round(height * (4 / 3));
    return { width: newWidth, height: height };
  } else {
    // The height is taller, adjust height based on width
    const newHeight: number = Math.round(width * (3 / 4));
    return { width: width, height: newHeight };
  }
};

export enum apiStatus {
  notStarted,
  processing,
  completed,
  error,
}

export const maskEmail = (email: string) => {
  const [username, domain] = email.split("@");
  let maskedUsername = "";
  for (let i = 0; i < username.length; i++) {
    maskedUsername += i % 2 === 0 ? username[i] : "*";
  }
  return maskedUsername + "@" + domain;
};

export const getShotIdByTime = (
  videoLayers: any,
  time: number,
  selectedVideoType: string,
) => {
  for (const layer of videoLayers) {
    const videoView = layer[selectedVideoType]
      ? selectedVideoType
      : "videoWideAngle";
    if (
      time >= parseFloat(layer[videoView].display.from) &&
      time <= parseFloat(layer[videoView].display.to)
    ) {
      return layer[videoView].id;
    }
  }
  return null;
};

export function mapNumberToCapitalLetter(number) {
  const base = "A".charCodeAt(0) - 1; // ASCII code of 'A' minus 1

  let quotient = Math.floor((number - 1) / 26); // Number of times to append 'Z'
  let remainder = number % 26; // Remainder to determine the letter

  if (remainder === 0) {
    remainder = 26; // Set remainder to 26 if it is 0
    quotient--; // Reduce quotient when remainder is 26
  }

  let letter = String.fromCharCode(base + remainder);

  if (quotient > 0) {
    letter += "Z".repeat(quotient);
  }

  return letter;
}

export const getMonthYearFormattedDate = (dateString: string): string => {
  const date = new Date(dateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  const formattedDate = `${month} ${year}`;

  return formattedDate;
};

export const humanizeTimestamp = (timestamp) => {
  const currentDate = new Date();
  const activityDate = new Date(timestamp);

  // Set the time to midnight for both dates
  currentDate.setHours(0, 0, 0, 0);
  activityDate.setHours(0, 0, 0, 0);

  // Calculate the difference in days
  const diffTime = currentDate.getTime() - activityDate.getTime();
  const diffDays = diffTime / (1000 * 60 * 60 * 24);

  if (diffDays < 1) {
    return "Today";
  } else if (diffDays >= 1 && diffDays < 2) {
    return "Yesterday";
  } else {
    return `${Math.floor(diffDays)} days ago`;
  }
};

export const getScriptLocation = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const spanElement = doc.querySelector(`span[type="location"]`);
  let location = spanElement?.innerHTML.replace(/&nbsp;/g, "").trim();
  if (location?.toLowerCase().includes("oasis")) {
    location = "__event_oasis";
  } else if (
    location?.toLowerCase().includes("snowy") ||
    location?.toLowerCase().includes("christmas") ||
    location?.toLowerCase().includes("tree") ||
    location?.toLowerCase().includes("road") ||
    location?.toLowerCase().includes("steading")
  ) {
    location = "__event_christmas";
  }

  return location;
};

export const cameraShotCurves = () => {
  return {
    id: v4(),
    name: "",
    type: "Camera",
    duration: 10,
    camera_uid: v4(),
    isActive: true,
    isDeletable: true,
    display: {
      from: 0,
      to: 10,
    },
    canvasKeyframes: [],
    canvasPaths: [],
    target_entity_uid: {
      keyframes: [],
    },
    tracked: {
      keyframes: [],
    },
    relative_position: {
      x: {
        keyframes: [],
      },
      y: {
        keyframes: [],
      },
      z: {
        keyframes: [],
      },
    },
    relative_orientation: {
      keyframes: [],
    },
    focal_length: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    aperture: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    focus_distance: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    angle: {
      keyframes: [],
    },
    framing: {
      keyframes: [],
    },
  };
};

export function hasEditPermissions(roles) {
  if (roles) return roles?.includes("Owner") || roles?.includes("Editor");
  else return false;
}

export function highlightObjects(arg1, arg2) {
  // Parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(arg2.toString(), "text/html");

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  // Helper function to update or wrap the matched text
  function replaceOrWrap(node, obj) {
    if (node.parentNode && node.parentNode.nodeName === "SPAN") {
      // If already inside a span, update the attributes
      node.parentNode.setAttribute("data-type", obj.type);
      node.parentNode.setAttribute("contenteditable", "false");
      node.parentNode.setAttribute("data-uid", obj.uid);
      node.parentNode.setAttribute("parsed-name", obj.parsed_name);
      if (obj.type == "actor") {
        node.parentNode.setAttribute("data-sha512_digest", obj.sha512_digest);
      }
    } else {
      const currentNodeValue = node.nodeValue;
      const parentNode = node.parentNode;

      // Regular expression with word boundaries
      const regex = new RegExp(`\\b${escapeRegExp(obj.display_name)}\\b`, "gi");

      // Finding matches and their indices
      let match;
      const matches = [];
      while ((match = regex.exec(currentNodeValue)) !== null) {
        matches.push({ text: match[0], index: match.index });
      }

      // Clear the text node
      node.nodeValue = "";

      let lastIndex = 0;
      matches.forEach((match) => {
        // Text before the match
        const beforeMatch = currentNodeValue.slice(lastIndex, match.index);
        if (beforeMatch) {
          parentNode.insertBefore(document.createTextNode(beforeMatch), node);
        }

        // Create and insert the span for the matched text
        const span = document.createElement("span");
        span.textContent = match.text;
        span.setAttribute("data-type", obj.type);
        span.setAttribute("contenteditable", "false");
        span.setAttribute("data-sha512_digest", obj.sha512_digest);
        span.setAttribute("data-uid", obj.uid);
        span.setAttribute("parsed-name", obj.parsed_name);
        if (obj.type === "actor") {
          span.setAttribute("gender", "male");
        }
        parentNode.insertBefore(span, node);

        lastIndex = match.index + match.text.length;
      });

      // Append any remaining text after the last match
      const remainingText = currentNodeValue.slice(lastIndex);
      if (remainingText) {
        parentNode.insertBefore(document.createTextNode(remainingText), node);
      }

      // Remove the original (now empty) text node
      parentNode.removeChild(node);
    }
  }

  function isInsidePredefinedParagraph(node) {
    let currentElement = node.parentElement;
    while (currentElement && currentElement !== doc.body) {
      if (
        currentElement.tagName === "P" &&
        currentElement.getAttribute("type") === "predefined"
      ) {
        return true;
      }
      currentElement = currentElement.parentElement;
    }
    return false;
  }

  // Iterate over each object in the array
  arg1.forEach((obj) => {
    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT);
    const nodesToReplace = [];
    let node;
    while ((node = walker.nextNode())) {
      if (
        !isInsidePredefinedParagraph(node) &&
        node.nodeValue.toLowerCase().includes(obj.display_name.toLowerCase())
      ) {
        nodesToReplace.push(node);
      }
    }

    nodesToReplace.forEach((node) => replaceOrWrap(node, obj));
  });

  return doc.body.innerHTML;
}

export const defaultCameraShotCurves = [
  {
    id: "51a8a7b8-803d-4584-8404-06c521c24ea3",
    name: "Default View",
    type: "Camera",
    angle: {
      keyframes: [],
    },
    display: {
      to: 8,
      from: 0,
    },
    framing: {
      keyframes: [],
    },
    tracked: {
      keyframes: [],
    },
    aperture: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    duration: 8,
    camera_uid: "c0fd6cad-1b94-44f5-9670-a459da0427f4",
    focal_length: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    focus_distance: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    relative_position: {
      x: {
        keyframes: [],
      },
      y: {
        keyframes: [],
      },
      z: {
        keyframes: [],
      },
    },
    target_entity_uid: {
      keyframes: [],
    },
    relative_orientation: {
      keyframes: [],
    },
  },
  {
    id: "51a8a7b8-803d-4584-8404-06c521c24ea3",
    name: "360 View",
    type: "Camera",
    display: {
      to: 8,
      from: 0,
    },
    angle: {
      keyframes: [],
    },
    tracked: {
      keyframes: [
        {
          frame: 0,
          value: true,
        },
      ],
    },
    framing: {
      keyframes: [],
    },
    aperture: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    duration: 8,
    camera_uid: "fb91b7fb-cf29-4b5c-9a0e-0d255a8cce2f",
    focal_length: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    focus_distance: {
      keyframes: [
        {
          frame: 0,
          value: 35,
        },
      ],
    },
    relative_position: {
      x: {
        keyframes: [
          {
            frame: 0,
            value: -500,
          },
          {
            frame: 1,
            value: -498.9294616193017,
          },
          {
            frame: 2,
            value: -495.72243068690517,
          },
          {
            frame: 3,
            value: -490.3926402016152,
          },
          {
            frame: 4,
            value: -482.9629131445342,
          },
          {
            frame: 5,
            value: -473.46506474755284,
          },
          {
            frame: 6,
            value: -461.93976625564335,
          },
          {
            frame: 7,
            value: -448.43637076634417,
          },
          {
            frame: 8,
            value: -433.01270189221935,
          },
          {
            frame: 9,
            value: -415.7348061512726,
          },
          {
            frame: 10,
            value: -396.6766701456176,
          },
          {
            frame: 11,
            value: -375.91990373948875,
          },
          {
            frame: 12,
            value: -353.5533905932738,
          },
          {
            frame: 13,
            value: -329.67290755003444,
          },
          {
            frame: 14,
            value: -304.38071450436036,
          },
          {
            frame: 15,
            value: -277.7851165098012,
          },
          {
            frame: 16,
            value: -250.00000000000006,
          },
          {
            frame: 17,
            value: -221.14434510950062,
          },
          {
            frame: 18,
            value: -191.34171618254493,
          },
          {
            frame: 19,
            value: -160.71973265158084,
          },
          {
            frame: 20,
            value: -129.40952255126038,
          },
          {
            frame: 21,
            value: -97.54516100806417,
          },
          {
            frame: 22,
            value: -65.26309611002586,
          },
          {
            frame: 23,
            value: -32.70156461507163,
          },
          {
            frame: 24,
            value: -3.061616997868383e-14,
          },
          {
            frame: 25,
            value: 32.701564615071575,
          },
          {
            frame: 26,
            value: 65.2630961100258,
          },
          {
            frame: 27,
            value: 97.5451610080641,
          },
          {
            frame: 28,
            value: 129.40952255126032,
          },
          {
            frame: 29,
            value: 160.71973265158078,
          },
          {
            frame: 30,
            value: 191.34171618254476,
          },
          {
            frame: 31,
            value: 221.14434510950056,
          },
          {
            frame: 32,
            value: 249.99999999999991,
          },
          {
            frame: 33,
            value: 277.7851165098012,
          },
          {
            frame: 34,
            value: 304.38071450436036,
          },
          {
            frame: 35,
            value: 329.67290755003444,
          },
          {
            frame: 36,
            value: 353.5533905932737,
          },
          {
            frame: 37,
            value: 375.91990373948863,
          },
          {
            frame: 38,
            value: 396.67667014561755,
          },
          {
            frame: 39,
            value: 415.7348061512725,
          },
          {
            frame: 40,
            value: 433.01270189221935,
          },
          {
            frame: 41,
            value: 448.43637076634406,
          },
          {
            frame: 42,
            value: 461.93976625564335,
          },
          {
            frame: 43,
            value: 473.4650647475528,
          },
          {
            frame: 44,
            value: 482.9629131445341,
          },
          {
            frame: 45,
            value: 490.3926402016152,
          },
          {
            frame: 46,
            value: 495.72243068690517,
          },
          {
            frame: 47,
            value: 498.9294616193017,
          },
          {
            frame: 48,
            value: 500,
          },
          {
            frame: 49,
            value: 498.9294616193017,
          },
          {
            frame: 50,
            value: 495.72243068690517,
          },
          {
            frame: 51,
            value: 490.3926402016153,
          },
          {
            frame: 52,
            value: 482.9629131445342,
          },
          {
            frame: 53,
            value: 473.46506474755284,
          },
          {
            frame: 54,
            value: 461.9397662556434,
          },
          {
            frame: 55,
            value: 448.43637076634406,
          },
          {
            frame: 56,
            value: 433.0127018922194,
          },
          {
            frame: 57,
            value: 415.7348061512727,
          },
          {
            frame: 58,
            value: 396.6766701456176,
          },
          {
            frame: 59,
            value: 375.91990373948875,
          },
          {
            frame: 60,
            value: 353.55339059327395,
          },
          {
            frame: 61,
            value: 329.67290755003455,
          },
          {
            frame: 62,
            value: 304.3807145043604,
          },
          {
            frame: 63,
            value: 277.78511650980107,
          },
          {
            frame: 64,
            value: 250.00000000000023,
          },
          {
            frame: 65,
            value: 221.14434510950068,
          },
          {
            frame: 66,
            value: 191.34171618254476,
          },
          {
            frame: 67,
            value: 160.7197326515809,
          },
          {
            frame: 68,
            value: 129.40952255126032,
          },
          {
            frame: 69,
            value: 97.54516100806433,
          },
          {
            frame: 70,
            value: 65.26309611002581,
          },
          {
            frame: 71,
            value: 32.70156461507136,
          },
          {
            frame: 72,
            value: 9.184850993605149e-14,
          },
          {
            frame: 73,
            value: -32.701564615071185,
          },
          {
            frame: 74,
            value: -65.26309611002563,
          },
          {
            frame: 75,
            value: -97.54516100806416,
          },
          {
            frame: 76,
            value: -129.40952255126015,
          },
          {
            frame: 77,
            value: -160.71973265158073,
          },
          {
            frame: 78,
            value: -191.3417161825446,
          },
          {
            frame: 79,
            value: -221.1443451095005,
          },
          {
            frame: 80,
            value: -250.00000000000006,
          },
          {
            frame: 81,
            value: -277.7851165098009,
          },
          {
            frame: 82,
            value: -304.38071450435996,
          },
          {
            frame: 83,
            value: -329.67290755003455,
          },
          {
            frame: 84,
            value: -353.55339059327366,
          },
          {
            frame: 85,
            value: -375.91990373948875,
          },
          {
            frame: 86,
            value: -396.6766701456175,
          },
          {
            frame: 87,
            value: -415.7348061512724,
          },
          {
            frame: 88,
            value: -433.0127018922192,
          },
          {
            frame: 89,
            value: -448.4363707663441,
          },
          {
            frame: 90,
            value: -461.9397662556434,
          },
          {
            frame: 91,
            value: -473.4650647475528,
          },
          {
            frame: 92,
            value: -482.96291314453407,
          },
          {
            frame: 93,
            value: -490.39264020161517,
          },
          {
            frame: 94,
            value: -495.72243068690517,
          },
          {
            frame: 95,
            value: -498.9294616193017,
          },
        ],
      },
      y: {
        keyframes: [
          {
            frame: 0,
            value: 0,
          },
          {
            frame: 1,
            value: -32.70156461507153,
          },
          {
            frame: 2,
            value: -65.26309611002578,
          },
          {
            frame: 3,
            value: -97.54516100806413,
          },
          {
            frame: 4,
            value: -129.40952255126038,
          },
          {
            frame: 5,
            value: -160.71973265158078,
          },
          {
            frame: 6,
            value: -191.3417161825449,
          },
          {
            frame: 7,
            value: -221.14434510950062,
          },
          {
            frame: 8,
            value: -249.99999999999997,
          },
          {
            frame: 9,
            value: -277.78511650980107,
          },
          {
            frame: 10,
            value: -304.38071450436036,
          },
          {
            frame: 11,
            value: -329.67290755003444,
          },
          {
            frame: 12,
            value: -353.5533905932737,
          },
          {
            frame: 13,
            value: -375.9199037394887,
          },
          {
            frame: 14,
            value: -396.6766701456176,
          },
          {
            frame: 15,
            value: -415.73480615127255,
          },
          {
            frame: 16,
            value: -433.0127018922193,
          },
          {
            frame: 17,
            value: -448.43637076634417,
          },
          {
            frame: 18,
            value: -461.93976625564335,
          },
          {
            frame: 19,
            value: -473.4650647475528,
          },
          {
            frame: 20,
            value: -482.9629131445342,
          },
          {
            frame: 21,
            value: -490.3926402016152,
          },
          {
            frame: 22,
            value: -495.72243068690517,
          },
          {
            frame: 23,
            value: -498.9294616193017,
          },
          {
            frame: 24,
            value: -500,
          },
          {
            frame: 25,
            value: -498.9294616193017,
          },
          {
            frame: 26,
            value: -495.72243068690517,
          },
          {
            frame: 27,
            value: -490.3926402016152,
          },
          {
            frame: 28,
            value: -482.9629131445342,
          },
          {
            frame: 29,
            value: -473.46506474755284,
          },
          {
            frame: 30,
            value: -461.9397662556434,
          },
          {
            frame: 31,
            value: -448.43637076634417,
          },
          {
            frame: 32,
            value: -433.01270189221935,
          },
          {
            frame: 33,
            value: -415.73480615127255,
          },
          {
            frame: 34,
            value: -396.6766701456176,
          },
          {
            frame: 35,
            value: -375.9199037394887,
          },
          {
            frame: 36,
            value: -353.5533905932738,
          },
          {
            frame: 37,
            value: -329.6729075500345,
          },
          {
            frame: 38,
            value: -304.3807145043604,
          },
          {
            frame: 39,
            value: -277.78511650980124,
          },
          {
            frame: 40,
            value: -249.99999999999997,
          },
          {
            frame: 41,
            value: -221.14434510950085,
          },
          {
            frame: 42,
            value: -191.34171618254496,
          },
          {
            frame: 43,
            value: -160.71973265158087,
          },
          {
            frame: 44,
            value: -129.40952255126052,
          },
          {
            frame: 45,
            value: -97.54516100806408,
          },
          {
            frame: 46,
            value: -65.263096110026,
          },
          {
            frame: 47,
            value: -32.70156461507156,
          },
          {
            frame: 48,
            value: -6.123233995736766e-14,
          },
          {
            frame: 49,
            value: 32.70156461507143,
          },
          {
            frame: 50,
            value: 65.26309611002588,
          },
          {
            frame: 51,
            value: 97.54516100806396,
          },
          {
            frame: 52,
            value: 129.4095225512604,
          },
          {
            frame: 53,
            value: 160.71973265158076,
          },
          {
            frame: 54,
            value: 191.34171618254484,
          },
          {
            frame: 55,
            value: 221.14434510950073,
          },
          {
            frame: 56,
            value: 249.9999999999999,
          },
          {
            frame: 57,
            value: 277.78511650980096,
          },
          {
            frame: 58,
            value: 304.38071450436036,
          },
          {
            frame: 59,
            value: 329.67290755003444,
          },
          {
            frame: 60,
            value: 353.55339059327355,
          },
          {
            frame: 61,
            value: 375.91990373948863,
          },
          {
            frame: 62,
            value: 396.6766701456175,
          },
          {
            frame: 63,
            value: 415.7348061512726,
          },
          {
            frame: 64,
            value: 433.0127018922192,
          },
          {
            frame: 65,
            value: 448.4363707663441,
          },
          {
            frame: 66,
            value: 461.9397662556434,
          },
          {
            frame: 67,
            value: 473.4650647475528,
          },
          {
            frame: 68,
            value: 482.9629131445342,
          },
          {
            frame: 69,
            value: 490.39264020161517,
          },
          {
            frame: 70,
            value: 495.72243068690517,
          },
          {
            frame: 71,
            value: 498.9294616193017,
          },
          {
            frame: 72,
            value: 500,
          },
          {
            frame: 73,
            value: 498.9294616193018,
          },
          {
            frame: 74,
            value: 495.7224306869052,
          },
          {
            frame: 75,
            value: 490.3926402016152,
          },
          {
            frame: 76,
            value: 482.9629131445342,
          },
          {
            frame: 77,
            value: 473.46506474755284,
          },
          {
            frame: 78,
            value: 461.93976625564346,
          },
          {
            frame: 79,
            value: 448.4363707663442,
          },
          {
            frame: 80,
            value: 433.0127018922193,
          },
          {
            frame: 81,
            value: 415.7348061512727,
          },
          {
            frame: 82,
            value: 396.67667014561783,
          },
          {
            frame: 83,
            value: 375.9199037394886,
          },
          {
            frame: 84,
            value: 353.55339059327383,
          },
          {
            frame: 85,
            value: 329.6729075500344,
          },
          {
            frame: 86,
            value: 304.3807145043604,
          },
          {
            frame: 87,
            value: 277.78511650980147,
          },
          {
            frame: 88,
            value: 250.00000000000023,
          },
          {
            frame: 89,
            value: 221.1443451095007,
          },
          {
            frame: 90,
            value: 191.3417161825448,
          },
          {
            frame: 91,
            value: 160.71973265158093,
          },
          {
            frame: 92,
            value: 129.40952255126078,
          },
          {
            frame: 93,
            value: 97.54516100806435,
          },
          {
            frame: 94,
            value: 65.26309611002584,
          },
          {
            frame: 95,
            value: 32.7015646150714,
          },
        ],
      },
    },
    target_entity_uid: {
      keyframes: [],
    },
  },
];

export const getCameraShotCurve360 = (targetEntityId: string) => {
  return {
    camera_uid: v4(),
    target_entity_uid: {
      keyframes: [
        {
          frame: 0,
          value: targetEntityId,
        },
      ],
    },
    source_entity_uid: {
      keyframes: [
        {
          frame: 0,
          value: targetEntityId,
        },
      ],
    },
    tracked: {
      keyframes: [
        {
          frame: 0,
          value: true,
        },
      ],
    },
    relative_position: {
      x: {
        keyframes: [
          {
            frame: 0,
            value: -5,
          },
          {
            frame: 1,
            value: -4.989294616193018,
          },
          {
            frame: 2,
            value: -4.957224306869052,
          },
          {
            frame: 3,
            value: -4.903926402016152,
          },
          {
            frame: 4,
            value: -4.8296291314453415,
          },
          {
            frame: 5,
            value: -4.7346506474755286,
          },
          {
            frame: 6,
            value: -4.619397662556434,
          },
          {
            frame: 7,
            value: -4.484363707663442,
          },
          {
            frame: 8,
            value: -4.330127018922194,
          },
          {
            frame: 9,
            value: -4.157348061512726,
          },
          {
            frame: 10,
            value: -3.966766701456176,
          },
          {
            frame: 11,
            value: -3.7591990373948874,
          },
          {
            frame: 12,
            value: -3.5355339059327378,
          },
          {
            frame: 13,
            value: -3.296729075500344,
          },
          {
            frame: 14,
            value: -3.0438071450436035,
          },
          {
            frame: 15,
            value: -2.7778511650980118,
          },
          {
            frame: 16,
            value: -2.5000000000000004,
          },
          {
            frame: 17,
            value: -2.2114434510950063,
          },
          {
            frame: 18,
            value: -1.9134171618254492,
          },
          {
            frame: 19,
            value: -1.6071973265158084,
          },
          {
            frame: 20,
            value: -1.2940952255126037,
          },
          {
            frame: 21,
            value: -0.9754516100806417,
          },
          {
            frame: 22,
            value: -0.6526309611002585,
          },
          {
            frame: 23,
            value: -0.3270156461507163,
          },
          {
            frame: 24,
            value: -3.061616997868383e-16,
          },
          {
            frame: 25,
            value: 0.32701564615071577,
          },
          {
            frame: 26,
            value: 0.652630961100258,
          },
          {
            frame: 27,
            value: 0.975451610080641,
          },
          {
            frame: 28,
            value: 1.2940952255126033,
          },
          {
            frame: 29,
            value: 1.607197326515808,
          },
          {
            frame: 30,
            value: 1.9134171618254476,
          },
          {
            frame: 31,
            value: 2.211443451095006,
          },
          {
            frame: 32,
            value: 2.499999999999999,
          },
          {
            frame: 33,
            value: 2.7778511650980118,
          },
          {
            frame: 34,
            value: 3.0438071450436035,
          },
          {
            frame: 35,
            value: 3.296729075500344,
          },
          {
            frame: 36,
            value: 3.5355339059327373,
          },
          {
            frame: 37,
            value: 3.7591990373948865,
          },
          {
            frame: 38,
            value: 3.9667667014561756,
          },
          {
            frame: 39,
            value: 4.1573480615127245,
          },
          {
            frame: 40,
            value: 4.330127018922194,
          },
          {
            frame: 41,
            value: 4.484363707663441,
          },
          {
            frame: 42,
            value: 4.619397662556434,
          },
          {
            frame: 43,
            value: 4.734650647475528,
          },
          {
            frame: 44,
            value: 4.8296291314453415,
          },
          {
            frame: 45,
            value: 4.903926402016152,
          },
          {
            frame: 46,
            value: 4.957224306869052,
          },
          {
            frame: 47,
            value: 4.989294616193018,
          },
          {
            frame: 48,
            value: 5,
          },
          {
            frame: 49,
            value: 4.989294616193018,
          },
          {
            frame: 50,
            value: 4.957224306869052,
          },
          {
            frame: 51,
            value: 4.903926402016153,
          },
          {
            frame: 52,
            value: 4.8296291314453415,
          },
          {
            frame: 53,
            value: 4.7346506474755286,
          },
          {
            frame: 54,
            value: 4.619397662556434,
          },
          {
            frame: 55,
            value: 4.484363707663441,
          },
          {
            frame: 56,
            value: 4.3301270189221945,
          },
          {
            frame: 57,
            value: 4.157348061512727,
          },
          {
            frame: 58,
            value: 3.966766701456176,
          },
          {
            frame: 59,
            value: 3.7591990373948874,
          },
          {
            frame: 60,
            value: 3.5355339059327395,
          },
          {
            frame: 61,
            value: 3.2967290755003456,
          },
          {
            frame: 62,
            value: 3.043807145043604,
          },
          {
            frame: 63,
            value: 2.777851165098011,
          },
          {
            frame: 64,
            value: 2.500000000000002,
          },
          {
            frame: 65,
            value: 2.2114434510950067,
          },
          {
            frame: 66,
            value: 1.9134171618254476,
          },
          {
            frame: 67,
            value: 1.607197326515809,
          },
          {
            frame: 68,
            value: 1.2940952255126033,
          },
          {
            frame: 69,
            value: 0.9754516100806433,
          },
          {
            frame: 70,
            value: 0.6526309611002581,
          },
          {
            frame: 71,
            value: 0.3270156461507136,
          },
          {
            frame: 72,
            value: 9.184850993605148e-16,
          },
          {
            frame: 73,
            value: -0.3270156461507118,
          },
          {
            frame: 74,
            value: -0.6526309611002563,
          },
          {
            frame: 75,
            value: -0.9754516100806415,
          },
          {
            frame: 76,
            value: -1.2940952255126015,
          },
          {
            frame: 77,
            value: -1.6071973265158073,
          },
          {
            frame: 78,
            value: -1.9134171618254459,
          },
          {
            frame: 79,
            value: -2.211443451095005,
          },
          {
            frame: 80,
            value: -2.5000000000000004,
          },
          {
            frame: 81,
            value: -2.777851165098009,
          },
          {
            frame: 82,
            value: -3.0438071450435995,
          },
          {
            frame: 83,
            value: -3.2967290755003456,
          },
          {
            frame: 84,
            value: -3.5355339059327364,
          },
          {
            frame: 85,
            value: -3.7591990373948874,
          },
          {
            frame: 86,
            value: -3.9667667014561747,
          },
          {
            frame: 87,
            value: -4.157348061512724,
          },
          {
            frame: 88,
            value: -4.330127018922192,
          },
          {
            frame: 89,
            value: -4.484363707663441,
          },
          {
            frame: 90,
            value: -4.619397662556434,
          },
          {
            frame: 91,
            value: -4.734650647475528,
          },
          {
            frame: 92,
            value: -4.829629131445341,
          },
          {
            frame: 93,
            value: -4.903926402016151,
          },
          {
            frame: 94,
            value: -4.957224306869052,
          },
          {
            frame: 95,
            value: -4.989294616193018,
          },
        ],
      },
      y: {
        keyframes: [
          {
            frame: 0,
            value: 0,
          },
          {
            frame: 1,
            value: -0.3270156461507153,
          },
          {
            frame: 2,
            value: -0.6526309611002579,
          },
          {
            frame: 3,
            value: -0.9754516100806413,
          },
          {
            frame: 4,
            value: -1.2940952255126037,
          },
          {
            frame: 5,
            value: -1.607197326515808,
          },
          {
            frame: 6,
            value: -1.913417161825449,
          },
          {
            frame: 7,
            value: -2.2114434510950063,
          },
          {
            frame: 8,
            value: -2.4999999999999996,
          },
          {
            frame: 9,
            value: -2.777851165098011,
          },
          {
            frame: 10,
            value: -3.0438071450436035,
          },
          {
            frame: 11,
            value: -3.296729075500344,
          },
          {
            frame: 12,
            value: -3.5355339059327373,
          },
          {
            frame: 13,
            value: -3.759199037394887,
          },
          {
            frame: 14,
            value: -3.966766701456176,
          },
          {
            frame: 15,
            value: -4.157348061512725,
          },
          {
            frame: 16,
            value: -4.330127018922193,
          },
          {
            frame: 17,
            value: -4.484363707663442,
          },
          {
            frame: 18,
            value: -4.619397662556434,
          },
          {
            frame: 19,
            value: -4.734650647475528,
          },
          {
            frame: 20,
            value: -4.8296291314453415,
          },
          {
            frame: 21,
            value: -4.903926402016152,
          },
          {
            frame: 22,
            value: -4.957224306869052,
          },
          {
            frame: 23,
            value: -4.989294616193018,
          },
          {
            frame: 24,
            value: -5,
          },
          {
            frame: 25,
            value: -4.989294616193018,
          },
          {
            frame: 26,
            value: -4.957224306869052,
          },
          {
            frame: 27,
            value: -4.903926402016152,
          },
          {
            frame: 28,
            value: -4.8296291314453415,
          },
          {
            frame: 29,
            value: -4.7346506474755286,
          },
          {
            frame: 30,
            value: -4.619397662556434,
          },
          {
            frame: 31,
            value: -4.484363707663442,
          },
          {
            frame: 32,
            value: -4.330127018922194,
          },
          {
            frame: 33,
            value: -4.157348061512725,
          },
          {
            frame: 34,
            value: -3.966766701456176,
          },
          {
            frame: 35,
            value: -3.759199037394887,
          },
          {
            frame: 36,
            value: -3.5355339059327378,
          },
          {
            frame: 37,
            value: -3.296729075500345,
          },
          {
            frame: 38,
            value: -3.043807145043604,
          },
          {
            frame: 39,
            value: -2.777851165098012,
          },
          {
            frame: 40,
            value: -2.4999999999999996,
          },
          {
            frame: 41,
            value: -2.2114434510950085,
          },
          {
            frame: 42,
            value: -1.9134171618254496,
          },
          {
            frame: 43,
            value: -1.6071973265158086,
          },
          {
            frame: 44,
            value: -1.2940952255126053,
          },
          {
            frame: 45,
            value: -0.9754516100806409,
          },
          {
            frame: 46,
            value: -0.65263096110026,
          },
          {
            frame: 47,
            value: -0.3270156461507156,
          },
          {
            frame: 48,
            value: -6.123233995736766e-16,
          },
          {
            frame: 49,
            value: 0.3270156461507143,
          },
          {
            frame: 50,
            value: 0.6526309611002589,
          },
          {
            frame: 51,
            value: 0.9754516100806395,
          },
          {
            frame: 52,
            value: 1.2940952255126041,
          },
          {
            frame: 53,
            value: 1.6071973265158075,
          },
          {
            frame: 54,
            value: 1.9134171618254485,
          },
          {
            frame: 55,
            value: 2.211443451095007,
          },
          {
            frame: 56,
            value: 2.4999999999999987,
          },
          {
            frame: 57,
            value: 2.7778511650980096,
          },
          {
            frame: 58,
            value: 3.0438071450436035,
          },
          {
            frame: 59,
            value: 3.296729075500344,
          },
          {
            frame: 60,
            value: 3.5355339059327355,
          },
          {
            frame: 61,
            value: 3.7591990373948865,
          },
          {
            frame: 62,
            value: 3.9667667014561747,
          },
          {
            frame: 63,
            value: 4.157348061512726,
          },
          {
            frame: 64,
            value: 4.330127018922192,
          },
          {
            frame: 65,
            value: 4.484363707663441,
          },
          {
            frame: 66,
            value: 4.619397662556434,
          },
          {
            frame: 67,
            value: 4.734650647475528,
          },
          {
            frame: 68,
            value: 4.8296291314453415,
          },
          {
            frame: 69,
            value: 4.903926402016151,
          },
          {
            frame: 70,
            value: 4.957224306869052,
          },
          {
            frame: 71,
            value: 4.989294616193018,
          },
          {
            frame: 72,
            value: 5,
          },
          {
            frame: 73,
            value: 4.989294616193018,
          },
          {
            frame: 74,
            value: 4.957224306869052,
          },
          {
            frame: 75,
            value: 4.903926402016152,
          },
          {
            frame: 76,
            value: 4.8296291314453415,
          },
          {
            frame: 77,
            value: 4.7346506474755286,
          },
          {
            frame: 78,
            value: 4.619397662556435,
          },
          {
            frame: 79,
            value: 4.484363707663443,
          },
          {
            frame: 80,
            value: 4.330127018922193,
          },
          {
            frame: 81,
            value: 4.157348061512727,
          },
          {
            frame: 82,
            value: 3.9667667014561783,
          },
          {
            frame: 83,
            value: 3.7591990373948856,
          },
          {
            frame: 84,
            value: 3.535533905932738,
          },
          {
            frame: 85,
            value: 3.2967290755003438,
          },
          {
            frame: 86,
            value: 3.043807145043604,
          },
          {
            frame: 87,
            value: 2.777851165098015,
          },
          {
            frame: 88,
            value: 2.500000000000002,
          },
          {
            frame: 89,
            value: 2.211443451095007,
          },
          {
            frame: 90,
            value: 1.9134171618254479,
          },
          {
            frame: 91,
            value: 1.6071973265158093,
          },
          {
            frame: 92,
            value: 1.2940952255126077,
          },
          {
            frame: 93,
            value: 0.9754516100806435,
          },
          {
            frame: 94,
            value: 0.6526309611002584,
          },
          {
            frame: 95,
            value: 0.327015646150714,
          },
        ],
      },
      z: {
        keyframes: [
          {
            frame: 0,
            value: 0.0005,
          },
        ],
      },
    },
    focal_length: {
      keyframes: [
        {
          frame: 0,
          value: 20,
        },
      ],
    },
  };
};

export const getCameraShotCurveTopDownView = () => {
  return {
    camera_uid: v4(),
    focal_length: {
      keyframes: [
        {
          frame: 0,
          value: 20,
        },
      ],
    },
    relative_position: {
      z: {
        keyframes: [
          {
            frame: 0,
            value: 10,
          },
        ],
      },
      x: {
        keyframes: [
          {
            frame: 0,
            value: 5,
          },
        ],
      },
      y: {
        keyframes: [
          {
            frame: 0,
            value: 0,
          },
        ],
      },
    },
    relative_orientation: {
      keyframes: [
        {
          frame: 0,
          value: {
            w: 0.7071067811865476,
            x: 0,
            y: 0.7071067811865476,
            z: 0,
          },
          incoming_scalar_tangent: 0,
          outgoing_scalar_tangent: 0,
        },
      ],
    },
  };
};

export const getCameraShotCurveWideAngleView = () => {
  return {
    camera_uid: v4(),
    focal_length: {
      keyframes: [
        {
          frame: 0,
          value: 5,
        },
      ],
    },
  };
};

export const toTitleCase = (str) => {
  return str
    .split(/\s+/) // Split by whitespace characters
    .map((word) => {
      // Check if the word is in all caps
      if (word.toUpperCase() === word) {
        // Convert the first character of the word to uppercase and the rest to lowercase
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      // Return the word as is if it's not in all caps
      return word;
    })
    .join(" ");
};

export const isSupportedDevice = (userAgent, width, height) => {
  const parser = new UAParser(userAgent);
  const deviceType = parser.getDevice().type;

  // Minimum width for tablets and above
  const minSupportedScreenWidth = 768;

  // Check if the device is a tablet (or possibly an iPad)
  if (deviceType === "tablet") {
    // Allow only landscape mode for tablets
    return width > height;
  }

  // Check for laptops and desktops
  if (width >= minSupportedScreenWidth) {
    return true;
  }

  // Mobile and others are not supported
  return false;
};

export const getColorFromArray = (index) => {
  const pastelColors = [
    "#FFD1DC",
    "#AEC6CF",
    "#FFD700",
    "#98FB98",
    "#FFB6C1",
    "#B0E0E6",
    "#FFA07A",
    "#C1E1C1",
  ];
  return pastelColors[index % 8];
};

export const pureConstructOriginalTextWithoutHighlighting = (htmlText) => {
  // Regular expression to match <span> elements with type="object" or type="actor"
  const spanRegex = /<span\s+type="(object|actor)"[^>]*>(.*?)<\/span>/g;
  const originalText = htmlText?.replace(spanRegex, "$2");
  return originalText;
};

export async function getSkyBoxImage(sceneDescription, guid) {
  const skyBoxApiUrl = process.env.SKYBOX_API_URL;
  const apiKey = process.env.SKYBOX_API_KEY;
  const webhookUrl =
    process.env.BASE_URL + "/api/projects/scenes/skyboxWebhook/" + guid;

  try {
    const response = await axios.post(
      skyBoxApiUrl,
      { prompt: sceneDescription, webhook_url: webhookUrl },
      {
        headers: {
          "x-api-key": apiKey,
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error calling SkyBox API:", error.message);
    return null;
  }
}

interface PcgVolumeProto {
  position_m: { x: number; y: number; z: number };
  scale: { x: number; y: number; z: number };
  orientation: { x: number; y: number; z: number; w: number };

  content_preset: {
    preset_type: number | string;
  };

  [layers: string]: any;

  pcg_volume_box: any;
  pcg_volume_texture2d: any;
}

export function getPcgVolumes(scene: IScene) {
  const pcgVolumes: PcgVolumeProto[] = [];

  for (const pcgVolume of scene?.pcgVolumes || []) {
    const newPcgVolume: PcgVolumeProto = {
      position_m: convertPositionFromThreeJsToUnrealEngine(
        pcgVolume.position_m,
      ),
      content_preset: {
        preset_type: pcgVolume.content_preset.preset_type,
      },
      scale: convertScaleFromThreeJsToUnrealEngine(pcgVolume.scale),
      orientation: convertQuaternionFromThreeJsToUnrealEngine(
        pcgVolume.orientation,
      ),
      layers: {},
    };

    function convertPCGLayer(sourceLayer) {
      // density, randomness, and limit are only used internally, and when
      // modified also change the actual protobuf fields, so we can just
      // serialize the protobuf fields.
      return {
        point_size_m: convertScaleFromThreeJsToUnrealEngine(
          sourceLayer.point_size_m,
        ),
        point_looseness: sourceLayer.point_looseness,
        point_density_upper_bound_per_m2:
          sourceLayer.point_density_upper_bound_per_m2,
      };
    }

    for (const [layerName, layer] of Object.entries(pcgVolume.layers)) {
      newPcgVolume.layers[layerName] = convertPCGLayer(layer);
    }

    if (pcgVolume.pcg_volume_box) {
      newPcgVolume.pcg_volume_box = pcgVolume.pcg_volume_box;
    }

    if (pcgVolume.pcg_volume_texture2d) {
      newPcgVolume.pcg_volume_texture2d = pcgVolume.pcg_volume_texture2d;
    }

    pcgVolumes.push(newPcgVolume);
  }

  return pcgVolumes;
}

export function getSceneEntityTransforms(scene) {
  const sceneEntityTransforms = [];
  for (const transform of scene?.sceneEntityTransforms || []) {
    sceneEntityTransforms.push({
      entity_uid: transform.entity_uid,
      position_m: convertPositionFromThreeJsToUnrealEngine(
        transform.position_m,
      ),
      orientation: convertQuaternionFromThreeJsToUnrealEngine(
        transform.orientation,
      ),
      scale: convertScaleFromThreeJsToUnrealEngine(transform.scale),
    });
  }
  return sceneEntityTransforms;
}

export const updateStaticLayer = (
  scene,
  setFitTimeline: any | null,
  cameraView: string,
) => {
  scene?.staticTextLayers.forEach((layer) => {
    if (layer.subType && layer.subType == "Directed_By") {
      let MaxSceneDuration = 0;
      scene?.audioLayers.forEach((audioLayer) => {
        MaxSceneDuration = Number(audioLayer.display.to);
      });
      scene?.staticImageLayers.forEach((imageLayer) => {
        if (
          Number(imageLayer.display.to) > MaxSceneDuration &&
          imageLayer.subType != "Produced_By"
        )
          MaxSceneDuration = Number(imageLayer.display.to);
      });

      scene?.staticTextLayers.forEach((textLayer) => {
        if (
          Number(textLayer.display.to) > MaxSceneDuration &&
          textLayer.subType != "Directed_By"
        )
          MaxSceneDuration = Number(textLayer.display.to);
      });

      (scene?.videoLayers || [])
        .filter((l) => l[cameraView])
        .forEach((vl) => {
          const videoLayer = vl[cameraView] || vl.videoWideAngle;
          if (Number(videoLayer.display.to) > MaxSceneDuration)
            MaxSceneDuration = Number(videoLayer.display.to);
        });

      layer.display.from = Math.round(MaxSceneDuration.toFixed(2) * 10) / 10;
      layer.display.to = (MaxSceneDuration + Number(layer.duration)).toFixed(2);
    }
  });
  scene?.staticImageLayers.forEach((layer) => {
    if (layer.subType && layer.subType == "Produced_By") {
      let MaxSceneDuration = 0;
      scene?.audioLayers.forEach((audioLayer) => {
        MaxSceneDuration = Number(audioLayer.display.to);
      });
      scene?.staticImageLayers.forEach((imageLayer) => {
        if (
          Number(imageLayer.display.to) > MaxSceneDuration &&
          imageLayer.subType != "Produced_By"
        )
          MaxSceneDuration = Number(imageLayer.display.to);
      });
      scene?.staticTextLayers.forEach((textLayer) => {
        if (Number(textLayer.display.to) > MaxSceneDuration)
          MaxSceneDuration = Number(textLayer.display.to);
      });

      (scene?.videoLayers || [])
        .filter((l: any) => l[cameraView])
        .forEach((vl: any) => {
          const videoLayer = vl[cameraView] || vl.videoWideAngle;
          if (Number(videoLayer.display.to) > MaxSceneDuration)
            MaxSceneDuration = Number(videoLayer.display.to);
        });

      if (scene?.display?.to > MaxSceneDuration) {
        MaxSceneDuration = Number(scene?.display?.to);
      }

      layer.display.from = Math.round(MaxSceneDuration.toFixed(2) * 10) / 10;
      layer.display.to = (MaxSceneDuration + Number(layer.duration)).toFixed(2);
      scene.duration = Number(layer.display.to);
    }
  });
  if (setFitTimeline) setFitTimeline(true);
};

export function executeCommand(command: string) {
  return new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      // This code will only run on the server
      const { exec } = require("child_process");
      exec(command, (error: any, stdout: any, stderr: any) => {
        if (error) {
          reject(`exec error: ${error}`);
          return;
        }
        resolve(`stdout: ${stdout}`);
      });
    }
  });
}

export const uploadToS3 = async (filePath, key) => {
  return new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      const fs = require("fs");
      const fileStream = fs.createReadStream(filePath);

      const params = {
        Bucket: process.env.PENTOPIX_MEDIA_BUCKET,
        Key: key,
        Body: fileStream,
        ContentType: "audio/mpeg",
      };

      s3_media_client.upload(params, (err, data) => {
        if (err) {
          return reject(err);
        }
        resolve(data.Location); // Returns the URL of the uploaded file
      });
    } else {
      reject("Not an server side call.");
    }
  });
};

/*---------- Conversions from ThreeJS to Unreal Engine Start ---------------*/
export const convertPositionFromThreeJsToUnrealEngine = (threeJsPosition: {
  x: number;
  y: number;
  z: number;
}) => {
  // Flip handedness by flipping y, scale from centimeters to meters.
  return {
    x: threeJsPosition.x / 100,
    y: -(threeJsPosition.y / 100),
    z: threeJsPosition.z / 100,
  };
};

export const convertCameraPositionFromThreeJsToUnrealEngine =
  (threeJsPosition: { x: number; y: number; z: number }) => {
    // Flip handedness by flipping y, scale from centimeters to meters, and apply
    // the default initial camera transform.
    // TODO: This should not apply the default camera transform, as doing so is
    //     not appropriate when `tracking` and `source` are set.
    return {
      x: threeJsPosition.x / 100 + 5,
      y: -(threeJsPosition.y / 100),
      z: threeJsPosition.z / 100 - 1.4,
    };
  };

export const convertQuaternionFromThreeJsToUnrealEngine = (threeJsQuaternion: {
  x: number;
  y: number;
  z: number;
  w: number;
}) => {
  // Flip handedness by flipping y, but also flip x, z, and y again, because
  // the handedness also reversed the direction of a positive rotation.
  return {
    x: -threeJsQuaternion.x,
    y: threeJsQuaternion.y,
    z: -threeJsQuaternion.z,
    w: threeJsQuaternion.w,
  };
};

export const convertScaleFromThreeJsToUnrealEngine = (threeJsScale: {
  x: number;
  y: number;
  z: number;
}) => {
  // Scaling does not need any adjustment, as the axes agree up to identifying
  // antipodal points.
  return {
    x: threeJsScale.x,
    y: threeJsScale.y,
    z: threeJsScale.z,
  };
};
/*---------- Conversions from ThreeJS to Unreal Engine End ---------------*/

export const vizContextExpiryTime = () => {
  return new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString();
};
