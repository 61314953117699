import { useTheme } from '@mui/material/styles'
const ScenesIcon = (props: any) => {
    const theme = useTheme()

    const color = props.color ?? theme.palette.mode === 'light' ? '#000' : '#fff'
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill={color} d="M19 2H5a3.003 3.003 0 0 0-3 3v14a3.003 3.003 0 0 0 3 3h14c.182 0 .362-.022.54-.055c.066-.012.129-.03.193-.047a2.971 2.971 0 0 0 .531-.187c.084-.04.165-.086.245-.133c.075-.044.151-.086.222-.136l.018-.011l.021-.018c.014-.011.03-.017.043-.03c.007-.006.01-.015.015-.022A2.987 2.987 0 0 0 22 19V5a3.003 3.003 0 0 0-3-3zm.575 18.905A1.95 1.95 0 0 1 19 21H5a2.003 2.003 0 0 1-2-2v-4.725l3.763-3.762a1.753 1.753 0 0 1 2.474 0l3.405 3.404l.004.007l6.97 6.969l-.041.012zM21 19c0 .516-.202.982-.523 1.337l-6.769-6.768l1.056-1.055a1.787 1.787 0 0 1 2.472 0L21 16.277V19zm0-4.137l-3.057-3.056a2.75 2.75 0 0 0-3.886 0L13 12.862L9.944 9.806a2.753 2.753 0 0 0-3.888 0L3 12.86V5a2.003 2.003 0 0 1 2-2h14a2.003 2.003 0 0 1 2 2v9.863zM13.5 6a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3zm0 2a.5.5 0 1 1 0-1a.5.5 0 0 1 0 1z" /></svg>
    )
}
export default ScenesIcon;

// width="29" height="29" viewBox="0 0 29 29"