import { useTheme } from "@mui/material/styles";

import { useEffect } from "react";

import { useAuth } from "src/config/auth";

const CreditCount = (props: any) => {
  const credits = "5";
  const theme = useTheme();
  const { getUserData, userData } = useAuth();

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div
      style={{
        padding: "3px 6px",
        borderRadius: "8px",
        backgroundColor: theme.palette.customColors.tableHeaderBg,
        fontSize: "13px",
      }}
    >
      Credits: {userData?.lastUsedAccount.account.credits}
    </div>
  );
};
export default CreditCount;
