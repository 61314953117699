import { fabric } from "fabric";
import {
  RefCallback,
  SetStateAction,
  Dispatch,
  useState,
  useCallback,
  createContext,
  ReactNode,
} from "react";

import {
  IStaticImage,
  IStaticText,
  IStaticVideo,
} from "../DesignEditorContext/layers";

async function downloadAndCreateObjectURL(remoteImageUrl) {
  try {
    const response = await fetch(remoteImageUrl);
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    return objectURL;
  } catch (error) {
    console.error("failed to download remote image", error);
    return null;
  }
}

const controls = fabric.Object.prototype.controls;
const rotateControls = controls.mtr;
rotateControls.visible = false;
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = "#c6c8cc";
fabric.Object.prototype.cornerStyle = "circle";
fabric.Object.prototype.borderColor = "#c6c8cc";
fabric.Object.prototype.borderScaleFactor = 0.5;
fabric.Object.prototype.cornerSize = 6;

interface ICanvasContext {
  canvas: fabric.Canvas | null;
  clearCanvas: RefCallback<null>;
  initCanvas: RefCallback<HTMLCanvasElement | null>;
  activeObject: fabric.Object | null;
  setActiveObject: Dispatch<SetStateAction<fabric.Object | null>>;
  drawVideo: RefCallback<IStaticVideo | null>;
  drawImage: RefCallback<IStaticImage | null>;
  drawText: RefCallback<IStaticText | null>;
  canvasWidth: number | 0;
  setCanvasWidth: Dispatch<SetStateAction<number | 0>>;
  canvasHeight: number | 0;
  setCanvasHeight: Dispatch<SetStateAction<number | 0>>;
  canvasLoader: boolean | false;
  enableCanvasLoader: Dispatch<SetStateAction<boolean | false>>;
  reAlignObjectsBasedOnSize: Dispatch<SetStateAction<boolean | false>>;
  oldFrame: Object | { width: 0; height: 0 };
  setOldFrame: Dispatch<
    SetStateAction<
      | Object
      | {
          width: 0;
          height: 0;
        }
    >
  >;
  stopScaling: any;
}

export const CanvasContext = createContext<ICanvasContext>({
  canvas: null,
  clearCanvas: () => {},
  initCanvas: () => {},
  activeObject: null,
  setActiveObject: () => {},
  drawVideo: () => {},
  drawImage: () => {},
  drawText: () => {},
  canvasWidth: 0,
  setCanvasWidth: () => {},
  canvasHeight: 0,
  setCanvasHeight: () => {},
  canvasLoader: false,
  enableCanvasLoader: () => {},
  reAlignObjectsBasedOnSize: () => {},
  oldFrame: { width: 0, height: 0 },
  setOldFrame: () => {},
  stopScaling: () => {},
});

export const CanvasContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null);

  const [canvasWidth, setCanvasWidth] = useState<number>(0);
  const [canvasHeight, setCanvasHeight] = useState<number>(0);
  const [canvasLoader, enableCanvasLoader] = useState<boolean>(false);
  const [oldFrame, setOldFrame] = useState<any>({ width: 0, height: 0 });
  const [doScalingCalc, setDoScalingCalc] = useState<any>({
    doScaling: false,
  });

  const initCanvas = useCallback((el: HTMLCanvasElement) => {
    const canvasOptions = {
      preserveObjectStacking: false,
      selection: true,
      defaultCursor: "default",
      backgroundColor: "#000",
    };
    const c = new fabric.Canvas(el, canvasOptions);
    c.renderAll();
    setCanvas(c);
  }, []);

  const clearCanvas = useCallback(() => {
    setCanvas(null);
  }, []);

  const canvasMinFactor = () => {
    const canvasWidthFactor = canvasWidth / (oldFrame.width || canvasWidth);
    const canvasHeightFactor = canvasHeight / (oldFrame.height || canvasHeight);
    return Math.min(canvasWidthFactor, canvasHeightFactor);
  };

  const stopScaling = () => {
    doScalingCalc.doScaling = false;
  };

  const drawVideo = useCallback(
    async (videoLayer: IStaticVideo) => {
      if (canvas) {
        videoLayer.top = videoLayer.top ? videoLayer.top : 0;
        videoLayer.left = videoLayer.left ? videoLayer.left : 0;

        if (doScalingCalc.doScaling) {
          const canvasOldNewMinFactor = canvasMinFactor();
          videoLayer.scaleX *= canvasOldNewMinFactor;
          videoLayer.scaleY *= canvasOldNewMinFactor;
        }

        try {
          const localObjectURL = await downloadAndCreateObjectURL(
            videoLayer.preview,
          );

          fabric.Image.fromURL(localObjectURL, function (videoLayerThumbnail) {
            videoLayerThumbnail.set({
              id: videoLayer.id,
              scaleX: videoLayer.scaleX,
              scaleY: videoLayer.scaleY,
              top: videoLayer.top,
              left: videoLayer.left,
              width: videoLayer.width,
              height: videoLayer.height,
            });
            canvas?.add(videoLayerThumbnail);
          });
        } catch (error) {
          console.error("failed to draw video", error);
        }
      }
    },
    [canvas],
  );
  const drawImage = useCallback(
    async (imageLayer: IStaticImage) => {
      if (canvas) {
        imageLayer.top = imageLayer.top ? imageLayer.top : 0;
        imageLayer.left = imageLayer.left ? imageLayer.left : 0;
        if (doScalingCalc.doScaling && canvas.width != oldFrame.width) {
          const canvasOldNewMinFactor = canvasMinFactor();
          imageLayer.scaleX *= canvasOldNewMinFactor;
          imageLayer.scaleY *= canvasOldNewMinFactor;
        }

        const localObjectURL = await downloadAndCreateObjectURL(
          imageLayer.preview,
        );

        fabric.Image.fromURL(localObjectURL, function (imageLayerThumbnail) {
          imageLayerThumbnail.set({
            id: imageLayer.id,
            scaleX: imageLayer.scaleX,
            scaleY: imageLayer.scaleY,
            top: imageLayer.top,
            left: imageLayer.left,
            width: imageLayer.width,
            height: imageLayer.height,
          });
          canvas?.add(imageLayerThumbnail);
          // canvas?.setActiveObject(imageLayerThumbnail);
          // setActiveObject(imageLayerThumbnail);
          // canvas?.renderAll();
        });
      }
    },
    [canvas],
  );

  const drawText = useCallback(
    (staticTextLayer: IStaticText) => {
      if (canvas) {
        if (doScalingCalc.doScaling) {
          const canvasOldNewMinFactor = canvasMinFactor();
          staticTextLayer.scaleX *= canvasOldNewMinFactor;
          staticTextLayer.scaleY *= canvasOldNewMinFactor;
        }

        const textBox = new fabric.Textbox(staticTextLayer.text, {
          id: staticTextLayer.id,
          width: staticTextLayer.width,
          top: staticTextLayer.top,
          left: staticTextLayer.left,
          fontSize: staticTextLayer.fontSize,
          fontWeight: staticTextLayer.fontWeight,
          fontStyle: staticTextLayer.fontStyle,
          textAlign: staticTextLayer.textAlign,
          fontFamily: staticTextLayer.fontFamily,
          textDecoration: staticTextLayer.textDecoration,
          fill: staticTextLayer.fill,
          scaleX: staticTextLayer.scaleX,
          scaleY: staticTextLayer.scaleY,
          underline: staticTextLayer.textDecoration == "underline",
        });

        canvas?.add(textBox);
        canvas?.setActiveObject(textBox);
        setActiveObject(textBox);
      }
    },
    [canvas],
  );

  const reAlignObjectsBasedOnSize = useCallback(() => {
    // Assuming you have a fabric.js canvas object called 'canvas'

    // Get all the objects on the canvas
    const objects = canvas?.getObjects() || [];

    // Sort the objects based on their size and scale
    // objects.sort(function (a, b) {
    //   var sizeA = a.width * a.height * a.scaleX * a.scaleY;
    //   var sizeB = b.width * b.height * b.scaleX * b.scaleY;

    //   // Sort in ascending order
    //   return sizeB - sizeA;
    // });

    // Clear the canvas
    canvas?.clear();
    canvas?.setBackgroundColor("black");

    // Add the sorted objects back to the canvas
    for (let i = 0; i < objects.length; i++) {
      canvas?.add(objects[i]);
    }

    canvas?.bringToFront(objects[objects.length - 1]);

    // Since we are displaying all the objects at the selected time, no single object should be selected.
    setActiveObject(null);

    // Render the canvas
    canvas?.renderAll();
  }, [canvas]);

  return (
    <CanvasContext.Provider
      value={{
        canvas,
        clearCanvas,
        initCanvas,
        activeObject,
        setActiveObject,
        drawVideo,
        drawImage,
        drawText,
        canvasWidth,
        setCanvasWidth,
        canvasHeight,
        setCanvasHeight,
        canvasLoader,
        enableCanvasLoader,
        reAlignObjectsBasedOnSize,
        oldFrame,
        setOldFrame,
        stopScaling,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
