import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { LayoutProps } from "src/@core/layouts/types";
import Logo from "src/common/Logo";

interface Props {
  hidden: LayoutProps["hidden"];
  settings: LayoutProps["settings"];
  saveSettings: LayoutProps["saveSettings"];
  appBarContent: NonNullable<
    NonNullable<LayoutProps["horizontalLayoutProps"]>["appBar"]
  >["content"];
  appBarBranding: NonNullable<
    NonNullable<LayoutProps["horizontalLayoutProps"]>["appBar"]
  >["branding"];
}

const AppBarContent = (props: Props) => {
  // ** Props
  const {
    appBarContent: userAppBarContent,
    appBarBranding: userAppBarBranding,
  } = props;

  // ** Hooks
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {userAppBarBranding ? userAppBarBranding(props) : <Logo />}
      {userAppBarContent ? userAppBarContent(props) : null}
    </Box>
  );
};

export default AppBarContent;
