import { useTheme } from '@mui/material/styles'
const ShotIcon = (props: any) => {
    const theme = useTheme()

    const color = props.color ?? theme.palette.mode === 'light' ? '#000' : '#fff'
    return (
        <svg width="18" height="22" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.2973 8.28486H15.0673L28.2244 6.89754C28.3155 6.88795 28.4037 6.86051 28.4842 6.8168C28.5646 6.7731 28.6356 6.71397 28.6932 6.64281C28.7508 6.57165 28.7938 6.48983 28.8198 6.40205C28.8458 6.31426 28.8542 6.22222 28.8446 6.13118L28.6253 4.05154C28.5774 3.59635 28.4402 3.15507 28.2216 2.7529C28.0031 2.35073 27.7075 1.99554 27.3517 1.70761C26.9959 1.41969 26.5869 1.20466 26.1479 1.07482C25.709 0.944978 25.2488 0.902857 24.7937 0.950865L3.99685 3.14369C3.07805 3.24174 2.23563 3.7002 1.65438 4.41851C1.07312 5.13681 0.800478 6.05633 0.896246 6.97538L1.1119 9.01907V25.0145C1.11296 25.9385 1.48047 26.8244 2.13383 27.4778C2.78718 28.1312 3.67302 28.4988 4.59703 28.4999H25.5091C26.4332 28.4988 27.3191 28.1313 27.9724 27.4779C28.6258 26.8245 28.9934 25.9386 28.9944 25.0145V8.98196C28.9944 8.79708 28.921 8.61977 28.7903 8.48904C28.6595 8.35831 28.4822 8.28486 28.2973 8.28486ZM27.6003 13.1644H21.4482L24.4356 9.67905H27.6003V13.1644ZM12.143 3.68662L15.4794 6.83951L10.3731 7.37793L7.03675 4.22504L12.143 3.68662ZM19.0753 2.95568L22.4115 6.1087L17.3053 6.64706L13.969 3.4941L19.0753 2.95568ZM2.50582 9.67898H8.65803L5.67054 13.1643H2.50582V9.67898ZM10.4942 9.67898H15.6287L12.6413 13.1643H7.50675L10.4942 9.67898ZM17.465 9.67898H22.5995L19.6119 13.1644H14.4775L17.465 9.67898ZM24.9399 2.33734C25.0141 2.32953 25.0886 2.3256 25.1632 2.32557C25.6792 2.32667 26.1765 2.51844 26.5597 2.86403C26.9428 3.20961 27.1847 3.68461 27.2389 4.19773L27.3851 5.58413L24.2378 5.91598L20.9013 2.76322L24.9399 2.33734ZM4.14311 4.53016L5.21074 4.41757L8.54719 7.57045L2.42891 8.21559L2.28278 6.82919C2.22523 6.27774 2.38877 5.72599 2.73754 5.29498C3.0863 4.86398 3.59179 4.58892 4.14311 4.53016ZM27.6003 25.0145C27.5997 25.569 27.3792 26.1005 26.9871 26.4925C26.5951 26.8846 26.0635 27.1051 25.5091 27.1057H4.59703C4.0426 27.1051 3.51105 26.8846 3.11901 26.4925C2.72697 26.1005 2.50645 25.569 2.50582 25.0145V14.5585H27.6003V25.0145Z" fill={color} />
            <path d="M12.2559 25.2645C12.4647 25.3867 12.7022 25.4511 12.9442 25.4511C13.1861 25.4511 13.4237 25.3867 13.6325 25.2645L19.2441 22.0246C19.4534 21.9038 19.6272 21.73 19.748 21.5208C19.8688 21.3115 19.9324 21.0741 19.9324 20.8324C19.9324 20.5908 19.8688 20.3534 19.748 20.1441C19.6272 19.9349 19.4534 19.7611 19.2441 19.6403L13.6325 16.4004C13.4232 16.2795 13.1858 16.2159 12.9441 16.2158C12.7025 16.2158 12.465 16.2794 12.2557 16.4002C12.0464 16.521 11.8726 16.6948 11.7518 16.9041C11.631 17.1134 11.5674 17.3508 11.5674 17.5925V24.0723C11.566 24.3142 11.629 24.5522 11.75 24.7617C11.871 24.9713 12.0456 25.1448 12.2559 25.2645ZM12.9617 17.623L18.5207 20.8324L12.9617 24.0419V17.623Z" fill={color} />
        </svg>
    )
}
export default ShotIcon;

// width="29" height="29" viewBox="0 0 29 29"