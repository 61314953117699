import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { VerticalNavItemsType } from "src/@core/layouts/types";
import { getProjectDetails, getSceneDetails } from "src/config/api";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";

import { ScenesIcon } from "public/images/pages/icons/Icons";
import ShotIcon from "public/images/pages/icons/ShotIcon";

const ServerSideNavItems = () => {
  // ** State
  const router = useRouter();
  const [menuItems, setMenuItems] = useState<VerticalNavItemsType>([]);
  const { id, scene_id } = router.query;
  const { setProject, setProjectRoles, setLoadingScene } = useProjectContext();
  const {
    setLayersChanged,
    addNewShot,
    setScene,
    setSceneNumericId,
    addNewCameraSettings,
    setAddNewCameraSettings,
    setAddNewShot,
    addNewScene,
    setAddNewScene,
    setCurrentScriptComponentId,
    setCurrentShotId,
  } = useDesignEditorContext();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (addNewCameraSettings) {
  //       const projectsData = await getProjectDetails(parseInt(id as string), parseInt(scene_id as string));
  //       const currentScene = projectsData.project?.scenes.find(scene => scene.id === parseInt(router.query.scene_id));
  //       setScene(currentScene);
  //       const leftMenuItems = createLeftMenuitems(projectsData.project);
  //       setMenuItems(leftMenuItems);
  //       setAddNewCameraSettings(false);
  //     }
  //   };
  //   if (addNewCameraSettings)
  //     fetchData();
  // }, [addNewCameraSettings]);

  // useEffect(() => {
  //   if (addNewShot) {
  //     (async function () {
  //       const projectsData = await getProjectDetails(parseInt(id as string), parseInt(scene_id as string));
  //       if (projectsData) {
  //         setProject(projectsData.project);
  //         const currentScene = projectsData.project?.scenes.find(scene => scene.id === parseInt(router.query.scene_id));
  //         setCurrentScriptComponentId(currentScene.shots[currentScene.shots.length - 1].scriptComponentId);
  //         setCurrentShotId(currentScene.shots[currentScene.shots.length - 1].id);
  //         setScene(currentScene);
  //         setProjectRoles(projectsData.roles);
  //         setMenuItems(createLeftMenuitems(projectsData.project));
  //       }
  //       setAddNewShot(false);
  //     })();
  //   }
  // }, [addNewShot]);

  useEffect(() => {
    if (addNewScene) {
      (async function () {
        const projectsData = await getProjectDetails(id);
        if (projectsData) {
          setProject(projectsData.project);
          setProjectRoles(projectsData.roles);
          setMenuItems(createLeftMenuitems(projectsData.project));
          const currentURL = router.asPath;
          const url_parts = currentURL.split("/");
          const url_tab = url_parts.pop();
          router.push({
            pathname:
              "/projects/" +
              id +
              "/scene/" +
              projectsData.project.scenes[
                projectsData.project.scenes.length - 1
              ].id +
              "/" +
              url_tab,
          });
        }

        setAddNewScene(false);
      })();
    }
  }, [addNewScene]);

  const navigation = (): VerticalNavItemsType => {
    return [
      {
        sectionTitle: "All Projects",
        // sectionTitle: <Button size="small" variant='outlined' color='secondary'>All Projects</Button>
      },
    ];
  };

  const createShotsMenuItems = (project: any, scene: any) => {
    const shotsMenu = [];

    scene.shots.forEach((shot: any, index: number) => {
      if (shot.type == 2) {
        shotsMenu.push({
          title: shot.name,
          id: shot.id,
          isLast: index == scene.shots.length - 1,
          icon: <ShotIcon />,
          path:
            window.origin +
            "/projects/" +
            project.id +
            "/scene/" +
            scene.id +
            "/script/" +
            shot.scriptComponentId,
        });
      }
    });

    return shotsMenu;
  };

  const createSceneGroup = (project: any, scene: any, isLast: boolean) => {
    return {
      title: scene.name,
      id: scene.id,
      isLast: isLast,
      icon: <ScenesIcon />,
      // children: createShotsMenuItems(project, scene),
      children: [],
    };
  };

  const createLeftMenuitems = (project: any) => {
    const leftMenuItems = navigation();
    project?.scenes?.forEach((scene: any, index: number) => {
      leftMenuItems.push(
        createSceneGroup(project, scene, index == project.scenes.length - 1),
      );
    });
    return leftMenuItems;
  };

  // useEffect(() => {
  //   (async function () {
  //     if (id) {
  //       const getAllProjectsOfCurrentUser = await getLoggedInUser();
  //       const projectsData = await getProjectDetails(parseInt(id as string), parseInt(scene_id as string));
  //       if (getAllProjectsOfCurrentUser.projects) {
  //         setProject(projectsData.project);
  //         setProjectRoles(projectsData.roles);
  //         setMenuItems(createLeftMenuitems(projectsData.project));
  //         const currentScene = projectsData.project?.scenes.find(scene => scene.id === parseInt(scene_id));
  //         setScene(currentScene);
  //       }

  //       // if (getAllProjectsOfCurrentUser.projects) {
  //       //   setMenuItems(createLeftMenuitems(getAllProjectsOfCurrentUser.projects))
  //       // }

  //     }
  //   })();
  // }, [id])

  useEffect(() => {
    if (router.query.id) {
      (async function () {
        try {
          if (router.pathname.includes("script")) setLoadingScene(true);
          const projectsData = await getProjectDetails(router.query.id);
          if (projectsData.project) {
            setProject({ ...projectsData.project });
            setProjectRoles(projectsData.roles);
            setMenuItems(createLeftMenuitems(projectsData.project));
          }
        } catch {
          setLoadingScene(false);
        }
      })();
    }
  }, [router.query.id]);

  useEffect(() => {
    if (router.query.scene_id) {
      (async function () {
        try {
          if (router.pathname.includes("scripts")) setLoadingScene(true);
          const sceneData = await getSceneDetails(
            router.query.id as string,
            router.query.scene_id as string,
          );
          if (sceneData) {
            setScene(sceneData);
            setSceneNumericId(sceneData.numericId);
            setLayersChanged(true);
          }
        } catch {
          setLoadingScene(false);
        }
      })();
    }
  }, [router.query.scene_id]);

  return { menuItems };
};

export default ServerSideNavItems;
