import { useRouter } from "next/router";
import { ReactElement, ReactNode, useEffect, useState } from "react";

import { useAuth } from "src/config/auth";

interface AuthGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props;
  const auth = useAuth();
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const [authorized, setAuthorized] = useState(false);

  useEffect(
    () => {
      if (!router.isReady) {
        return;
      }
      authCheck(router.asPath);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.route],
  );

  async function authCheck(url: string) {
    // redirect to login page if accessing a private page and not logged in
    const publicPaths = ["/login", "/verify"];
    const path = url.split("?")[0];
    const loggedIn = await isAuthenticated();
    if (!loggedIn && !publicPaths.includes(path)) {
      setAuthorized(false);
      router.push({
        pathname: "/login",
        // query: { returnUrl: router.asPath },
      });
    } else if (publicPaths.includes(path) && loggedIn) {
      setAuthorized(true);
      router.push({
        pathname: "/",
      });
    } else {
      setAuthorized(true);
    }
  }

  if (authorized) {
    return <>{children}</>;
  }
  if (auth.loading && !authorized && !router.asPath.includes("/verify")) {
    return fallback;
  }

  return <>{children}</>;
};

export default AuthGuard;
