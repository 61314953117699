import { Auth } from "aws-amplify";
import axios from "axios";

/**
 * Axios instance that adds the Authorization header to internal API routes.
 */
const httpInterceptInstance = axios.create();

httpInterceptInstance.interceptors.request.use(
  async (config) => {
    // Add Authorization header to internal API requests.
    if (config.url?.startsWith("/api/") && !config.baseURL) {
      const currentUser = await Auth.currentAuthenticatedUser();
      config.headers.Authorization = `Bearer ${currentUser.signInUserSession.idToken.jwtToken}`;
    }

    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  },
);

export default httpInterceptInstance;
