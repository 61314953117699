import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  getLoggedInUser,
  inviteUserInProject,
  requestMagicLink,
  verifyAttendeeByMagicLink,
} from "src/config/api";
import { correctEncodedCookies, deleteAllCookies } from "src/libs/utils";

type AC = {
  loggedIn: boolean | null;
  userSession: any | null;
  loading: boolean | false;
  userData: any | null;
  getUserData: () => Promise<any>;
  clearUserData: () => Promise<any>;
  isAuthenticated: () => Promise<boolean>;
  signIn: (args: { email: string }) => Promise<any>;
  verifyAttendee: (args: { email: string }) => Promise<any>;
  userInvite: (args: {
    email: string;
    roleId: number;
    projectId: string;
    sceneId: string;
  }) => Promise<any>;
  answerCustomChallenge: (email: string, answer: string) => Promise<boolean>;
  signOut: typeof Auth.signOut;
};

const AuthContext = createContext<AC>({
  loggedIn: null,
  userSession: null,
  loading: false,
  userData: null,
  getUserData: () => Promise.resolve(false),
  clearUserData: () => Promise.resolve(false),
  isAuthenticated: () => Promise.resolve(false),
  signIn: () => Promise.resolve(null),
  verifyAttendee: () => Promise.resolve(null),
  userInvite: () => Promise.resolve(null),
  answerCustomChallenge: () => Promise.resolve(true),
  signOut: () => Promise.resolve(),
});

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = (props: AuthProviderProps) => {
  const [loggedIn, setLoggedIn] = useState<AC["loggedIn"]>(null);
  const [userSession, setUserSession] = useState<AC["userSession"]>(null);
  const [loading, setLoading] = useState<AC["loading"]>(true);
  const [userData, setUserData] = useState<any>(null);

  const router = useRouter();

  const isAuthenticated = useCallback(async () => {
    try {
      // setLoading(true)
      setUserSession(await Auth.currentSession());
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  useEffect(() => {
    isAuthenticated()
      .then((res) => {
        setLoading(false);
        return setLoggedIn(res);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [isAuthenticated]);

  useEffect(() => {
    isAuthenticated()
      .then((res) => {
        setLoading(false);
        if (!res) {
          if (router.pathname !== "/login" && router.pathname !== "/verify") {
            router.push("/login");
          }
        }
        return setLoggedIn(res);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const getUserData = useCallback(async () => {
    try {
      const user = await getLoggedInUser().then((response: any) => {
        setUserData(response);
        return response;
      });
      return user;
    } catch (error) {
      console.error("Failed to get user data", error);
    }
  }, []);

  // const response = await getUserData()
  // const veltUser = {
  //   userId: response.user.id,
  //   name: `${response.user.firstName} ${response.user.lastName}`,
  //   email: response.user.email,
  //   photoUrl: response.user.imdbProfileLink,
  //   groupId: "theoffice2",
  // };
  // useIdentify(veltUser);

  const clearUserData = useCallback(async () => {
    setUserData(null);
  }, []);

  const signIn = useCallback(async ({ email }: { email: string }) => {
    try {
      await Auth.signUp({
        username: email,
        password: `password${Math.random().toString().slice(0, 8)}`,
        attributes: { email },
      });
    } catch (e) {
      // skip if user already exists
    }
    return requestMagicLink(email);
  }, []);

  const verifyAttendee = useCallback(async ({ email }: { email: string }) => {
    try {
      await Auth.signUp({
        username: email,
        password: `password${Math.random().toString().slice(0, 8)}`,
        attributes: { email },
      });
    } catch (e) {
      // skip if user already exists
    }
    return verifyAttendeeByMagicLink(email);
  }, []);

  const userInvite = useCallback(
    async ({
      email,
      roleId,
      projectId,
      sceneId,
    }: {
      email: string;
      roleId: number;
      projectId: string;
      sceneId: string;
    }) => {
      try {
        await Auth.signUp({
          username: email,
          password: `password${Math.random().toString().slice(0, 8)}`,
          attributes: { email },
        });
      } catch (e) {
        // skip if user already exists
      }

      const session = await Auth.currentSession();

      return inviteUserInProject(
        session.idToken.payload.email,
        email,
        roleId,
        projectId,
        sceneId,
      );
    },
    [],
  );

  const answerCustomChallenge = async (email: string, answer: string) => {
    const cognitoUser = await Auth.signIn(email);
    const customAuthResp = await Auth.sendCustomChallengeAnswer(
      cognitoUser,
      answer,
    );
    setLoggedIn(true);
    correctEncodedCookies();
    return customAuthResp;
  };

  const signOut = useCallback(async () => {
    setLoggedIn(false);
    await Auth.signOut().finally(() => {
      deleteAllCookies();
      setUserData(null);
      router.push("/login");
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        userSession,
        loading,
        userData,
        getUserData,
        clearUserData,
        isAuthenticated,
        signIn,
        verifyAttendee,
        userInvite,
        answerCustomChallenge,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
