const ShareIcon = (props: any) => {
    const color = props.color ?? '#C9C9C9'

    return ( 
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9835 3.80376C17.9835 5.30593 16.7659 6.52369 15.2637 6.52369C13.7616 6.52369 12.5439 5.30593 12.5439 3.80376C12.5439 2.30175 13.7616 1.08398 15.2637 1.08398C16.7659 1.08398 17.9835 2.30175 17.9835 3.80376Z" fill={color}/>
        <path d="M15.2644 7.15204C13.4182 7.15204 11.917 5.65064 11.917 3.80446C11.917 1.95843 13.4182 0.457031 15.2644 0.457031C17.1106 0.457031 18.6118 1.95843 18.6118 3.80446C18.6118 5.65064 17.1106 7.15204 15.2644 7.15204ZM15.2644 1.71234C14.1104 1.71234 13.1723 2.65136 13.1723 3.80446C13.1723 4.95771 14.1104 5.89673 15.2644 5.89673C16.4184 5.89673 17.3565 4.95771 17.3565 3.80446C17.3565 2.65136 16.4184 1.71234 15.2644 1.71234Z" fill={color}/>
        <path d="M17.9835 17.1945C17.9835 18.6966 16.7659 19.9143 15.2637 19.9143C13.7616 19.9143 12.5439 18.6966 12.5439 17.1945C12.5439 15.6924 13.7616 14.4746 15.2637 14.4746C16.7659 14.4746 17.9835 15.6924 17.9835 17.1945Z" fill={color}/>
        <path d="M15.2644 20.5417C13.4182 20.5417 11.917 19.0403 11.917 17.1943C11.917 15.3481 13.4182 13.8467 15.2644 13.8467C17.1106 13.8467 18.6118 15.3481 18.6118 17.1943C18.6118 19.0403 17.1106 20.5417 15.2644 20.5417ZM15.2644 15.102C14.1104 15.102 13.1723 16.041 13.1723 17.1943C13.1723 18.3474 14.1104 19.2864 15.2644 19.2864C16.4184 19.2864 17.3565 18.3474 17.3565 17.1943C17.3565 16.041 16.4184 15.102 15.2644 15.102Z" fill={color}/>
        <path d="M6.26783 10.5C6.26783 12.0022 5.05007 13.2198 3.5479 13.2198C2.04589 13.2198 0.828125 12.0022 0.828125 10.5C0.828125 8.99788 2.04589 7.78027 3.5479 7.78027C5.05007 7.78027 6.26783 8.99788 6.26783 10.5Z" fill={color}/>
        <path d="M3.54762 13.8472C1.7016 13.8472 0.200195 12.346 0.200195 10.4998C0.200195 8.65359 1.7016 7.15234 3.54762 7.15234C5.3938 7.15234 6.8952 8.65359 6.8952 10.4998C6.8952 12.346 5.3938 13.8472 3.54762 13.8472ZM3.54762 8.40765C2.39361 8.40765 1.4555 9.34652 1.4555 10.4998C1.4555 11.653 2.39361 12.5919 3.54762 12.5919C4.70179 12.5919 5.6399 11.653 5.6399 10.4998C5.6399 9.34652 4.70179 8.40765 3.54762 8.40765Z" fill={color}/>
        <path d="M5.52325 10.0979C5.23195 10.0979 4.94908 9.94639 4.79508 9.67531C4.5666 9.27445 4.70727 8.7631 5.10814 8.53371L12.8733 4.10674C13.2742 3.87658 13.7856 4.01725 14.0149 4.41965C14.2434 4.82051 14.1027 5.33186 13.7019 5.56125L5.93653 9.98822C5.80597 10.0627 5.66377 10.0979 5.52325 10.0979Z" fill={color}/>
        <path d="M13.2888 17.0011C13.1481 17.0011 13.0059 16.9658 12.8753 16.8914L5.10997 12.4644C4.70911 12.2359 4.56859 11.7246 4.79707 11.3228C5.02462 10.9212 5.53673 10.7797 5.93852 11.0099L13.7039 15.4369C14.1047 15.6653 14.2453 16.1767 14.0168 16.5785C13.862 16.8495 13.5791 17.0011 13.2888 17.0011Z" fill={color}/>
        </svg>
        
    )
}
export default ShareIcon;