const Previous = (props: any) => {
    const color = props.color ?? '#999999'
    const width = props.width || 16;
    return (
        <svg width={width} height={width} viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8984 8.43157L19.8206 15.3924C20.016 15.5472 20.2687 15.5555 20.4704 15.4211C20.6758 15.2851 20.8027 15.0253 20.8027 14.742V0.82046C20.8027 0.537243 20.6758 0.277374 20.4704 0.141421C20.3758 0.078702 20.27 0.0470152 20.1654 0.0470152C20.0459 0.0470152 19.9252 0.0878372 19.8206 0.170135L10.8984 7.13092C10.7166 7.27289 10.6058 7.5184 10.6058 7.78125C10.6058 8.04409 10.7166 8.28953 10.8984 8.43157ZM19.5281 2.23741V13.3251L12.4219 7.78125L19.5281 2.23741Z" fill={color} />
            <path d="M0.701591 8.43157L9.62383 15.3924C9.81926 15.5472 10.0719 15.5555 10.2736 15.4211C10.479 15.2851 10.606 15.0253 10.606 14.742V0.82046C10.606 0.537243 10.479 0.277374 10.2736 0.141421C10.179 0.078702 10.0732 0.0470152 9.96863 0.0470152C9.84914 0.0470152 9.72839 0.0878372 9.62383 0.170135L0.701591 7.13092C0.519839 7.27289 0.409068 7.5184 0.409068 7.78125C0.409068 8.04409 0.519839 8.28953 0.701591 8.43157ZM9.33137 2.23741V13.3251L2.22515 7.78125L9.33137 2.23741Z" fill={color} />
        </svg>
    )
}
export default Previous