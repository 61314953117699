import { CircularProgress, IconButton, styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

import { useTimer } from "@layerhub-io/use-timer";

import {
  IStaticImage,
  IStaticVideo,
} from "src/@core/context/DesignEditorContext/layers";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import { convertMS } from "src/libs/utils";

import {
  Next,
  PauseButton,
  PlayButton,
  Previous,
} from "public/images/pages/icons/Icons";

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "0.5rem",
}));

const BoxContainer = styled("div")<BoxProps>(({ theme }) => ({
  display: "flex",
  height: "100%",
  padding: "0 0.2rem",
}));

const VideoTimelineControl = ({ mode }) => {
  const { start, pause, status, reset, time, setTime } = useTimer();
  const {
    scene,
    setDisplayPlayback,
    setPlaybackVisibility,
    videoEditorMode,
    setVideoEditorMode,
    setForceReloadFrames,
    setPlaybackSeeked,
    displayPlayback,
    setPlaybackWaiting,
    playbackWaiting,
    selectedVideoType,
  } = useDesignEditorContext();
  const {
    canvas,
    enableCanvasLoader,
    reAlignObjectsBasedOnSize,
    drawText,
    drawImage,
    drawVideo,
  } = useCanvasContext();
  const theme = useTheme();
  const timeDelay = 2000;

  const alignLayersInCanvas = (currentPoint: number) => {
    canvas.remove(...canvas.getObjects());
    scene?.staticTextLayers.forEach((textLayer) => {
      if (
        Number(textLayer.display.to) > currentPoint &&
        Number(textLayer.display.from) <= currentPoint
      ) {
        drawText(textLayer as IStaticImage);
      }
    });

    scene?.staticImageLayers.forEach((imageLayer) => {
      if (
        Number(imageLayer.display.to) > currentPoint &&
        Number(imageLayer.display.from) <= currentPoint
      ) {
        drawImage(imageLayer as IStaticImage);
      }
    });

    (scene?.videoLayers || [])
      .filter((l) => l[selectedVideoType])
      .forEach((videoLayer) => {
        const selectedCameraView = videoLayer[selectedVideoType]
          ? selectedVideoType
          : "videoWideAngle";
        if (
          Number(videoLayer[selectedCameraView].display.to) > currentPoint &&
          Number(videoLayer[selectedCameraView].display.from) <= currentPoint
        ) {
          drawVideo(videoLayer[selectedCameraView] as IStaticVideo);
        }
      });

    setTimeout(() => {
      reAlignObjectsBasedOnSize(true);
    }, 100);
  };

  return (
    <BoxContainer>
      <MainContainer sx={{ alignItems: "center" }}>
        <BoxContainer>
          <Box display="flex" sx={mode == 'SHOT-DESIGN' ? { flexDirection: 'row', alignItems: 'center', columnGap: 12 } : { flexDirection: 'column' }}>
            <Box display="flex" columnGap={mode == 'SHOT-DESIGN' ? 12 : 0}>
              <Box sx={{ padding: "0.1rem" }}>
                <IconButton
                  onClick={() => {
                    if (time > 1) {
                      if (displayPlayback) {
                        pause();
                        setTimeout(() => {
                          start();
                        }, 400);
                      }

                      const currentTime =
                        time - timeDelay < 0 ? 0 : time - timeDelay;
                      setTime(currentTime);
                      setPlaybackSeeked(true);
                      alignLayersInCanvas(currentTime / 1000);
                    }
                  }}
                  disabled={time === 0}
                >
                  <Previous
                    width={mode == 'SHOT-DESIGN' ? 25 : 16}
                    color={
                      time < 100
                        ? "#999999"
                        : theme.palette.mode === "dark"
                          ? "white"
                          : "black"
                    }
                  />
                </IconButton>
              </Box>
              {status === "RUNNING" && (
                <Box sx={{ padding: "0.1rem" }}>
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => {
                      pause();
                      setDisplayPlayback(false);
                      setTimeout(() => {
                        alignLayersInCanvas(time / 1000);
                      }, 1000);
                    }}
                    disabled={status === "PAUSED"}
                  >
                    <PauseButton
                      width={mode == 'SHOT-DESIGN' ? 35 : 20}
                      color={
                        status === "PAUSED"
                          ? ""
                          : theme.palette.mode === "dark"
                            ? "white"
                            : "black"
                      }
                    />
                  </IconButton>
                </Box>
              )}
              {(status === "PAUSED" || status === "STOPPED") && (
                <Box
                  sx={{
                    padding: "0.1rem",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{ padding: 0 }}
                    disabled={status === "RUNNING"}
                    onClick={() => {
                      if (videoEditorMode == "EDIT") {
                        setForceReloadFrames(true);
                        // setVideoEditorMode("VIEW");
                        setPlaybackWaiting(true);
                        // setTimeout(() => {
                        //     enableCanvasLoader(true)
                        //     setPlaybackVisibility(true);
                        //     setDisplayPlayback(true);
                        // }, 3000)
                      } else {
                        enableCanvasLoader(true);
                        setPlaybackVisibility(true);
                        setDisplayPlayback(true);
                      }
                    }}
                  >
                    <PlayButton
                      width={mode == 'SHOT-DESIGN' ? 35 : 20}
                      color={
                        status === "RUNNING"
                          ? ""
                          : theme.palette.mode === "dark"
                            ? "white"
                            : "black"
                      }
                    />
                  </IconButton>
                  {playbackWaiting && (
                    <CircularProgress
                      sx={{
                        position: "absolute",
                        color:
                          theme.palette.mode === "dark" ? "white" : "black",
                        height: "22px !important",
                        width: "22px !important",
                      }}
                    />
                  )}
                </Box>
              )}
              <Box sx={{ padding: "0.1rem" }}>
                <IconButton
                  onClick={() => {
                    if (displayPlayback) {
                      pause();
                      setTimeout(() => {
                        start();
                      }, 400);
                    }

                    const currentTime = time + timeDelay;
                    setTime(currentTime);
                    setPlaybackSeeked(true);
                    alignLayersInCanvas(currentTime / 1000);
                  }}
                >
                  <Next
                    color={theme.palette.mode === "dark" ? "white" : "black"}
                    width={mode == 'SHOT-DESIGN' ? 25 : 16}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ padding: "0.1rem", textAlign: "center", fontSize: 14 }}>
              {convertMS(time)}
            </Box>
          </Box>
        </BoxContainer>
      </MainContainer>
    </BoxContainer>
  );
};

export default VideoTimelineControl;
