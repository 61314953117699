import { useContext } from "react";

import { CanvasContext } from "src/@core/context/CanvasContext/CanvasContext";

const useCanvasContext = () => {
  const {
    canvas,
    clearCanvas,
    initCanvas,
    activeObject,
    setActiveObject,
    drawVideo,
    drawText,
    drawImage,
    canvasWidth,
    setCanvasWidth,
    canvasHeight,
    setCanvasHeight,
    canvasLoader,
    enableCanvasLoader,
    reAlignObjectsBasedOnSize,
    oldFrame,
    setOldFrame,
    stopScaling,
  } = useContext(CanvasContext);
  return {
    canvas,
    clearCanvas,
    initCanvas,
    activeObject,
    setActiveObject,
    drawVideo,
    drawText,
    drawImage,
    canvasWidth,
    setCanvasWidth,
    canvasHeight,
    setCanvasHeight,
    canvasLoader,
    enableCanvasLoader,
    reAlignObjectsBasedOnSize,
    oldFrame,
    setOldFrame,
    stopScaling,
  };
};

export default useCanvasContext;
