import { Button } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { useRouter } from "next/router";
import {
  ElementType,
  useEffect,
  useState,
  ChangeEvent,
  KeyboardEvent,
} from "react";

import { Settings } from "src/@core/context/settingsContext";
import { NavGroup, NavLink } from "src/@core/layouts/types";
import { updateShotName } from "src/config/api";
import themeConfig from "src/configs/themeConfig";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import Translations from "src/layouts/components/Translations";
import CanViewNavLink from "src/layouts/components/acl/CanViewNavLink";

interface Props {
  parent?: boolean;
  item: NavLink;
  navHover?: boolean;
  settings: Settings;
  navVisible?: boolean;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  isSubToSub?: NavGroup | undefined;
}

// ** Styled Components
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & {
    component?: ElementType;
    target?: "_blank" | undefined;
  }
>(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  textTransform: "capitalize",
  transition: "padding-left .25s ease-in-out",
  "&.active": {
    "&, &:hover": {
      backgroundColor: theme.palette.primary.light,
      "&.Mui-focusVisible": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "& .MuiTypography-root": {
      fontWeight: 500,
      color: `${theme.palette.common.white} !important`,
    },
    "& .MuiListItemIcon-root": {
      color: `${theme.palette.common.white} !important`,
    },
  },
}));

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" }),
}));

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth,
}: Props) => {
  // ** Hooks
  const router = useRouter();
  const {
    scene,
    currentScriptComponentId,
    setCurrentScriptComponentId,
    currentShotId,
    setCurrentShotId,
  } = useDesignEditorContext();
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(item.title);

  useEffect(() => {
    setNewTitle(item.title);
  }, [parent]);

  // ** Vars
  const { navCollapsed } = settings;

  const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon;

  const isNavLinkActive = () => {
    // Regular expression to match a GUID pattern
    const guidRegex = /[0-9a-f]{32}$/i;

    // Extract the last GUID from the URL
    const matches = item?.path.match(guidRegex);
    const lastGuid = matches ? matches[0] : null;
    if (lastGuid == currentScriptComponentId && item.id == currentShotId) {
      return true;
    } else {
      return false;
    }
  };
  const navigateToPath = () => {
    setCurrentShotId(item?.id);

    // Regular expression to match a GUID pattern
    const guidRegex = /[0-9a-f]{32}$/i;

    // Extract the last GUID from the URL
    const matches = item?.path.match(guidRegex);
    const lastGuid = matches ? matches[0] : null;
    if (lastGuid) {
      setCurrentScriptComponentId(lastGuid);
    } else {
      const urlObj = new URL(item.path);
      const newPathname = urlObj?.pathname;
      router.push({
        pathname:
          newPathname === undefined
            ? item.path === undefined
              ? "/"
              : `${item.path}`
            : `${newPathname}`,
      });
    }
  };

  const handleTitleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {};

  const handleTitleBlur = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsEditing(false);
  };

  const handleTitleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setIsEditing(false);
      const updateNameResp = await updateShotName(item.id, e.target.value);
      setNewTitle(e.target.value);
    } else if (e.key === "Escape") {
      setIsEditing(false);
    }
  };

  return (
    <>
      <CanViewNavLink navLink={item}>
        <ListItem
          disablePadding
          className="nav-link"
          disabled={item.disabled || false}
          sx={{
            mt: 1.5,
            transition: "padding .25s ease-in-out",
            px: (theme) =>
              parent
                ? "0 !important"
                : `${theme.spacing(
                    navCollapsed && !navHover ? 2 : 3,
                  )} !important`,
          }}
        >
          <MenuNavLink
            component={Button}
            {...(item.disabled && { tabIndex: -1 })}
            className={isNavLinkActive() ? "active" : ""}
            // href={item.path === undefined ? '/' : `${item.path}`}
            {...(item.openInNewTab ? { target: "_blank" } : null)}
            onClick={(e) => {
              if (item.path === undefined) {
                e.preventDefault();
                e.stopPropagation();
              }
              if (navVisible) {
                toggleNavVisibility();
              }
              navigateToPath();
            }}
            sx={{
              py: 2.25,
              ...(item.disabled
                ? { pointerEvents: "none" }
                : { cursor: "pointer" }),
              pr:
                navCollapsed && !navHover
                  ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8
                  : 3,
              pl:
                navCollapsed && !navHover
                  ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8
                  : 4,
            }}
          >
            {/* {isSubToSub ? null : ( */}
            <ListItemIcon
              sx={{
                transition: "margin .25s ease-in-out",
                color: parent ? "text.secondary" : "text.primary",
                ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2 }),
                ...(parent ? { ml: 2, mr: 4 } : {}), // This line should be after (navCollapsed && !navHover) condition for proper styling
                "& svg": {
                  ...(!parent
                    ? { fontSize: "1.5rem" }
                    : { fontSize: "0.5rem" }),
                  ...(parent && item.icon ? { fontSize: "0.875rem" } : {}),
                },
              }}
            >
              {/* <UserIcon icon={icon as string} /> */}
              {icon}
            </ListItemIcon>
            {/* )} */}

            <MenuItemTextMetaWrapper
              sx={{
                ...(isSubToSub ? { ml: 0 } : {}),
                ...(navCollapsed && !navHover
                  ? { opacity: 0 }
                  : { opacity: 1 }),
              }}
            >
              {isEditing ? (
                <input
                  style={{
                    border: "none",
                    background: "#33acee",
                    color: "#fff",
                    padding: "4px",
                    fontWeight: "500",
                    fontSize: "17px",
                    outline: "none",
                  }}
                  type="text"
                  defaultValue={newTitle}
                  onChange={handleTitleChange}
                  onBlur={handleTitleBlur}
                  onKeyDown={handleTitleKeyDown}
                />
              ) : (
                <Typography
                  onDoubleClick={handleTitleDoubleClick}
                  {...((themeConfig.menuTextTruncate ||
                    (!themeConfig.menuTextTruncate &&
                      navCollapsed &&
                      !navHover)) && {
                    noWrap: true,
                  })}
                >
                  <Translations text={newTitle ? newTitle : item.title} />
                </Typography>
              )}
              {item.badgeContent ? (
                <Chip
                  size="small"
                  label={item.badgeContent}
                  color={item.badgeColor || "primary"}
                  sx={{
                    "& .MuiChip-label": {
                      px: 2.5,
                      lineHeight: 1.385,
                      textTransform: "capitalize",
                    },
                  }}
                />
              ) : null}
            </MenuItemTextMetaWrapper>
          </MenuNavLink>
        </ListItem>
      </CanViewNavLink>
    </>
  );
};

export default VerticalNavLink;
