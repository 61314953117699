const LeftRightArrow = (props: any) => {
    const color = props.color ?? '#A7A7A7'

    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_3360)">
                <path d="M14.3894 6.4583H1.01081C0.431812 6.4583 0.171808 6.29371 0.064586 5.86026C0.0349495 5.75986 0.0261683 5.65421 0.0388178 5.55013C0.0514674 5.44604 0.0852627 5.34581 0.138029 5.2559C0.190796 5.16598 0.261369 5.08836 0.345205 5.02803C0.429041 4.9677 0.52428 4.92599 0.62481 4.90559C0.789767 4.87182 0.957905 4.8571 1.12607 4.8617H14.3519C14.2447 4.73551 14.1857 4.65321 14.116 4.58737C13.3922 3.84667 12.6631 3.10598 11.9421 2.36529C11.5614 1.973 11.5266 1.48744 11.8456 1.1555C12.1646 0.823558 12.6497 0.853734 13.025 1.24054C14.2634 2.49514 15.4991 3.76255 16.7322 5.04276C17.1396 5.4634 17.1396 5.8813 16.7322 6.29645C15.5027 7.56568 14.2679 8.83126 13.0277 10.0932C12.6336 10.4964 12.1967 10.5211 11.8563 10.1755C11.5159 9.82982 11.5453 9.37992 11.9421 8.97391C12.7301 8.14817 13.5236 7.34439 14.3894 6.4583Z" fill={color} />
                <path d="M2.67556 16.3747H16.0783C16.6573 16.3747 16.9146 16.5393 17.0218 16.9727C17.0515 17.0731 17.0602 17.1788 17.0476 17.2829C17.0349 17.387 17.0011 17.4872 16.9484 17.5771C16.8956 17.667 16.825 17.7446 16.7412 17.805C16.6574 17.8653 16.5621 17.907 16.4616 17.9274C16.2966 17.9613 16.1285 17.976 15.9603 17.9713H2.72649C2.83103 18.0975 2.89 18.1798 2.9597 18.2456C3.68344 18.9863 4.41254 19.7243 5.13361 20.4677C5.51425 20.86 5.55179 21.3456 5.23012 21.6775C4.90846 22.0094 4.42595 21.9793 4.05068 21.5897C2.81227 20.3278 1.57743 19.0631 0.346166 17.7957C-0.0639565 17.376 -0.0639565 16.9727 0.346166 16.5393C1.57564 15.2719 2.81048 14.0072 4.05068 12.7453C4.44472 12.342 4.88432 12.3173 5.22207 12.663C5.55982 13.0087 5.53301 13.4586 5.13629 13.8646C4.34553 14.6821 3.5521 15.4886 2.67556 16.3747Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_8_3360">
                    <rect width="17" height="21" fill="white" transform="translate(0.0380859 0.916016)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default LeftRightArrow