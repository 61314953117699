import { Box, Divider } from "@mui/material";

import React, { createRef, useEffect, useRef, useState } from "react";

import { IStaticText } from "src/@core/context/DesignEditorContext/layers";
import { IScene } from "src/@core/context/DesignEditorContext/scene";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import { closest } from "src/utils/closest";

import Clip from "./Clip";
import KeyFrameMarker from "./KeyFrameMarker";
import KeyframeClip from "./KeyframeClip";

const CameraTimeline = (props: any) => {
  const { scene, setScene, updateScale, scaleSize, scalePoint, fitTimeline } =
    useDesignEditorContext();
  const [distanceBetweenTwoScalePoints, setDistanceBetweenTwoScalePoints] =
    useState(scaleSize || 0);
  const [pixelPoints, setPixelPoints] = useState(scalePoint?.pixelPoints || {});
  const [canvasWidth, setCanvasWidth] = useState(500);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneClipRef = useRef(null);

  useEffect(() => {
    setCanvasWidth(props.width);
  }, [fitTimeline]);

  useEffect(() => {
    if (scene?.parseSceneResponse) {
      setPixelPoints(scalePoint?.pixelPoints);
      setDistanceBetweenTwoScalePoints(
        scalePoint?.distanceBetweenTwoScalePoints || 0,
      );

      const duration = scene?.parseSceneResponse?.expected_duration_frames / 24;
      const formattedDuration = `${duration?.toFixed(1)}0`;

      scene!.display = {
        from: 0,
        to: formattedDuration,
      };
      scene!.type = "Scene";

      scene.pixelWidth = scalePoint?.pixelPoints
        ? scalePoint?.pixelPoints[Number(scene.display.to).toFixed(2)] -
        scalePoint?.pixelPoints[Number(scene.display.from).toFixed(2)]
        : 0;

      if (scene?.parseSceneResponse?.original_duration_frames) {
        const initialDuration =
          scene?.parseSceneResponse?.original_duration_frames / 24;
        const formattedInitialDuration = `${initialDuration?.toFixed(1)}0`;

        scene.initialMinWidth = scalePoint?.pixelPoints
          ? scalePoint?.pixelPoints[
          Number(formattedInitialDuration).toFixed(2)
          ] - scalePoint?.pixelPoints[Number(scene?.display?.from).toFixed(2)]
          : 0;
      }
    }
  }, [scene, scalePoint, scalePoint?.pixelPoints, scaleSize]);

  // Array storing element references of text layer Rnd components.
  let rndRefs: Array<any> = [];
  rndRefs =
    scene?.cameraShotCurves
      .filter((csc) => !csc.isDeleted)
      .map((_, i) => rndRefs[i] ?? createRef()) || [];

  const onResizeVideoTimelineStop = (
    dir: string,
    d: { height: number; width: number },
    refToElement: any,
    position: { x: any; y: number },
    layer: IScene,
    i: number,
    setWidth: any,
  ) => {
    if (layer.display) {
      switch (dir) {
        case "left":
          layer.startingPointPixel =
            pixelPoints[Number(layer.display.from).toFixed(2)];
          const displayFrom = closest(
            pixelPoints,
            Math.abs(d.width - layer.startingPointPixel),
          );
          layer.display.from = displayFrom;
          layer.startingPointPixel = pixelPoints[layer.display.from];
          // }
          break;
        case "right":
          layer.endingPointPixel =
            pixelPoints[Number(layer.display.to).toFixed(2)];
          const displayTo = closest(
            pixelPoints,
            Math.abs(d.width + layer.endingPointPixel),
          );
          if (layer.display.to > displayTo) {
            const updatedPixelWidth = pixelPoints[Number(displayTo).toFixed(2)];

            const updatedCameraShotCurves = layer.cameraShotCurves?.map(
              (item) => {
                if (item?.pixelWidth > updatedPixelWidth && item?.isActive) {
                  return {
                    ...item,
                    pixelWidth: updatedPixelWidth,
                    endingPointPixel: updatedPixelWidth,
                    display: {
                      from: item?.display?.from,
                      to: displayTo,
                    },
                  };
                }

                return { ...item };
              },
            );

            layer.cameraShotCurves = updatedCameraShotCurves;
          }

          layer.display.to = displayTo;
          layer.endingPointPixel = pixelPoints[layer.display.to];
          break;
      }
    }

    if (Number(layer.display.from) < 0 && Number(layer.display.to) > 0) {
      layer.display.from = "0.00";
      layer.startingPointPixel = pixelPoints["0.00"];
      // rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    }

    if (Number(layer.display.from) + 1 / 10 >= Number(layer.display.to)) {
      layer.startingPointPixel =
        pixelPoints[Number(layer.display.from).toFixed(2)];
      layer.display.to = (Number(layer.display.from) + 3 / 10).toFixed(2);
      layer.endingPointPixel = pixelPoints[Number(layer.display.to).toFixed(2)];
    }

    layer.parseSceneResponse = {
      ...layer.parseSceneResponse,
      expected_duration_frames: Math.round(parseFloat(layer.display.to) * 24),
    };
    layer.startingPointPixel =
      pixelPoints[Number(layer.display.from).toFixed(2)];
    // rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    layer.pixelWidth =
      pixelPoints[Number(layer.display.to).toFixed(2)] -
      pixelPoints[Number(layer.display.from).toFixed(2)];

    if (scene.duration < Number(layer.display.to)) {
      scene.duration = Number(layer.display.to);
      updateScale(true);
    }

    setWidth(layer.pixelWidth);
    if (scene) setScene(scene);
  };

  const onResizeStop = (
    dir: string,
    d: { height: number; width: number },
    refToElement: any,
    position: { x: any; y: number },
    layer: Partial<IStaticText>,
    i: number,
    setWidth: any,
  ) => {
    if (layer.display) {
      switch (dir) {
        case "left":
          layer.startingPointPixel =
            pixelPoints[Number(layer.display.from).toFixed(2)];
          // if((layer.startingPointPixel - Math.abs(position.x) )< pixelPoints['0.00']){
          //   layer.display.from = '0.00'
          //   layer.startingPointPixel = pixelPoints['0.00']
          // }
          // else{
          const displayFrom = closest(
            pixelPoints,
            Math.abs(d.width - layer.startingPointPixel),
          );
          layer.display.from = displayFrom;
          layer.startingPointPixel = pixelPoints[layer.display.from];
          // }
          break;
        case "right":
          layer.endingPointPixel =
            pixelPoints[Number(layer.display.to).toFixed(2)];
          const displayTo = closest(
            pixelPoints,
            Math.abs(d.width + layer.endingPointPixel),
          );
          layer.display.to = displayTo;
          layer.endingPointPixel = pixelPoints[layer.display.to];
          break;
      }
    }

    if (Number(layer.display.from) < 0 && Number(layer.display.to) > 0) {
      layer.display.from = "0.00";
      layer.startingPointPixel = pixelPoints["0.00"];
      rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    }

    if (Number(layer.display.from) + 1 / 10 >= Number(layer.display.to)) {
      layer.startingPointPixel =
        pixelPoints[Number(layer.display.from).toFixed(2)];
      layer.display.to = (Number(layer.display.from) + 3 / 10).toFixed(2);
      layer.endingPointPixel = pixelPoints[Number(layer.display.to).toFixed(2)];
    }

    layer.startingPointPixel =
      pixelPoints[Number(layer.display.from).toFixed(2)];
    rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    layer.pixelWidth =
      pixelPoints[Number(layer.display.to).toFixed(2)] -
      pixelPoints[Number(layer.display.from).toFixed(2)];
    if (scene.duration < Number(layer.display.to)) {
      scene.duration = Number(layer.display.to);
      updateScale(true);
    }
    setWidth(layer.pixelWidth);
    if (scene) setScene(scene);
  };

  const onDragStop = (
    event: React.DragEvent<HTMLDivElement>,
    d: { x: number; y: number; lastX: number; lastY: number; width: number },
    layer: Partial<IStaticText>,
    i: number,
  ) => {
    if (!d.lastX) {
      return;
    }
    const target = event.target as HTMLDivElement;
    const targetRect = target.getBoundingClientRect();
    const canvasRect = canvasRef.current?.getBoundingClientRect();
    if (d.x < 20) {
      d.x = 20;
    }
    if (targetRect.right > canvasRect!.right) {
      setCanvasWidth(canvasWidth + targetRect.right - canvasRect!.right);
    }
    rndRefs[i].current.updatePosition({ y: 0 });

    const diff = scaleSize;
    layer.display.from = Number(
      (Math.round(d.lastX / diff) / 10).toFixed(2) - 2 / Number(10),
    ).toFixed(2);
    if (d.lastX < d.x) {
      layer.display.to = Number(
        (
          Math.round(
            (d.lastX +
              rndRefs[i].current.resizableElement.current.offsetWidth) /
            diff,
          ) /
          10 -
          1 / Number(10)
        ).toFixed(2),
      ).toFixed(2);
    } else {
      layer.display.to = Number(
        (
          Math.round(
            (d.lastX +
              rndRefs[i].current.resizableElement.current.offsetWidth) /
            diff,
          ) /
          10 -
          2 / Number(10)
        ).toFixed(2),
      ).toFixed(2);
    }

    if (Number(layer.display.from) < 0 && Number(layer.display.to) < 0) {
      layer.display.to = (
        Number(
          closest(
            pixelPoints,
            pixelPoints[Number(Math.abs(layer.display.from)).toFixed(2)] -
            pixelPoints[Number(Math.abs(layer.display.to)).toFixed(2)],
          ),
        ) +
        1 / 10
      ).toFixed(2);
      layer.endingPointPixel = pixelPoints[layer.display.to];
      layer.display.from = "0.00";
      layer.startingPointPixel = pixelPoints["0.00"];
      rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    }

    if (Number(layer.display.from) < 0 && Number(layer.display.to) > 0) {
      layer.display.to = (
        Number(
          closest(
            pixelPoints,
            pixelPoints[Number(Math.abs(layer.display.from)).toFixed(2)] +
            pixelPoints[Number(Math.abs(layer.display.to)).toFixed(2)],
          ),
        ) -
        1 / 10
      ).toFixed(2);
      layer.endingPointPixel = pixelPoints[layer.display.to];
      layer.display.from = "0.00";
      layer.startingPointPixel = pixelPoints["0.00"];
      rndRefs[i].current.updatePosition({ x: layer.startingPointPixel });
    }

    if (scene.duration < Number(layer.display.to)) {
      scene.duration = Number(layer.display.to);
      updateScale(true);
    }
    if (scene) setScene(scene);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "block",
          minHeight: "40px",
          margin: "0px",
        }}
      >
        {scene &&
          scene.display &&
          scalePoint &&
          pixelPoints &&
          Object.keys(pixelPoints).length > 0 && (
            <>
              {scene.cameraShotCurves.map((layer: any, i: number) => (
                <>
                  {layer.isActive && !layer.isDeleted && (
                    <Box
                      sx={{
                        position: "relative",
                        display: "block",
                        height: "40px !important",
                        margin: "0px",
                      }}
                      key={i}
                    >
                      {/* <KeyFrameMarker
                        layer={layer}
                        editor={props?.editor}
                        setSelectedLayerId={props?.setSelectedLayerId}
                        setMinimizedElements={props?.setMinimizedElements}
                      /> */}
                      {/* <canvas
                        ref={canvasRef}
                        width={canvasWidth}
                        height="50"
                        style={{ left: 0, top: 0 }}
                      ></canvas> */}

                      {
                        layer.canvasKeyframes.map((keyFrame: any, index: number) => (
                          <KeyframeClip seconds={keyFrame.seconds} pixelPoints={pixelPoints} nextKeyFrame={layer.canvasKeyframes[index + 1]} id={keyFrame.id} isActive={keyFrame.isActive} layerId={layer.canvasKeyframes[0].id} />
                        ))
                      }
                    </Box>
                  )}
                </>
              ))}
              {/* <Box
                sx={{
                  position: "relative",
                  display: "block",
                  height: "40px !important",
                  margin: "0px",
                }}
                key={props.cameraShotCurves.length + 1}
              >
                <canvas
                  ref={canvasRef}
                  width={canvasWidth}
                  height="50"
                  style={{ left: 0, top: 0 }}
                ></canvas>
                <Clip
                  rndRef={sceneClipRef}
                  layer={scene}
                  pixelPoints={pixelPoints}
                  width={scene.pixelWidth}
                  distanceBetweenTwoScalePoints={distanceBetweenTwoScalePoints}
                  index={props.cameraShotCurves.length + 1}
                  onResizeStop={onResizeVideoTimelineStop}
                  deleteClip={props.deleteClip}
                  key={props.cameraShotCurves.length + 1}
                  minWidth={scene?.initialMinWidth}
                  setMinimizedElements={props?.setMinimizedElements}
                />
              </Box> */}
            </>
          )}
      </Box>
      <Divider
        key={"video"}
        sx={{
          "& .MuiDivider-wrapper": { px: 4 },
          mt: (theme) => `${theme.spacing(2)} !important`,
          mb: (theme) => `${theme.spacing(3)} !important`,
        }}
      ></Divider>
    </>
  );
};
export default CameraTimeline;
