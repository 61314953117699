const AudioIcon = (props: any) => {
    const color = props.color ?? '#A7A7A7'
    return (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5516 0.242188C12.3049 0.124489 12.0142 0.155687 11.8014 0.327271L4.90404 5.84491H1.60848C0.827136 5.84491 0.19043 6.48162 0.19043 7.26297V12.9352C0.19043 13.7165 0.827136 14.3532 1.60848 14.3532H4.90404L11.8 19.8709C11.9291 19.973 12.0865 20.0254 12.2439 20.0254C12.3488 20.0254 12.4538 20.0028 12.5516 19.956C12.7969 19.8368 12.9529 19.5887 12.9529 19.3164V0.88173C12.9529 0.609463 12.7969 0.361304 12.5516 0.242188ZM4.44459 12.9352H1.60848V7.26297H4.44459V12.9352ZM11.5349 17.8402L5.86264 13.3025V6.89569L11.5349 2.35792V17.8402Z" fill={color}/>
            <path d="M16.5432 5.08499C16.2638 4.80847 15.8157 4.81272 15.5406 5.09066C15.2655 5.37001 15.2683 5.81812 15.5463 6.09322C16.6169 7.15251 17.2068 8.57481 17.2068 10.0992C17.2068 11.6236 16.6169 13.0459 15.5463 14.1052C15.2669 14.3803 15.2641 14.8284 15.5406 15.1078C15.6796 15.2482 15.8625 15.3177 16.044 15.3177C16.2241 15.3177 16.4042 15.2496 16.5432 15.112C17.886 13.7862 18.6249 12.0065 18.6249 10.0992C18.6249 8.19194 17.886 6.41228 16.5432 5.08499Z" fill={color}/>
            <path d="M18.5428 3.0871C18.2649 2.812 17.8153 2.81342 17.5402 3.09136C17.2651 3.3693 17.2665 3.81882 17.5445 4.09392C19.1554 5.69207 20.0431 7.82482 20.0431 10.0994C20.0431 12.3739 19.1554 14.5067 17.5445 16.1048C17.2665 16.3799 17.2651 16.8295 17.5402 17.1074C17.6792 17.2478 17.8607 17.3173 18.0436 17.3173C18.2237 17.3173 18.4038 17.2492 18.5428 17.1116C20.4246 15.2455 21.4611 12.7554 21.4611 10.0994C21.4611 7.44336 20.4246 4.95326 18.5428 3.0871Z" fill={color}/>
        </svg>
    )
}
export default AudioIcon