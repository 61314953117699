const RectMaximize = (props: any) => {
    const color = props.color ?? '#999999'
    return (
        <svg width="38" height="37" viewBox="0 0 38 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5998" y="1.40547" width="34.7518" height="34.7518" rx="10.7" stroke={color} stroke-width="1.4" />
            <g clipPath="url(#clip0_4_1994)">
                <path fillRule="evenodd" clipRule="evenodd" d="M25.9034 13.1802L13.1813 25.9024H16.8307C17.2543 25.9024 17.5977 26.2458 17.5977 26.6694C17.5977 27.093 17.2543 27.4364 16.8307 27.4364H11.3295C10.9059 27.4364 10.5625 27.093 10.5625 26.6694V21.1682C10.5625 20.7446 10.9059 20.4012 11.3295 20.4012C11.7532 20.4012 12.0966 20.7446 12.0966 21.1682V24.8177L24.8217 12.0926H21.1693C20.7473 12.0926 20.4052 11.7504 20.4052 11.3285C20.4052 10.9066 20.7473 10.5645 21.1693 10.5645H26.6704C27.0934 10.5645 27.4364 10.9066 27.4375 11.3285V16.8296C27.4375 17.2533 27.0941 17.5967 26.6704 17.5967C26.2468 17.5967 25.9034 17.2532 25.9034 16.8296V13.1802Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_4_1994">
                    <rect width="18" height="18" fill="white" transform="translate(10 10)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default RectMaximize