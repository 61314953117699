import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import MuiToolbar, { ToolbarProps } from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { LayoutProps } from "src/@core/layouts/types";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

interface Props {
  hidden: LayoutProps["hidden"];
  toggleNavVisibility: () => void;
  settings: LayoutProps["settings"];
  saveSettings: LayoutProps["saveSettings"];
  appBarContent: NonNullable<
    LayoutProps["verticalLayoutProps"]["appBar"]
  >["content"];
  appBarProps: NonNullable<
    LayoutProps["verticalLayoutProps"]["appBar"]
  >["componentProps"];
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  transition: "none",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Toolbar = styled(MuiToolbar)<ToolbarProps>(({ theme }) => ({
  width: "100%",
  padding: "0 !important",
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition:
    "padding .25s ease-in-out, box-shadow .25s ease-in-out, background-color .25s ease-in-out",
}));

const LayoutAppBar = (props: Props) => {
  // ** Props
  const { settings, appBarProps, appBarContent: userAppBarContent } = props;

  // ** Hooks
  const theme = useTheme();
  const scrollTrigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true,
  });

  // ** Vars
  const { skin, appBar, appBarBlur, contentWidth } = settings;

  const appBarFixedStyles = () => {
    return {
      px: `${theme.spacing(6)} !important`,
      boxShadow: skin === "bordered" ? 0 : 3,
      ...(appBarBlur && { backdropFilter: "blur(8px)" }),
      backgroundColor: hexToRGBA(
        theme.palette.background.paper,
        appBarBlur ? 0.9 : 1,
      ),
      ...(skin === "bordered" && {
        border: `1px solid ${theme.palette.divider}`,
        borderTopWidth: 0,
      }),
    };
  };

  if (appBar === "hidden") {
    return null;
  }

  let userAppBarStyle = {};
  if (appBarProps && appBarProps.sx) {
    userAppBarStyle = appBarProps.sx;
  }
  const userAppBarProps = Object.assign({}, appBarProps);
  delete userAppBarProps.sx;

  return (
    <AppBar
      elevation={0}
      color="default"
      className="layout-navbar"
      sx={{ ...userAppBarStyle, minHeight: "fit-content !important" }}
      position={appBar === "fixed" ? "sticky" : "static"}
      {...userAppBarProps}
    >
      <Toolbar
        className="navbar-content-container"
        sx={{
          ...(appBar === "fixed" &&
            scrollTrigger && { ...appBarFixedStyles() }),
          ...(contentWidth === "boxed" && {
            "@media (min-width:100%)": {
              maxWidth: `calc(100% - ${theme.spacing(6)} * 2)`,
            },
            minHeight: "fit-content !important",
            padding: "6px 4px 4px 4px !important",
          }),
        }}
      >
        {(userAppBarContent && userAppBarContent(props)) || null}
      </Toolbar>
    </AppBar>
  );
};

export default LayoutAppBar;
