import Divider from "@mui/material/Divider";
import MuiListSubheader, {
  ListSubheaderProps,
} from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

import Link from "next/link";

import { Settings } from "src/@core/context/settingsContext";
import { NavSectionTitle } from "src/@core/layouts/types";
import useProjectContext from "src/hooks/useProjectContext";
import Translations from "src/layouts/components/Translations";
import CanViewNavSectionTitle from "src/layouts/components/acl/CanViewNavSectionTitle";

interface Props {
  navHover: boolean;
  settings: Settings;
  item: NavSectionTitle;
  collapsedNavWidth: number;
  navigationBorderWidth: number;
}

// ** Styled Components
const ListSubheader = styled((props: ListSubheaderProps) => (
  <MuiListSubheader component="li" {...props} />
))(({ theme }) => ({
  lineHeight: 1,
  display: "flex",
  position: "static",
  padding: theme.spacing(3),
  marginTop: theme.spacing(6.25),
  backgroundColor: "transparent",
  color: theme.palette.text.disabled,
  transition: "padding-left .25s ease-in-out",
}));

const VerticalNavSectionTitle = (props: Props) => {
  // ** Props
  const { item, navHover, settings, collapsedNavWidth, navigationBorderWidth } =
    props;
  const { project } = useProjectContext();

  // ** Hook
  const theme = useTheme();

  // ** Vars
  const { mode, navCollapsed } = settings;

  const conditionalBorderColor = () => {
    if (mode === "semi-dark") {
      return {
        "&, &:before": {
          borderColor: `rgba(${theme.palette.customColors.dark}, 0.12)`,
        },
      };
    } else return {};
  };

  const conditionalColor = () => {
    if (mode === "semi-dark") {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.38) !important`,
      };
    } else {
      return {
        color: "text.disabled",
      };
    }
  };

  return (
    <CanViewNavSectionTitle navTitle={item}>
      {/* {(!navCollapsed || navHover) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', fontWeight: '500', fontSize: '18px', paddingLeft: '1.5rem' }}>
        {project?.name}
      </div>} */}
      <ListSubheader
        className="nav-section-title"
        sx={{
          ...(navCollapsed && !navHover
            ? {
                py: 4.75,
                px: (collapsedNavWidth - navigationBorderWidth - 22) / 8,
              }
            : { pl: 0 }),
        }}
      >
        <Divider
          textAlign="left"
          sx={{
            m: "0 !important",
            lineHeight: "normal",
            ...conditionalBorderColor(),
            "&:after": { display: "none" },
            ...(navCollapsed && !navHover
              ? { width: 22 }
              : {
                  width: "100%",
                  "&:before": {
                    top: 7,
                    transform: "none",
                    width: theme.spacing(4),
                  },
                  "& .MuiDivider-wrapper": {
                    px: 4,
                    fontSize: "0.75rem",
                    letterSpacing: "0.21px",
                  },
                }),
          }}
        >
          {navCollapsed && !navHover ? null : (
            <Link href="/" style={{ textDecoration: "none" }}>
              <Typography
                noWrap
                variant="caption"
                sx={{ ...conditionalColor() }}
              >
                <Translations text={item.sectionTitle} />
              </Typography>
            </Link>
          )}
        </Divider>
      </ListSubheader>
      {(!navCollapsed || navHover) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            fontWeight: "500",
            fontSize: "18px",
            paddingLeft: "1rem",
            marginTop: "0.7rem",
            marginBottom: "1rem",
          }}
        >
          {project?.name}
        </div>
      )}
    </CanViewNavSectionTitle>
  );
};

export default VerticalNavSectionTitle;
