import { Box, Button, styled } from "@mui/material";

import VideoIcon from "public/images/pages/icons/VideoIcon";

const BackgroundLayer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const IconContainer = styled(Button)({
  width: "100%",
  flex: 1,
});

const Camera = (props: any) => {
  return (
    <IconContainer>
      <Box>
        <BackgroundLayer>
          <VideoIcon color={props.color} />
        </BackgroundLayer>
      </Box>
    </IconContainer>
  );
};

export default Camera;
