import { Box, Button, styled } from "@mui/material";

import { useEffect, useState } from "react";
import { v4 } from "uuid";

import { IStaticText } from "src/@core/context/DesignEditorContext/layers";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";

import TextIcon from "public/images/pages/icons/TextIcon";

const BackgroundLayer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const IconContainer = styled(Button)({
  width: "100%",
  flex: 1,
});

const TextBox = (props: any) => {
  const { drawText, activeObject } = useCanvasContext();
  const {
    scene,
    setScene,
    scalePoint,
    setCurrentLayer,
    setLayersChanged,
    setLayersAddedDeleted,
  } = useDesignEditorContext();
  const [showTools, setShowTools] = useState(false);
  const { projectRoles } = useProjectContext();

  useEffect(() => {
    setShowTools(activeObject ? activeObject.get("type") === "textbox" : false);
  }, [activeObject]);

  const addTextBox = () => {
    props.setLoaderVisibility(true);
    const textboxLayer: IStaticText = {
      id: v4(),
      type: "StaticText",
      text: "Add your text here",
      top: 10,
      left: 10,
      width: 208,
      height: 100,
      boundingRectWidth: 208,
      boundingRectHeight: 100,
      display: {
        from: 0,
        to: scene?.duration > 10 ? 10 : scene.duration,
      },
      duration: scene?.duration > 10 ? 10 : scene.duration,
      fontFamily: "arial",
      fontSize: 24,
      scaleX: 1,
      scaleY: 1,
      fontWeight: "normal",
      textAlign: "left",
      fill: "#ffffff",
      fontStyle: "normal",
      textDecoration: "normal",
      isDeletable: true,
    };

    textboxLayer.startingPointPixel =
      scalePoint?.pixelPoints[textboxLayer.display.from];
    textboxLayer.pixelWidth =
      (scalePoint?.distanceBetweenTwoScalePoints || 1) * textboxLayer.duration;
    textboxLayer.endingPointPixel =
      scalePoint?.pixelPoints[textboxLayer.display.to];
    textboxLayer.pixelWidth =
      scalePoint?.pixelPoints[
        (textboxLayer.display.to - textboxLayer.display.from).toFixed(2)
      ];
    scene?.staticTextLayers.push(textboxLayer);
    setScene(scene);
    drawText(textboxLayer);
    setCurrentLayer(textboxLayer);
    setLayersChanged(true);
    setLayersAddedDeleted(true);
    props.setLoaderVisibility(false);
  };

  return (
    <IconContainer onClick={() => addTextBox()}>
      <Box>
        <BackgroundLayer>
          {/* <RectangleBackgroundLayer /> */}
          <TextIcon />
        </BackgroundLayer>
      </Box>
    </IconContainer>
  );
};

export default TextBox;
