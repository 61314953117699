const StopButton = (props: any) => {
    const color = props.color ?? '#999999'
    return (
        <svg width="28" height="28" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6758 34C7.48019 34 0 26.5191 0 17.3242C0 8.12935 7.48019 0.648438 16.6758 0.648438C25.8714 0.648438 33.3516 8.12935 33.3516 17.3242C33.3516 26.5191 25.8714 34 16.6758 34ZM16.6758 2.03806C8.24696 2.03806 1.38963 8.89539 1.38963 17.3242C1.38963 25.753 8.24696 32.6104 16.6758 32.6104C25.1046 32.6104 31.9619 25.753 31.9619 17.3242C31.9619 8.89539 25.1046 2.03806 16.6758 2.03806Z" fill={color}/>
        <rect x="11" y="11" width="12" height="12" rx="3" fill={color}/>
        </svg>
    )
}
export default StopButton


