const MessageIcon = (props: any) => {
    const color = props.color ?? '#00ACEE'
    return (
        <svg width="51" height="38" viewBox="0 0 51 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.6667 24.4252C28.4263 25.2521 26.9855 25.6892 25.5 25.6892C24.0146 25.6892 22.5738 25.2521 21.3334 24.4252L0.903491 10.8048C0.790144 10.7292 0.679719 10.6505 0.571533 10.5694V32.8879C0.571533 35.4468 2.64809 37.4776 5.1612 37.4776H45.8387C48.3976 37.4776 50.4284 35.401 50.4284 32.8879V10.5693C50.32 10.6505 50.2094 10.7295 50.0957 10.8052L29.6667 24.4252Z" fill={color}/>
        <path d="M2.52394 8.37402L22.9539 21.9945C23.7273 22.5101 24.6136 22.7679 25.4999 22.7679C26.3863 22.7679 27.2727 22.51 28.0461 21.9945L48.4761 8.37402C49.6986 7.55946 50.4285 6.19619 50.4285 4.72482C50.4285 2.19488 48.3702 0.136719 45.8404 0.136719H5.15964C2.62979 0.136816 0.571533 2.19497 0.571533 4.72726C0.571533 6.19619 1.30147 7.55946 2.52394 8.37402Z" fill={color}/>
        </svg>
        
    )
}
export default MessageIcon