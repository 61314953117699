import styled from "@emotion/styled";
import { useTimer } from "@layerhub-io/use-timer";
import { useRef } from "react";

import {
  IStaticImage,
  IStaticVideo,
} from "src/@core/context/DesignEditorContext/layers";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";

const TimeValue = styled("span")({
  fontSize: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Dot = styled("div")({
  fontSize: "8px",
  width: "100%",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const Point = (props: any) => {
  const nodeRef = useRef(null);
  const { pause, start } = useTimer();

  const { canvas, drawVideo, drawImage, drawText, reAlignObjectsBasedOnSize } =
    useCanvasContext();
  const { scene, displayPlayback, selectedVideoType } =
    useDesignEditorContext();
  const handleClick = () => {
    if (displayPlayback) {
      pause();
      setTimeout(() => {
        start();
      }, 300);
    }
    const currentTime = parseFloat(props.point) * 1000;
    props.setTime(currentTime);
    if (props.mode == 'COMPOSER') {
      props.setPlaybackSeeked(true);
      alignLayersInCanvas(Number(props.point));
    }
  };

  const alignLayersInCanvas = (currentPoint: number) => {
    canvas?.remove(...canvas?.getObjects());
    scene?.staticTextLayers.forEach((textLayer) => {
      if (
        Number(textLayer.display.to) > currentPoint &&
        Number(textLayer.display.from) <= currentPoint
      ) {
        drawText(textLayer as IStaticImage);
      }
    });

    scene?.staticImageLayers.forEach((imageLayer) => {
      if (
        Number(imageLayer.display.to) > currentPoint &&
        Number(imageLayer.display.from) <= currentPoint
      ) {
        drawImage(imageLayer as IStaticImage);
      }
    });

    scene?.videoLayers.forEach((videoLayer) => {
      const selectedCameraView = videoLayer[selectedVideoType]
        ? selectedVideoType
        : "videoWideAngle";
      if (
        Number(videoLayer[selectedCameraView].display.to) > currentPoint &&
        Number(videoLayer[selectedCameraView].display.from) <= currentPoint
      ) {
        drawVideo(videoLayer[selectedCameraView] as IStaticVideo);
      }
    });

    setTimeout(() => {
      reAlignObjectsBasedOnSize(true);
    }, 100);
  };

  return (
    <div
      key={props.point}
      style={{
        width: props.scaleSize + "px",
        maxWidth: props.scaleSize + "px",
        height: "100%",
        cursor: "pointer",
      }}
      onClick={() => handleClick()}
    >
      <Dot
        id={props.point}
        className="point"
        ref={nodeRef}
        style={{ padding: "10%" }}
      >
        <span>&middot;</span>
      </Dot>
      {props.point % 1 === 0 && (
        <TimeValue id={props.point} style={{ cursor: "pointer" }}>
          {props.point}
        </TimeValue>
      )}
    </div>
  );
};
export default Point;
