const MediaIcon = (props: any) => {
    const color = props.color ?? '#A7A7A7'
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_3344)">
                <path d="M22.0189 13.2636V20.3964C22.0034 20.4199 21.9912 20.4455 21.9828 20.4724C21.7584 21.666 20.861 22.4147 19.6483 22.416H3.25581C3.12702 22.4198 2.99815 22.4123 2.87068 22.3936C1.66542 22.173 0.913844 21.2722 0.912598 20.0488C0.912598 17.851 0.912598 15.6533 0.912598 13.4555V13.2412H21.7647C21.8494 13.2412 21.9342 13.2562 22.0189 13.2636Z" fill={color} />
                <path d="M22.0192 4.67075C21.9624 4.76804 21.8839 4.85098 21.7899 4.91319C21.6959 4.9754 21.5889 5.01521 21.4771 5.02956C20.8352 5.16412 20.1983 5.32235 19.5564 5.46064C19.5185 5.46586 19.4798 5.46229 19.4435 5.45019C19.4072 5.43809 19.3742 5.41779 19.347 5.39087C18.4022 4.01208 17.4629 2.62997 16.5289 1.24454C16.5205 1.22252 16.515 1.19947 16.5127 1.17601L17.5198 0.934308L19.5389 0.455885C19.565 0.445802 19.5898 0.432402 19.6125 0.416016H20.1285C20.151 0.432274 20.1758 0.445275 20.202 0.454638C20.4534 0.514189 20.6829 0.64301 20.8647 0.826467C21.0465 1.00992 21.1731 1.24066 21.2303 1.49247C21.3387 1.89116 21.4372 2.29109 21.5444 2.68977C21.7002 3.26413 21.8609 3.83849 22.0192 4.41285V4.67075Z" fill={color} />
                <path d="M22.0189 12.3188C21.5116 12.3188 21.0031 12.3288 20.4958 12.3301C19.6944 12.3301 18.893 12.3301 18.0915 12.3301H17.8311C17.8647 12.2341 17.8859 12.1681 17.9108 12.1046C18.3458 11.0181 18.7833 9.93421 19.2133 8.8453C19.2284 8.78543 19.2652 8.7333 19.3165 8.69901C19.3679 8.66471 19.4301 8.65069 19.4912 8.65966C20.1344 8.66713 20.7788 8.67461 21.4231 8.65966C21.5473 8.64411 21.6732 8.67016 21.781 8.73374C21.8888 8.79731 21.9725 8.89482 22.0189 9.011V12.3188Z" fill={color} />
                <path d="M4.62907 8.76084C4.53054 8.87003 4.44404 8.98949 4.37107 9.11716C3.96142 10.1214 3.55842 11.1285 3.16207 12.1385C3.14633 12.2021 3.10723 12.2576 3.05252 12.2938C2.99781 12.33 2.93148 12.3444 2.86668 12.3341C2.22354 12.3266 1.58041 12.3341 0.911097 12.3341C0.911097 12.1173 0.901125 11.9179 0.911097 11.7211C0.986877 10.4931 0.832589 9.26186 0.456166 8.09055C0.287903 7.55357 0.169494 7.00039 0.0485943 6.45095C-0.0249237 6.11927 0.0293126 5.77208 0.200486 5.47859C0.37166 5.1851 0.647197 4.96687 0.972168 4.86742C1.21813 4.79421 1.46774 4.73391 1.72 4.68677C1.75242 4.68324 1.78522 4.68738 1.81574 4.69885C1.84627 4.71032 1.87367 4.7288 1.89574 4.7528C2.8243 6.10086 3.74289 7.45265 4.62907 8.76084Z" fill={color} />
                <path d="M9.33812 7.78573C8.13037 8.05983 6.94132 8.33143 5.75102 8.59556C5.71968 8.59905 5.68796 8.59524 5.65834 8.58443C5.62871 8.57362 5.602 8.55612 5.58027 8.53327C4.65296 7.17649 3.73063 5.81722 2.78711 4.43054L4.17185 4.10286C4.90099 3.93093 5.63012 3.75526 6.3605 3.58956C6.39465 3.58333 6.42979 3.58516 6.46311 3.59489C6.49643 3.60462 6.52703 3.62199 6.55245 3.64562C7.47727 4.99617 8.39835 6.34838 9.31569 7.70226C9.32689 7.72894 9.33444 7.75702 9.33812 7.78573Z" fill={color} />
                <path d="M11.8584 2.27882C12.3906 2.15423 12.9016 2.02965 13.4126 1.90506C14.0732 1.74932 14.7313 1.5886 15.3932 1.44034C15.4302 1.4347 15.468 1.43749 15.5039 1.44849C15.5397 1.45949 15.5726 1.47842 15.6001 1.50388C16.5486 2.89056 17.4908 4.28098 18.4555 5.7013C18.1614 5.77231 17.8884 5.83959 17.6155 5.90188C16.8153 6.08378 16.0139 6.26195 15.2149 6.44634C15.1636 6.46938 15.1058 6.47351 15.0517 6.45801C14.9976 6.4425 14.9508 6.40835 14.9195 6.36162C13.9307 5.03848 12.9386 3.71742 11.9432 2.39843C11.9182 2.37102 11.897 2.33614 11.8584 2.27882Z" fill={color} />
                <path d="M14.0195 6.72006L11.9306 7.20347C11.4495 7.31311 10.9696 7.42524 10.4873 7.52366C10.4466 7.53146 10.4046 7.52977 10.3646 7.51876C10.3246 7.50774 10.2877 7.48768 10.2567 7.46012C9.32939 6.10957 8.40706 4.7557 7.48972 3.39851C7.47916 3.37549 7.47162 3.3512 7.46729 3.32625L9.5313 2.82789C9.93513 2.73195 10.3365 2.63228 10.7428 2.54756C10.7811 2.54194 10.8202 2.54532 10.857 2.55743C10.8938 2.56954 10.9272 2.59005 10.9547 2.61733C11.9717 3.95708 12.9846 5.30016 13.9934 6.64655C14.0048 6.67003 14.0135 6.69468 14.0195 6.72006Z" fill={color} />
                <path d="M4.08838 12.312C4.10001 12.2627 4.11415 12.214 4.13076 12.1662C4.57696 11.0449 5.02691 9.92358 5.47063 8.80228C5.48077 8.75818 5.50688 8.71939 5.54393 8.69339C5.58098 8.6674 5.62636 8.65604 5.67129 8.66149C6.79304 8.66149 7.90482 8.66149 9.02159 8.66149C9.04901 8.66149 9.07767 8.66149 9.13002 8.67395C8.94057 9.14614 8.75611 9.60837 8.57039 10.0706C8.29245 10.7658 8.01824 11.4648 7.73282 12.1575C7.71395 12.1981 7.68608 12.2339 7.65131 12.2622C7.61655 12.2905 7.57579 12.3105 7.53215 12.3207C6.4104 12.3307 5.29862 12.3207 4.18186 12.3207C4.15049 12.3208 4.11919 12.3178 4.08838 12.312Z" fill={color} />
                <path d="M8.66016 12.3027C8.85709 11.8043 9.05401 11.3209 9.2497 10.8312C9.51767 10.161 9.78191 9.49067 10.0574 8.82411C10.0741 8.78625 10.0994 8.75274 10.1311 8.72617C10.1629 8.6996 10.2003 8.68068 10.2406 8.67087C11.371 8.66215 12.5028 8.67087 13.6345 8.67087C13.6612 8.6751 13.6875 8.68177 13.713 8.6908L13.0425 10.3628C12.8007 10.9671 12.5638 11.5713 12.3146 12.1718C12.298 12.21 12.273 12.2439 12.2415 12.2711C12.21 12.2983 12.1728 12.318 12.1326 12.3288C11.0009 12.3375 9.86915 12.3288 8.73743 12.3288C8.71073 12.3232 8.68479 12.3144 8.66016 12.3027Z" fill={color} />
                <path d="M13.2422 12.3115C13.5214 11.6138 13.7931 10.941 14.0636 10.2657C14.2568 9.78106 14.445 9.29516 14.6444 8.81425C14.6718 8.75071 14.7541 8.66599 14.8114 8.66599C15.9631 8.65726 17.116 8.66599 18.2926 8.66599C18.2714 8.73326 18.2601 8.79058 18.2414 8.83418C17.7952 9.94801 17.3499 11.0623 16.9053 12.1769C16.8954 12.2246 16.8684 12.267 16.8294 12.2962C16.7904 12.3253 16.742 12.3392 16.6934 12.3351C15.5829 12.3351 14.4724 12.3351 13.3631 12.3351C13.3332 12.3302 13.3058 12.3239 13.2422 12.3115Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_8_3344">
                    <rect width="22" height="22" fill="white" transform="translate(0.019043 0.416016)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default MediaIcon