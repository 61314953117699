import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { clsx } from "clsx";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";

import { LayoutProps, NavGroup } from "src/@core/layouts/types";
import { hasActiveChild, removeChildren } from "src/@core/layouts/utils";
import { createNewScene, createNewShot } from "src/config/api";
import themeConfig from "src/configs/themeConfig";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";
import Translations from "src/layouts/components/Translations";
import CanViewNavGroup from "src/layouts/components/acl/CanViewNavGroup";

import VerticalNavItems from "./VerticalNavItems";

import AddCircle from "public/images/pages/icons/AddCircle";

interface Props {
  item: NavGroup;
  navHover: boolean;
  parent?: NavGroup;
  navVisible?: boolean;
  groupActive: string[];
  collapsedNavWidth: number;
  currentActiveGroup: string[];
  navigationBorderWidth: number;
  settings: LayoutProps["settings"];
  isSubToSub?: NavGroup | undefined;
  saveSettings: LayoutProps["saveSettings"];
  setGroupActive: (values: string[]) => void;
  setCurrentActiveGroup: (items: string[]) => void;
  verticalLayoutProps?: any;
}

const MenuItemTextWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  justifyContent: "space-between",
  transition: "opacity .25s ease-in-out",
  ...(themeConfig.menuTextTruncate && { overflow: "hidden" }),
}));

const VerticalNavGroup = (props: Props) => {
  // ** Props

  const {
    item,
    parent,
    settings,
    navHover,
    navVisible,
    isSubToSub,
    groupActive,
    setGroupActive,
    collapsedNavWidth,
    currentActiveGroup,
    setCurrentActiveGroup,
    navigationBorderWidth,
    verticalLayoutProps,
  } = props;

  // ** Hooks & Vars
  const router = useRouter();
  const { id, scene_id } = router.query;
  const currentURL = router.asPath;
  const { direction, navCollapsed, verticalNavToggleType } = settings;
  const { scene, setAddNewShot, setAddNewScene } = useDesignEditorContext();
  const { project, loadingScene, setLoadingScene } = useProjectContext();
  const [isAddingNewScene, setIsAddingNewScene] = useState(false);

  // useEffect(() => {
  //   if (addNewCameraSettings)
  //     props.item.children?.splice(2, 0, addNewCameraSettings)
  // }, [addNewCameraSettings]);

  useEffect(() => {
    if (scene) {
      setGroupActive([scene.name]);
    }
  }, [scene]);

  useEffect(() => {
    setTimeout(() => {
      setGroupActive([item.title]);
    }, 200);
  }, []);

  // ** Accordion menu group open toggle
  const toggleActiveGroup = (item: NavGroup, parent: NavGroup | undefined) => {
    let openGroup = groupActive;

    // ** If Group is already open and clicked, close the group
    if (openGroup.includes(item.title)) {
      openGroup.splice(openGroup.indexOf(item.title), 1);

      // If clicked Group has open group children, Also remove those children to close those groups
      if (item.children) {
        removeChildren(item.children, openGroup, currentActiveGroup);
      }
    } else if (parent) {
      // ** If Group clicked is the child of an open group, first remove all the open groups under that parent
      if (parent.children) {
        removeChildren(parent.children, openGroup, currentActiveGroup);
      }

      // ** After removing all the open groups under that parent, add the clicked group to open group array
      if (!openGroup.includes(item.title)) {
        openGroup.push(item.title);
      }
    } else {
      // ** If clicked on another group that is not active or open, create openGroup array from scratch

      // ** Empty Open Group array
      openGroup = [];

      // ** push Current Active Group To Open Group array
      if (currentActiveGroup.every((elem) => groupActive.includes(elem))) {
        openGroup.push(...currentActiveGroup);
      }

      // ** Push current clicked group item to Open Group array
      if (!openGroup.includes(item.title)) {
        openGroup.push(item.title);
      }
    }
    setGroupActive([...openGroup]);
  };

  // ** Menu Group Click
  const handleGroupClick = () => {
    if (loadingScene || item?.id == scene_id) {
      return;
    }
    if (router.pathname.includes("script")) {
      setLoadingScene(true);
    }
    const openGroup = groupActive;
    if (verticalNavToggleType === "collapse") {
      if (openGroup.includes(item.title)) {
        openGroup.splice(openGroup.indexOf(item.title), 1);
      } else {
        openGroup.push(item.title);
      }
      setGroupActive([...openGroup]);
    } else {
      if (openGroup.includes(item.title)) {
        openGroup.splice(openGroup.indexOf(item.title), 1);
      } else {
        openGroup.push(item.title);
      }
      setGroupActive([...openGroup]);
      // router.push(`/projects/${id}/scene/${item?.id}/script`);
      router.push(
        `/projects/${id}/scene/${item?.id}/${verticalLayoutProps.navMenu.horizontalActiveTab}`,
      );
    }
  };

  useEffect(() => {
    if (hasActiveChild(item, window.origin + currentURL)) {
      if (!groupActive.includes(item.title)) groupActive.push(item.title);
    } else {
      const index = groupActive.indexOf(item.title);
      if (index > -1) groupActive.splice(index, 1);
    }
    setGroupActive([...groupActive]);
    setCurrentActiveGroup([...groupActive]);

    // Empty Active Group When Menu is collapsed and not hovered, to fix issue route change
    if (navCollapsed && !navHover) {
      setGroupActive([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    if (navCollapsed && !navHover) {
      setGroupActive([]);
    }

    if (
      (navCollapsed && navHover) ||
      (groupActive.length === 0 && !navCollapsed)
    ) {
      setGroupActive([...currentActiveGroup]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navCollapsed, navHover]);

  useEffect(() => {
    if (groupActive.length === 0 && !navCollapsed) {
      setGroupActive([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navHover]);

  const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon;

  const menuGroupCollapsedStyles =
    navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 };

  const addNewShot = async () => {
    const newlyAddedShot = await createNewShot(scene?.id.toString() || "");
    setAddNewShot(true);
  };

  const addNewScene = async () => {
    setIsAddingNewScene(true);
    const addNewSceneResp = await createNewScene(project?.id || 0);
    setAddNewScene(true);
  };

  return (
    <CanViewNavGroup navGroup={item}>
      <Fragment>
        <ListItem
          disablePadding
          className="nav-group"
          onClick={handleGroupClick}
          sx={{
            mt: 1.5,
            flexDirection: "column",
            transition: "padding .25s ease-in-out",
            px: (theme) =>
              parent && item.children
                ? "0 !important"
                : `${theme.spacing(
                    navCollapsed && !navHover ? 2 : 3,
                  )} !important`,
          }}
        >
          <ListItemButton
            className={clsx({
              "Mui-selected": scene?.name == item.title,
            })}
            sx={{
              py: 1,
              width: "100%",
              borderRadius: "6px",
              transition: "padding-left .25s ease-in-out",
              // pr: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 3,
              // pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 4,
              px: 1.5,
              "&.Mui-selected": {
                backgroundColor: "#00ACEE",
                color: "white !important",
                "&:hover": {
                  backgroundColor: "#00ACEE !important",
                },
                ".MuiTypography-root": {
                  color: "white !important",
                },
              },
              "&.Mui-selected.Mui-focusVisible": {
                backgroundColor: "action.focus",
                "&:hover": {
                  backgroundColor: "action.focus",
                },
              },
            }}
          >
            {/* {isSubToSub ? null : (
              <ListItemIcon
                sx={{
                  transition: 'margin .25s ease-in-out',
                  ...(parent && navCollapsed && !navHover ? {} : { mr: 2 }),
                  ...(navCollapsed && !navHover ? { mr: 0 } : {}), // this condition should come after (parent && navCollapsed && !navHover) condition for proper styling
                  ...(parent && item.children ? { ml: 2, mr: 4 } : {}),
                  color: parent && item.children ? 'text.secondary' : 'text.primary',
                }}
              >
                {icon}
              </ListItemIcon>
            )} */}
            <MenuItemTextWrapper
              sx={{
                ...menuGroupCollapsedStyles,
                ...(isSubToSub ? { ml: 8 } : {}),
              }}
            >
              <Typography
                fontSize={11}
                {...((themeConfig.menuTextTruncate ||
                  (!themeConfig.menuTextTruncate &&
                    navCollapsed &&
                    !navHover)) && {
                  noWrap: true,
                })}
              >
                <Translations text={item.title} />
              </Typography>
              <Box
                className="menu-item-meta"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& svg": {
                    transition: "transform .25s ease-in-out",
                    ...(groupActive.includes(item.title) && {
                      transform:
                        direction === "ltr"
                          ? "rotate(90deg)"
                          : "rotate(-90deg)",
                    }),
                  },
                }}
              >
                {item.badgeContent ? (
                  <Chip
                    size="small"
                    label={item.badgeContent}
                    color={item.badgeColor || "primary"}
                    sx={{
                      mr: 1.5,
                      "& .MuiChip-label": {
                        px: 2.5,
                        lineHeight: 1.385,
                        textTransform: "capitalize",
                      },
                    }}
                  />
                ) : null}

                {!project?.isDemoProject && item.isLast && (
                  <Tooltip title="Add New Scene" placement="bottom">
                    {isAddingNewScene ? (
                      <IconButton sx={{ p: 0 }}>
                        <CircularProgress
                          sx={{
                            color: (scene?.name == item.title && "white") || "",
                          }}
                          size={10}
                        />
                      </IconButton>
                    ) : (
                      <IconButton onClick={addNewScene} sx={{ p: 0 }}>
                        <AddCircle
                          color={(scene?.name == item.title && "white") || ""}
                        />
                      </IconButton>
                    )}
                  </Tooltip>
                )}
              </Box>
            </MenuItemTextWrapper>
          </ListItemButton>
          <Collapse
            component="ul"
            onClick={(e) => e.stopPropagation()}
            in={groupActive.includes(item.title)}
            sx={{
              pl: 0,
              width: "100%",
              ...menuGroupCollapsedStyles,
              transition: "all 0.25s ease-in-out",
              paddingLeft: "1.5rem !important",
            }}
          >
            <VerticalNavItems
              {...props}
              parent={item}
              navVisible={navVisible}
              verticalNavItems={item.children}
              isSubToSub={parent ? item : undefined}
            />
            {/* <Button onClick={addNewShot} sx={{ pt: 3 }}>Add New Shot</Button> */}
          </Collapse>
        </ListItem>
      </Fragment>
    </CanViewNavGroup>
  );
};

export default VerticalNavGroup;
