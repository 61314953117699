import { Alert, Snackbar, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import {
  VeltCommentTool,
  VeltHuddleTool,
  VeltPresence,
  VeltSidebarButton,
} from "@veltdev/react";
import { useRouter } from "next/router";
import { useState } from "react";

import Icon from "src/@core/components/icon";
import { Settings } from "src/@core/context/settingsContext";
import CreditCount from "src/@core/layouts/components/shared-components/CreditCount";
import ModeToggler from "src/@core/layouts/components/shared-components/ModeToggler";
import ShareProjectDropdown from "src/@core/layouts/components/shared-components/ShareProjectDropdown";
import UserDropdown from "src/@core/layouts/components/shared-components/UserDropdown";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";

import alertStyle from "./AppBarContent.style";

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  getTopNavMenu: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;
  const [exportBtnText, setExportBtnText] = useState("Export");
  const [show, setShow] = useState<boolean>(false);
  const { scene } = useDesignEditorContext();
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const router = useRouter();

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {props.getTopNavMenu()}

      <Box sx={{ display: "flex", gap: "0.4rem", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <VeltSidebarButton />
          <VeltHuddleTool />
          <VeltCommentTool />
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", marginRight: "18px" }}
        >
          <CreditCount credits="100" />
        </Box>

        <Box>
          <Tooltip title={"Render Requests"}>
            <IconButton
              color="inherit"
              sx={{
                ml: -2.75,
                padding: "0 !important",
                marginRight: "8px",
              }}
              onClick={() => {
                router.push(`/my-render-requests`);
              }}
            >
              <Icon
                icon="fluent-mdl2:server-processes"
                style={{ height: "20px", width: "20px" }}
              />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ShareProjectDropdown settings={settings} />
        </Box>

        <Box
          className="actions-left"
          sx={{ mr: 2, display: "flex", alignItems: "center" }}
        >
          {hidden ? (
            <IconButton
              color="inherit"
              sx={{ ml: -2.75, p: 0 }}
              onClick={toggleNavVisibility}
            >
              <Icon icon="mdi:menu" style={{ height: "20px", width: "20px" }} />
            </IconButton>
          ) : null}

          <ModeToggler settings={settings} saveSettings={saveSettings} />
        </Box>

        <Box>
          <VeltPresence flockMode={true} self={false} />
        </Box>
        <Box
          className="actions-right"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <UserDropdown settings={settings} />
        </Box>

        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton>
            <ShareIcon />
          </IconButton>
          <ShareProject show={show} setShow={setShow} />
        </Box> */}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={closeSnackbar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert style={alertStyle} onClose={closeSnackbar} severity="success">
          Your video is exported successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AppBarContent;
