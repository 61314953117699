import { styled, useTheme } from "@mui/material/styles";

import Link from "next/link";

const LinkStyled = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  marginRight: theme.spacing(8),
}));

function Logo(props: any) {
  const theme = useTheme();

  return (
    <LinkStyled href="/">
      {props.width ? (
        <img
          src={`/images/pages/pentopixLogo/pentopix_logo_${theme.palette.mode}.png`}
          style={{
            width: props.width,
            height: props.height ? props.height : "3rem",
            weight: props.weight ? props.weight : "7rem",
          }}
        />
      ) : (
        <img
          src={`/images/pages/pentopixLogo/pentopix_logo_${theme.palette.mode}.png`}
          style={{
            height: props.height ? props.height : "3rem",
            weight: props.weight ? props.weight : "7rem",
          }}
        />
      )}
    </LinkStyled>
  );
}

export default Logo;
