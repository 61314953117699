import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonBase,
  Card,
  Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import { useEffect, useRef, useState } from "react";

import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";
import { hasEditPermissions } from "src/libs/utils";

import Audio from "../CanvasDesigner/objects/Audio";
import Camera from "../CanvasDesigner/objects/Camera";
import Image from "../CanvasDesigner/objects/Image";
import TextBox from "../CanvasDesigner/objects/TextBox";
import Video from "../CanvasDesigner/objects/Video";

import MasterTimeline from "./Timeline/MasterTimeline";
import VideoTimelineControl from "./VideoTimelineController";

const BackgroundLayer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const IconContainer = styled(Button)({
  width: "100%",
  // flex: 1
});

const MediaSelector = styled(Box)({
  maxHeight: "100%",
  height: "100% !important",
  // minHeight: '30vh',
  display: "flex",
  flexDirection: "column",
});

const Timeline = (props: any) => {
  const {
    scaleSize,
    scene,
    layersChanged,
    layersAddedDeleted,
    setLayersAddedDeleted,
    selectedLayerId,
    cameraSelection
  } = useDesignEditorContext();
  const { canvas } = useCanvasContext();
  const [showLoader, setLoaderVisibility] = useState(false);
  const masterTimelineContainer = useRef(null);
  const [layersVisibility, setLayersVisibility] = useState(false);
  const [allowEdit, setAllowEdit] = useState();
  const { projectRoles } = useProjectContext();
  const theme = useTheme();
  const [textTimeLineHeight, setTextTimelineHeight] = useState({
    textTimeLine: "",
    imageTimeLine: "",
    audioTimeLine: "",
    videoTimeLine: "",
    cameraTimeline: "",
    scaleHeight: "",
  });

  const setHeights = () => {
    setTextTimelineHeight({
      textTimeLine:
        String(document.getElementById("textTimeLine")?.clientHeight) + "px" ||
        "",
      imageTimeLine:
        String(document.getElementById("imageTimeLine")?.clientHeight) + "px" ||
        "",
      audioTimeLine:
        String(document.getElementById("audioTimeLine")?.clientHeight) + "px" ||
        "",
      videoTimeLine:
        String(document.getElementById("videoTimeLine")?.clientHeight) + "px" ||
        "",
      scaleHeight:
        String(document.getElementById("timelineScale")?.clientHeight) + "px" ||
        "",
      cameraTimeline:
        String(document.getElementById("cameraTimeLine")?.clientHeight) +
        "px" || "",
    });

    setLayersAddedDeleted(false);
  };

  useEffect(() => {
    const hasPermissions = hasEditPermissions(projectRoles);
    setAllowEdit(hasPermissions);
  }, [projectRoles]);

  useEffect(() => {
    if (layersAddedDeleted) {
      setTimeout(() => {
        setHeights();
      }, 500);
    }
  }, [layersAddedDeleted, layersChanged]);

  // useEffect(() => {
  //     setTimeout(() => {
  //         setHeights();
  //     }, 5000)
  // }, [])

  return (
    <Grid container spacing={3} className="match-height" px={3}>
      <Grid item xs={0.95} md={0.95}>
        <Box sx={{ height: "100%" }}>
          <Card>
            <Grid container alignItems="center" maxHeight="min-content">
              <Grid
                item
                xs={12}
                md={12}
                display="flex"
                justifyContent="center"
                visibility={props.mode === "SHOT-DESIGN" ? "hidden" : "visible"}
              >
                <VideoTimelineControl mode={props.mode} />
              </Grid>
              <Grid item xs={12} md={12} display="flex" justifyContent="center">
                <MediaSelector>
                  <Accordion
                    expanded={layersVisibility}
                    sx={{
                      margin: "0 !important",
                      boxShadow: "none",
                      "& .MuiAccordionSummary-root": {
                        minHeight:
                          textTimeLineHeight.scaleHeight + " !important",
                        height: textTimeLineHeight.scaleHeight,
                        // marginBottom: "1.25rem"
                      },
                    }}
                  >
                    <AccordionSummary>
                      <IconContainer
                        onClick={() => {
                          setLayersVisibility(!layersVisibility);
                          setLayersAddedDeleted(true);
                        }}
                      >
                        <Box>
                          {props.mode == "COMPOSER" && (
                            <>
                              <BackgroundLayer>
                                {!layersVisibility && <ExpandMore />}
                                {layersVisibility && <ExpandLess />}
                              </BackgroundLayer>
                            </>
                          )}
                        </Box>
                      </IconContainer>
                    </AccordionSummary>
                    <AccordionDetails style={{ height: "100%", padding: 0 }}>
                      {props.mode == "COMPOSER" && (
                        <>
                          {allowEdit && (
                            <>
                              <Tooltip title="Text" placement="right-start">
                                <div
                                  style={{
                                    height: textTimeLineHeight.textTimeLine,
                                    marginBottom: "0.75rem",
                                  }}
                                >
                                  <TextBox
                                    setLoaderVisibility={setLoaderVisibility}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title="Image" placement="right-start">
                                <div
                                  style={{
                                    height: textTimeLineHeight.imageTimeLine,
                                    marginBottom: "0.75rem",
                                  }}
                                >
                                  <Image
                                    setLoaderVisibility={setLoaderVisibility}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip title="Audio" placement="right-start">
                                <div
                                  style={{
                                    height: textTimeLineHeight.audioTimeLine,
                                    marginBottom: "0.75rem",
                                  }}
                                >
                                  <Audio
                                    setLoaderVisibility={setLoaderVisibility}
                                  />
                                </div>
                              </Tooltip>
                            </>
                          )}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>

                  {props.mode == "COMPOSER" && (
                    <>
                      {allowEdit && (
                        <Tooltip title="Video" placement="right-start">
                          <div
                            style={{
                              height: textTimeLineHeight.videoTimeLine,
                              marginBottom: "0.75rem",
                            }}
                          >
                            <Video setLoaderVisibility={setLoaderVisibility} />
                          </div>
                        </Tooltip>
                      )}
                    </>
                  )}

                  {props.mode == "SHOT-DESIGN" && (
                    <>
                      {(
                        <div style={{ marginTop: "12px" }}>
                          {" "}
                          {scene?.cameraShotCurves
                            ?.filter(
                              (layer) => layer.isActive && !layer.isDeleted,
                            )
                            .map((cameraShotCurve, index) => (
                              <div
                                key={index}
                                style={{
                                  height: textTimeLineHeight.videoTimeLine,
                                  marginTop: "8px",
                                  position: "relative",
                                  textAlign: "center",
                                }}
                              >
                                <ButtonBase onClick={() => cameraSelection(cameraShotCurve.canvasKeyframes[0].id)}><Camera color={(selectedLayerId == cameraShotCurve.canvasKeyframes[0].id) && '#4DA9E8'} /></ButtonBase>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    position: "absolute",
                                    left: 0,
                                    right: "6px",
                                    top: "5px",
                                    color: (selectedLayerId == cameraShotCurve.canvasKeyframes[0].id) ? '#4DA9E8' : '#A7A7A7',
                                    pointerEvents: 'none'
                                  }}
                                >
                                  {cameraShotCurve.name}
                                </Typography>
                              </div>
                            ))}{" "}
                        </div>
                      )}
                    </>
                  )}
                </MediaSelector>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Grid>
      <Grid item xs={11} md={11}>
        <Card ref={masterTimelineContainer}>
          <MasterTimeline
            mode={props.mode}
            disableButton={props.disableButton}
            width={masterTimelineContainer.current?.clientWidth}
            showLoader={showLoader}
            layersVisibility={layersVisibility}
            editor={props?.editor}
            setSelectedLayerId={props?.setSelectedLayerId}
            setMinimizedElements={props?.setMinimizedElements}
            publishShotCurves={props.publishShotCurves}
            publishInProgress={props.publishInProgress}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Timeline;
