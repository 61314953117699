import { useTheme } from '@mui/material/styles'
const ProjectGroup = (props: any) => {
    const theme = useTheme()
    const color = props.color ?? theme.palette.mode === 'light' ? '#000' : '#fff'
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><g fill={color}><path d="M6.5 12a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7Zm0 3a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7Z" /><path fill-rule="evenodd" d="M11.185 1H4.5A1.5 1.5 0 0 0 3 2.5v15A1.5 1.5 0 0 0 4.5 19h11a1.5 1.5 0 0 0 1.5-1.5V7.202a1.5 1.5 0 0 0-.395-1.014l-4.314-4.702A1.5 1.5 0 0 0 11.185 1ZM4 2.5a.5.5 0 0 1 .5-.5h6.685a.5.5 0 0 1 .369.162l4.314 4.702a.5.5 0 0 1 .132.338V17.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-15Z" clip-rule="evenodd" /><path d="M11 7h5.5a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 1 0V7Z" /></g></svg>
    )
}
export default ProjectGroup;

// width="26" height="33" viewBox="0 0 26 33" fill="none"