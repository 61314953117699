import { styled } from "@mui/material";

import { useTimer } from "@layerhub-io/use-timer";
import { useEffect, useState } from "react";
import { Rnd } from "react-rnd";

import {
  IStaticImage,
  IStaticVideo,
} from "src/@core/context/DesignEditorContext/layers";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import { closest } from "src/utils/closest";

// Styled components for the timeline marker elements
const TimelineMarkerControl = styled("div")({
  position: "sticky",
  top: 0,
  cursor: "pointer",
  zIndex: "4",
  height: "100%",
});

// Define the Parent component inline and conditionally apply the transition CSS
const Parent = styled("div")({
  height: "100%",
  maxHeight: "100%",
  position: "absolute",
  "& .react-draggable": {
    height: "100% !important",
  },
});

const Circle = styled("div")({
  width: "9px",
  height: "9px",
  border: "2px solid #00acee",
  borderRadius: "3px",
  backgroundColor: "#00acee",
  position: "absolute",
  top: "8px",
  left: "-5px",
  zIndex: "4",
  transform: "rotate(45deg)",
  cursor: "move",
});

const Line = styled("div")({
  borderLeft: "2px solid #00acee",
  position: "absolute",
  top: "13px",
  left: "-1.5px",
  zIndex: "4",
  cursor: "move",
  bottom: 0,
});

/**
 * Component for the timeline marker.
 */
const TimelineMarker = (props) => {
  // Hooks
  const { time, pause, reset, setTime } = useTimer();
  const [timelineMarkerPosition, setTimelineMarkerPosition] = useState(30);
  const {
    scene,
    scalePoint,
    displayPlayback,
    setDisplayPlayback,
    scaleSize,
    setPlaybackSeeked,
    playbackVisibility,
    playbackSeeked,
    selectedVideoType,
  } = useDesignEditorContext();
  const { canvas, drawText, drawImage, drawVideo, reAlignObjectsBasedOnSize } =
    useCanvasContext();
  const [position, setPosition] = useState({ x: 30, y: 0 });

  /**
   * Update the marker position and time when the timer changes.
   */
  useEffect(() => {
    const value = Math.floor((time / 1000).toFixed(2) * 10) / 10;
    let timeValue = "";
    if (value % 1 !== 0) {
      timeValue = `${value}0`;
    } else {
      timeValue = `${value}.00`;
    }
    if (Number(timeValue) <= scene?.duration) {
      const newXPosition =
        scalePoint?.pixelPoints &&
          Object.keys(scalePoint.pixelPoints).length > 0
          ? scalePoint.pixelPoints[Number(timeValue).toFixed(2)]
          : 0;
      setPosition({
        ...position,
        x: newXPosition,
        y: 0,
      });
      setTimelineMarkerPosition(newXPosition);
    }

    if ((Number(timeValue) >= scene?.duration) && displayPlayback) {
      setTimeout(() => {
        const initialXPosition =
          scalePoint?.pixelPoints &&
            Object.keys(scalePoint.pixelPoints).length > 0
            ? scalePoint.pixelPoints["0.00"]
            : 0;
        setTimelineMarkerPosition(initialXPosition);
      }, 100);
      setDisplayPlayback(false);
      reset();
    }
  }, [time, scalePoint?.pixelPoints]);

  useEffect(() => {
    setTimelineMarkerPosition(position.x);
  }, [playbackVisibility]);

  /**
   * Set the initial marker position based on the scale points.
   */
  useEffect(() => {
    setPosition({
      x:
        scalePoint?.pixelPoints &&
          Object.keys(scalePoint.pixelPoints).length > 0
          ? scalePoint.pixelPoints["0.00"]
          : 0,
      y: 0,
    });
  }, [scalePoint]);

  /**
   * Handle the dragging of the marker and update the time and position.
   * @param {object} e - The event object.
   * @param {object} d - The draggable data.
   */
  const onDragStop = (e, d) => {
    const closestTime = closest(scalePoint?.pixelPoints, d.x);
    setTime(closestTime * 1000);
    if (props.mode == 'COMPOSER') {
      setPlaybackSeeked(true);
      alignLayersInCanvas(closestTime);
    }
  };

  const onDrag = (e, d) => {
    setTimelineMarkerPosition(d.x);
  };

  const alignLayersInCanvas = (currentPoint: number) => {
    canvas?.remove(...canvas.getObjects());
    scene?.staticTextLayers.forEach((textLayer) => {
      if (
        Number(textLayer.display.to) > currentPoint &&
        Number(textLayer.display.from) <= currentPoint
      ) {
        drawText(textLayer as IStaticImage);
      }
    });

    scene?.staticImageLayers.forEach((imageLayer) => {
      if (
        Number(imageLayer.display.to) > currentPoint &&
        Number(imageLayer.display.from) <= currentPoint
      ) {
        drawImage(imageLayer as IStaticImage);
      }
    });

    scene?.videoLayers.forEach((videoLayer) => {
      const selectedCameraView = videoLayer[selectedVideoType]
        ? selectedVideoType
        : "videoWideAngle";
      if (
        Number(videoLayer[selectedCameraView].display.to) > currentPoint &&
        Number(videoLayer[selectedCameraView].display.from) <= currentPoint
      ) {
        drawVideo(videoLayer[selectedCameraView] as IStaticVideo);
      }
    });

    setTimeout(() => {
      reAlignObjectsBasedOnSize(true);
    }, 100);
  };

  return (
    <Parent>
      <Rnd
        dragAxis="x"
        style={{
          zIndex: 15,
          transition: displayPlayback ? "transform 0.3s ease" : "none",
        }}
        position={{
          x: timelineMarkerPosition,
          y: 0,
        }}
        enableResizing={{
          top: false,
          bottom: false,
          topRight: false,
          topLeft: false,
          bottomRight: false,
          bottomLeft: false,
          right: false,
          left: false,
        }}
        onDragStop={onDragStop}
        onDrag={onDrag}
      >
        <TimelineMarkerControl className="TimelineMarker">
          <Circle />
          <Line />
        </TimelineMarkerControl>
      </Rnd>
    </Parent>
  );
};

export default TimelineMarker;
