import { ReactNode } from "react";

import "src/@core/layouts/Layout";

import { CanvasContextProvider } from "src/@core/context/CanvasContext/CanvasContext";
import { DesignEditorProvider } from "src/@core/context/DesignEditorContext/DesignEditorContext";
import { ProjectContextProvider } from "src/@core/context/ProjectContext/ProjectContext";
import ProjectLayout from "src/components/Projects/ProjectLayout";

import { TimerProvider } from "@layerhub-io/use-timer";

import { useAuth } from "src/config/auth";

interface Props {
  children: ReactNode;
  contentHeightFixed?: boolean;
}

const UserLayout = ({ children, contentHeightFixed }: Props) => {
  const { userData } = useAuth();
  return (
    <ProjectContextProvider>
      <DesignEditorProvider>
        <CanvasContextProvider>
          <TimerProvider>
            <ProjectLayout contentHeightFixed={contentHeightFixed}>
              {children}
            </ProjectLayout>
          </TimerProvider>
        </CanvasContextProvider>
      </DesignEditorProvider>
    </ProjectContextProvider>
  );
};

export default UserLayout;
