const ImageIcon = (props: any) => {
    const color = props.color ?? '#A7A7A7'
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_3341)">
                <path d="M9.06877 14.0664C10.0891 12.4337 11.0536 10.8872 12.0133 9.34216C12.8471 8.00639 13.6618 6.64835 14.5099 5.31554C15.2735 4.11185 16.0483 4.09256 16.788 5.27546C19.004 8.82171 21.2089 12.3714 23.4025 15.9246C24.147 17.1327 23.7644 17.7709 22.2961 17.7739C15.6322 17.7838 8.96833 17.7838 2.30443 17.7739C0.75484 17.7739 0.391364 17.155 1.16776 15.8786C2.26459 14.0827 3.37417 12.2927 4.49651 10.5087C5.29362 9.24569 6.03972 9.23233 6.82887 10.4657C7.55903 11.6189 8.27006 12.787 9.06877 14.0664Z" fill={color}/>
                <path d="M6.70119 0.783448C7.43858 0.792895 8.14174 1.07467 8.65598 1.56677C9.17022 2.05888 9.45342 2.72101 9.44327 3.40751C9.43313 4.09401 9.13046 4.74863 8.60187 5.22738C8.07328 5.70613 7.36206 5.96978 6.62467 5.96033C6.25955 5.95565 5.89899 5.88407 5.56359 5.74966C5.22818 5.61526 4.9245 5.42067 4.66988 5.177C4.41525 4.93334 4.21467 4.64537 4.07959 4.32953C3.94451 4.0137 3.87756 3.67619 3.88259 3.33627C3.88761 2.99635 3.9645 2.66068 4.10887 2.34843C4.25324 2.03617 4.46226 1.75345 4.72399 1.5164C4.98572 1.27935 5.29504 1.09261 5.63429 0.96685C5.97354 0.84109 6.33607 0.77877 6.70119 0.783448Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_8_3341">
                <rect width="23" height="17" fill="white" transform="translate(0.76416 0.783203)"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default ImageIcon