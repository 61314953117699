export const closest = (array: Object, num: any) => {
  let minDiff = 1000;
  let ans;
  for (const i in array) {
    const m = Math.abs(num - array[i]);
    if (m < minDiff) {
      minDiff = m;
      ans = Number(i).toFixed(2);
    }
  }
  return ans;
};
export const closestObject = (obj: Object, num: any) => {
  const searchkey = num;

  let closestKey = Object.keys(obj)[0];
  let closestDiff = Math.abs(searchkey - parseFloat(closestKey));

  for (const key of Object.keys(obj)) {
    const diff = Math.abs(searchkey - parseFloat(key));
    if (diff < closestDiff) {
      closestKey = key;
      closestDiff = diff;
    }
  }

  const closestValue = obj[closestKey];
  return closestValue;
};
