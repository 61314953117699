import { Box, Button, styled } from "@mui/material";

import axios from "axios";
import { useRef } from "react";
import { v4 } from "uuid";

import { IStaticImage } from "src/@core/context/DesignEditorContext/layers";
import { createImageUploadUrl } from "src/config/api";
import useCanvasContext from "src/hooks/useCanvasContext";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";
import useProjectContext from "src/hooks/useProjectContext";

import ImageIconBordered from "public/images/pages/icons/ImageIconBordered";

const BackgroundLayer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const IconContainer = styled(Button)({
  width: "100%",
  flex: 1,
});

const Image = (props: any) => {
  const { canvas, drawImage } = useCanvasContext();
  const {
    scene,
    setScene,
    scalePoint,
    setCurrentLayer,
    setLayersChanged,
    setLayersAddedDeleted,
  } = useDesignEditorContext();
  const fileUploadRef = useRef(null);
  const { projectRoles, project } = useProjectContext();

  const addImage = () => {
    fileUploadRef.current?.click();
  };

  const getImageMetadata = async (url: string) => {
    return new Promise<{ width: number; height: number; url: string }>(
      (resolve, reject) => {
        const img = document.createElement("img");
        img.onload = () => {
          resolve({ width: img.width, height: img.height, url });
        };
        img.onerror = reject;
        img.src = url;
      },
    );
  };

  const onImageUpload = async (e) => {
    props.setLoaderVisibility(true);

    const file = e.target.files[0];
    if (!file) return;

    const createImageUploadUrlResp = await createImageUploadUrl({
      projectId: project.id,
      fileName: file.name,
      contentType: file.type,
    });
    await axios.put(createImageUploadUrlResp.uploadUrl, file, {
      headers: createImageUploadUrlResp.headers,
    });
    const s3url = createImageUploadUrlResp.destObjectUrl;
    const { width, height, url } = await getImageMetadata(s3url);

    const widthFactor = canvas.width / width;
    const heightFactor = canvas.height / height;
    const minFactor = Math.min(widthFactor, heightFactor);
    const imageLayer: IStaticImage = {
      id: v4(),
      type: "StaticImage",
      width: width,
      height: height,
      top: null,
      left: null,
      src: url,
      preview: url,
      display: {
        from: 0,
        to: scene?.duration > 10 ? 10 : scene.duration,
      },
      duration: scene?.duration > 10 ? 10 : scene.duration,
      scaleX: minFactor,
      scaleY: minFactor,
      isDeletable: true,
    };

    imageLayer.startingPointPixel = scalePoint?.pixelPoints["0.00"];
    imageLayer.pixelWidth =
      scalePoint?.distanceBetweenTwoScalePoints * imageLayer.duration;
    imageLayer.endingPointPixel =
      scalePoint?.pixelPoints[imageLayer.display.to];
    imageLayer.pixelWidth =
      scalePoint?.pixelPoints[
        (imageLayer.display.to - imageLayer.display.from).toFixed(2)
      ];

    scene?.staticImageLayers.push(imageLayer);
    drawImage(imageLayer as IStaticImage);
    setCurrentLayer(imageLayer as IStaticImage);
    setLayersChanged(true);

    if (file) {
      fileUploadRef.current!.value = null;
    }
    props.setLoaderVisibility(false);
    setLayersAddedDeleted(true);
    setScene(scene);
  };

  return (
    <IconContainer onClick={() => addImage()}>
      <Box>
        <BackgroundLayer>
          {/* <RectangleBackgroundLayer /> */}
          <ImageIconBordered />
          <input
            type="file"
            ref={fileUploadRef}
            id="fabric-image-upload"
            accept="image/*"
            onChange={onImageUpload}
            style={{ display: "none" }}
          />
        </BackgroundLayer>
      </Box>
    </IconContainer>
  );
};

export default Image;
