import { Box, Button, styled } from "@mui/material";

import { useRef } from "react";

import Icon from "src/@core/components/icon";
import useProjectContext from "src/hooks/useProjectContext";

const BackgroundLayer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

const IconContainer = styled(Button)({
  width: "100%",
  flex: 1,
});

const Video = (props: any) => {
  const fileUploadRef = useRef(null);
  const { projectRoles, project } = useProjectContext();

  const addVideoFile = () => {
    fileUploadRef.current?.click();
  };

  const onVideoUpload = async (e) => {
    props.setLoaderVisibility(true);
    console.error("uploading video is not implemented");
    props.setLoaderVisibility(false);
  };

  return (
    <IconContainer onClick={() => addVideoFile()}>
      <Box>
        <BackgroundLayer>
          <Icon icon="octicon:video-24" fontSize={25} color="#A7A7A7" />
          <input
            type="file"
            ref={fileUploadRef}
            id="fabric-audio-upload"
            accept="video/*"
            onChange={onVideoUpload}
            style={{ display: "none" }}
          />
        </BackgroundLayer>
      </Box>
    </IconContainer>
  );
};

export default Video;
