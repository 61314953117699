import { hexToRGBA } from "src/@core/utils/hex-to-rgba";

import { OwnerStateThemeType } from "./";

const Backdrop = () => {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          backgroundColor:
            theme.palette.mode === "light"
              ? `rgba(${theme.palette.customColors.main}, 0.5)`
              : hexToRGBA("#17191c", 0.87),
        }),
        invisible: {
          backgroundColor: "transparent",
        },
      },
    },
  };
};

export default Backdrop;
