const TextIcon = (props: any) => {
    const color = props.color ?? '#00ACEE'
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.08729 5.45416C1.4564 5.45416 1.75564 5.15492 1.75564 4.78581V1.70095H4.8405C5.20961 1.70095 5.50885 1.40171 5.50885 1.0326C5.50885 0.663499 5.20961 0.364258 4.8405 0.364258H1.08729C0.718186 0.364258 0.418945 0.663499 0.418945 1.0326V4.78581C0.418945 5.15496 0.718186 5.45416 1.08729 5.45416Z" fill={color}/>
            <path d="M22.5632 0.364258H18.8099C18.4408 0.364258 18.1416 0.663499 18.1416 1.0326C18.1416 1.40171 18.4408 1.70095 18.8099 1.70095H21.8948V4.78581C21.8948 5.15492 22.1941 5.45416 22.5632 5.45416C22.9323 5.45416 23.2315 5.15492 23.2315 4.78581V1.0326C23.2315 0.663499 22.9323 0.364258 22.5632 0.364258Z" fill={color}/>
            <path d="M22.5632 18.0869C22.1941 18.0869 21.8948 18.3862 21.8948 18.7553V21.8401H18.8099C18.4408 21.8401 18.1416 22.1394 18.1416 22.5085C18.1416 22.8776 18.4408 23.1768 18.8099 23.1768H22.5632C22.9323 23.1768 23.2315 22.8776 23.2315 22.5085V18.7553C23.2315 18.3861 22.9323 18.0869 22.5632 18.0869Z" fill={color}/>
            <path d="M4.8405 21.8401H1.75564V18.7553C1.75564 18.3862 1.4564 18.0869 1.08729 18.0869C0.718186 18.0869 0.418945 18.3862 0.418945 18.7553V22.5085C0.418945 22.8776 0.718186 23.1768 1.08729 23.1768H4.8405C5.20961 23.1768 5.50885 22.8776 5.50885 22.5085C5.50885 22.1394 5.20965 21.8401 4.8405 21.8401Z" fill={color}/>
            <path d="M19.1174 8.89199V4.67117C19.1174 4.46175 18.9476 4.29199 18.7378 4.29199H11.8253H4.91282C4.70296 4.29199 4.5332 4.46175 4.5332 4.67117V8.89199C4.5332 9.10186 4.70296 9.27162 4.91282 9.27162H6.92856C7.13797 9.27162 7.30773 9.10186 7.30773 8.89199V7.44614C7.30773 7.23628 7.47794 7.06652 7.68735 7.06652H10.0582C10.2681 7.06652 10.4378 7.23628 10.4378 7.44614V17.8786C10.4378 18.0884 10.2681 18.2582 10.0582 18.2582H9.02137C8.81151 18.2582 8.64175 18.428 8.64175 18.6378V20.6535C8.64175 20.863 8.81151 21.0327 9.02137 21.0327H11.8253H14.6292C14.8391 21.0327 15.0089 20.863 15.0089 20.6535V18.6378C15.0089 18.428 14.8391 18.2582 14.6292 18.2582H13.5924C13.3826 18.2582 13.2128 18.0884 13.2128 17.8786V7.44614C13.2128 7.23628 13.3826 7.06652 13.5924 7.06652H15.9633C16.1727 7.06652 16.3429 7.23628 16.3429 7.44614V8.89199C16.3429 9.10186 16.5126 9.27162 16.7221 9.27162H18.7378C18.9476 9.27162 19.1174 9.10186 19.1174 8.89199Z" fill={color}/>
        </svg>

    )
}
export default TextIcon