import { useContext } from "react";

import DesignEditorContext from "src/@core/context/DesignEditorContext";

const useDesignEditorContext = () => {
  const {
    scene,
    setScene,
    currentLayer,
    setCurrentLayer,
    currentKeyFrame,
    setCurrentKeyFrame,
    currentScriptComponentId,
    setCurrentScriptComponentId,
    currentShotId,
    setCurrentShotId,
    scalePoint,
    setScalePoint,
    displayPlayback,
    setDisplayPlayback,
    displayPreview,
    setDisplayPreview,
    playbackSeeked,
    setPlaybackSeeked,
    playbackVisibility,
    setPlaybackVisibility,
    videoEditorMode,
    setVideoEditorMode,
    forceReloadFrames,
    setForceReloadFrames,
    fullScreenMode,
    setFullScreenMode,
    screenShotTaken,
    setScreenShotTaken,
    addNewShot,
    setAddNewShot,
    addNewScene,
    setAddNewScene,
    addNewCameraSettings,
    setAddNewCameraSettings,
    maxTime,
    setMaxTime,
    reRenderState,
    reRenderTimeline,
    scaleSize,
    setScaleSize,
    reRenderClips,
    reRenderAllClips,
    updateScalePoint,
    updateScale,
    fitTimeline,
    setFitTimeline,
    scaleRenderingProgress,
    setScaleRenderingProgress,
    layersChanged,
    setLayersChanged,
    sceneParsedOrRendered,
    setSceneParsedOrRendered,
    layersAddedDeleted,
    setLayersAddedDeleted,
    deletedLayerId,
    setDeletedLayerId,
    pcgVolumes,
    setPcgVolumes,
    sceneShapes,
    setSceneShapes,
    selectedPcgVolume,
    setSelectedPcgVolume,
    sceneEntityTransforms,
    setSceneEntityTransforms,
    sceneObjectProperties,
    setSceneObjectProperties,
    selectedLayerId,
    setSelectedLayerId,
    sceneNumericId,
    setSceneNumericId,
    selectedVideoType,
    setSelectedVideoType,
    playbackWaiting,
    setPlaybackWaiting,
    onTipTapEditor,
    setOnTipTapEditor,
    selectedCameraKeyframeId,
    setSelectedCameraKeyframeId,
    cameraSelection,
    setCameraSelection,
  } = useContext(DesignEditorContext);
  return {
    scene,
    setScene,
    currentLayer,
    setCurrentLayer,
    currentKeyFrame,
    setCurrentKeyFrame,
    currentScriptComponentId,
    setCurrentScriptComponentId,
    currentShotId,
    setCurrentShotId,
    scalePoint,
    setScalePoint,
    displayPlayback,
    setDisplayPlayback,
    displayPreview,
    setDisplayPreview,
    playbackSeeked,
    setPlaybackSeeked,
    playbackVisibility,
    setPlaybackVisibility,
    videoEditorMode,
    setVideoEditorMode,
    forceReloadFrames,
    setForceReloadFrames,
    fullScreenMode,
    setFullScreenMode,
    screenShotTaken,
    setScreenShotTaken,
    addNewShot,
    setAddNewShot,
    addNewScene,
    setAddNewScene,
    addNewCameraSettings,
    setAddNewCameraSettings,
    maxTime,
    setMaxTime,
    reRenderState,
    reRenderTimeline,
    scaleSize,
    setScaleSize,
    reRenderClips,
    reRenderAllClips,
    updateScalePoint,
    updateScale,
    fitTimeline,
    setFitTimeline,
    scaleRenderingProgress,
    setScaleRenderingProgress,
    layersChanged,
    setLayersChanged,
    sceneParsedOrRendered,
    setSceneParsedOrRendered,
    layersAddedDeleted,
    setLayersAddedDeleted,
    deletedLayerId,
    setDeletedLayerId,
    pcgVolumes,
    setPcgVolumes,
    sceneShapes,
    setSceneShapes,
    selectedPcgVolume,
    setSelectedPcgVolume,
    sceneEntityTransforms,
    setSceneEntityTransforms,
    sceneObjectProperties,
    setSceneObjectProperties,
    selectedLayerId,
    setSelectedLayerId,
    sceneNumericId,
    setSceneNumericId,
    selectedVideoType,
    setSelectedVideoType,
    playbackWaiting,
    setPlaybackWaiting,
    onTipTapEditor,
    setOnTipTapEditor,
    selectedCameraKeyframeId,
    setSelectedCameraKeyframeId,
    cameraSelection,
    setCameraSelection,
  };
};

export default useDesignEditorContext;
