import { CircularProgress } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";

import { useVeltClient } from "@veltdev/react";
import { useRouter } from "next/router";
import { useState } from "react";

import Customizer from "src/@core/components/customizer";
import Icon from "src/@core/components/icon";
import ScrollToTop from "src/@core/components/scroll-to-top";
import { LayoutProps } from "src/@core/layouts/types";
import themeConfig from "src/configs/themeConfig";
import useProjectContext from "src/hooks/useProjectContext";

import AppBar from "./components/vertical/appBar";
import Navigation from "./components/vertical/navigation";

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  // minHeight: '100vh',
  flexDirection: "column",
  paddingBottom: "1rem",
});

const ContentWrapper = styled("main")(({ theme }) => ({
  flexGrow: 1,
  width: "100%",
  padding: theme.spacing(6),
  transition: "padding .25s ease-in-out",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const VerticalLayoutWrapper = styled("div")({
  display: "flex",
});

const VerticalLayout = (props: LayoutProps) => {
  // ** Props
  const {
    hidden,
    settings,
    children,
    scrollToTop,
    footerProps,
    contentHeightFixed,
    verticalLayoutProps,
  } = props;

  // ** Vars
  const { skin, navHidden, contentWidth } = settings;
  const { navigationSize, disableCustomizer, collapsedNavigationSize } =
    themeConfig;
  const navWidth = navigationSize;
  const navigationBorderWidth = skin === "bordered" ? 1 : 0;
  const collapsedNavWidth = collapsedNavigationSize;
  const router = useRouter();
  const { client } = useVeltClient();
  const { loadingScene } = useProjectContext();

  // ** States
  const [navVisible, setNavVisible] = useState<boolean>(false);

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible);

  return (
    <>
      <VerticalLayoutWrapper
        style={{ height: props.isTimelineVisible ? "80%" : "100%" }}
        className="layout-wrapper"
        onMouseUp={() => {
          client.disableFeatures([]);
        }}
      >
        {/* Navigation Menu */}
        {(navHidden && !(navHidden && settings.lastLayout === "horizontal")) ||
        router.pathname.includes("previs") ||
        router.pathname.includes("assets") ? null : (
          <Navigation
            navWidth={navWidth}
            navVisible={navVisible}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            navMenuContent={verticalLayoutProps.navMenu.content}
            navMenuBranding={verticalLayoutProps.navMenu.branding}
            menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
            verticalNavItems={verticalLayoutProps.navMenu.navItems}
            navMenuProps={verticalLayoutProps.navMenu.componentProps}
            menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
            afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
            beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
            {...props}
          />
        )}
        <MainContentWrapper
          className="layout-content-wrapper"
          sx={{ ...(contentHeightFixed && { maxHeight: "100vh" }) }}
        >
          {/* AppBar Component */}
          <AppBar
            toggleNavVisibility={toggleNavVisibility}
            appBarContent={verticalLayoutProps.appBar?.content}
            appBarProps={verticalLayoutProps.appBar?.componentProps}
            {...props}
          />

          {/* Content */}
          <ContentWrapper
            className="layout-page-content"
            sx={{
              ...(contentHeightFixed && {
                overflow: "hidden",
                "& > :first-of-type": { height: "100%" },
              }),
              ...(contentWidth === "boxed" && {
                mx: "auto",
                "@media (min-width:100%)": { maxWidth: "100%" },
                "@media (min-width:1200px)": { maxWidth: "100%" },
              }),
              padding: "12px 24px 0px 24px",
            }}
          >
            {loadingScene && (
              <>
                <div
                  style={{
                    position: "absolute",
                    width: "98%",
                    height: "99%",
                    background: "black",
                    opacity: "50%",
                    zIndex: 9999,
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    width: "98%",
                    height: "99%",
                    zIndex: 99999,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              </>
            )}
            {children}
          </ContentWrapper>

          {/* Footer Component */}
          {/* <Footer footerStyles={footerProps?.sx} footerContent={footerProps?.content} {...props} /> */}
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {/* Customizer */}
      {disableCustomizer || hidden ? null : <Customizer />}

      {/* Scroll to top button */}
      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <Icon icon="mdi:arrow-up" />
          </Fab>
        </ScrollToTop>
      )}
    </>
  );
};

export default VerticalLayout;
