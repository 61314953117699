const ReactionIcon = (props: any) => {
    const color = props.color ?? '#A7A7A7'

    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8_3355)">
                <path d="M0.273926 10.2045C0.52442 9.72429 0.953977 9.40931 1.3576 9.07829C3.06018 7.68228 4.99515 6.6829 7.02259 5.84928C8.39933 5.283 9.81521 4.84796 11.2966 4.64527C11.4731 4.61645 11.6512 4.59892 11.8299 4.59277C12.214 4.59277 12.463 4.79207 12.622 5.13232C13.2766 6.52882 13.943 7.92045 14.5927 9.3189C15.0457 10.2911 15.5492 11.2486 15.9137 12.2548C17.0879 15.5115 16.0115 19.2261 13.3275 21.4096C12.989 21.6852 12.5961 21.7858 12.1822 21.8388C8.35725 22.3292 4.9291 20.4617 3.2823 16.9853C2.28554 14.8842 1.29466 12.7807 0.30964 10.6751C0.30328 10.6614 0.287624 10.6522 0.276371 10.641L0.273926 10.2045ZM9.12929 18.3361L9.14298 18.2734C11.1205 18.2666 12.8852 17.0631 13.5428 15.2719C13.7018 14.8388 13.5305 14.4256 13.1284 14.2735C12.737 14.1243 12.3246 14.3036 12.166 14.7299C11.5736 16.3213 9.76139 17.2089 8.1004 16.6256C7.67524 16.4764 7.25694 16.681 7.12533 17.0655C6.98687 17.4689 7.19383 17.8918 7.63855 18.0153C8.12682 18.1495 8.63074 18.2316 9.12831 18.3361H9.12929ZM6.33275 11.8684C5.30533 11.8975 4.37968 12.5226 4.02155 13.4676C3.97316 13.5862 3.95177 13.714 3.95893 13.8418C3.99416 14.2049 4.24024 14.4451 4.59886 14.501C4.91198 14.5496 5.19819 14.3722 5.37138 14.0693C5.48098 13.8783 5.61307 13.6805 5.78186 13.5468C6.03627 13.3417 6.344 13.3397 6.6625 13.4321C7.11065 13.5619 7.49863 13.3485 7.62143 12.9285C7.73347 12.545 7.51477 12.1586 7.09108 12.0317C6.84304 11.9583 6.5852 11.9218 6.33275 11.8684ZM11.893 9.28973C10.8505 9.31841 9.94633 9.92163 9.57058 10.8695C9.4062 11.2831 9.56618 11.6992 9.95122 11.8601C10.3363 12.021 10.7379 11.8562 10.9331 11.444C11.1983 10.8841 11.6225 10.6891 12.2262 10.85C12.6431 10.9614 13.0477 10.7562 13.1631 10.3766C13.1938 10.282 13.2048 10.1822 13.1954 10.0832C13.186 9.98422 13.1564 9.8882 13.1084 9.80096C13.0605 9.71373 12.9951 9.63712 12.9164 9.5758C12.8376 9.51448 12.7471 9.46974 12.6504 9.4443C12.4043 9.37139 12.146 9.3398 11.893 9.28973Z" fill={color} />
                <path d="M15.5253 7.79504C15.8482 7.73331 16.1686 7.72213 16.4445 7.60644C16.7704 7.46552 17.0741 7.27855 17.3462 7.05134C17.6726 6.78351 17.6774 6.33681 17.4118 6.03349C17.3499 5.96077 17.274 5.90101 17.1886 5.85769C17.1032 5.81438 17.01 5.78836 16.9144 5.78117C16.8188 5.77398 16.7227 5.78575 16.6317 5.8158C16.5407 5.84585 16.4566 5.89358 16.3844 5.95621C16.1363 6.15939 15.8785 6.30716 15.5453 6.27216C15.4726 6.27165 15.4006 6.25712 15.3335 6.22938C14.9616 6.01794 14.5649 5.85316 14.3794 5.40305C14.1299 4.79983 13.8246 4.21945 13.5511 3.62547C13.3956 3.28521 13.1377 3.11849 12.7684 3.11654C12.4435 3.11654 12.1186 3.11654 11.7967 3.11654C11.6499 2.33056 11.8588 1.92517 12.5027 1.7147C13.9015 1.2593 15.3576 1.00111 16.8286 0.947667C17.9563 0.90392 19.0889 0.904406 20.2171 0.947667C21.6986 1.00502 23.1555 1.24758 24.5646 1.72539C25.0988 1.90718 25.275 2.15411 25.275 2.71213C25.275 4.97595 25.275 7.23929 25.275 9.50214C25.275 13.3606 22.7769 16.6149 19.0366 17.6488C18.5194 17.7917 18.0439 17.6819 17.5346 17.4957C17.9595 15.8827 17.9719 14.1897 17.5708 12.5707C18.1164 12.397 18.6996 12.3753 19.2567 12.508C19.8151 12.6331 20.3269 12.9115 20.7338 13.3115C20.9632 13.5332 21.2284 13.6221 21.5356 13.523C22.0792 13.3465 22.2093 12.6874 21.7803 12.2674C21.1244 11.6187 20.2835 11.1862 19.3717 11.0284C18.6821 10.8981 17.9714 10.928 17.2953 11.1159C17.1833 11.147 17.0996 11.1708 17.0277 11.0187C16.5483 9.97752 16.0566 8.94169 15.5693 7.90441C15.5512 7.87038 15.5399 7.83149 15.5253 7.79504ZM21.7773 7.74886C22.4094 7.75226 22.9691 7.52915 23.4437 7.09606C23.7823 6.78789 23.8155 6.32903 23.5303 6.01842C23.2451 5.70782 22.8018 5.70345 22.4603 5.99218C21.997 6.38396 21.5224 6.38104 21.0576 5.98975C20.7186 5.70296 20.2656 5.71609 19.9882 6.02086C19.7034 6.3334 19.7401 6.79129 20.0796 7.098C20.5387 7.52653 21.148 7.76006 21.7778 7.74886H21.7773Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_8_3355">
                    <rect width="25" height="21" fill="white" transform="translate(0.273926 0.916016)" />
                </clipPath>
            </defs>
        </svg>

    )
}
export default ReactionIcon