import { configureStore } from "@reduxjs/toolkit";

import storyboard from "src/store/apps/Storyboard";
import calendar from "src/store/apps/calendar";
import chat from "src/store/apps/chat";
import email from "src/store/apps/email";
import invoice from "src/store/apps/invoice";
import permissions from "src/store/apps/permissions";
import user from "src/store/apps/user";

export const store = configureStore({
  reducer: {
    user,
    chat,
    email,
    invoice,
    calendar,
    permissions,
    storyboard,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
