import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import {
  AddEventType,
  CalendarFiltersType,
  EventType,
} from "src/types/apps/calendarTypes";

// ** Fetch Events
export const fetchEvents = createAsyncThunk(
  "appCalendar/fetchEvents",
  async (calendars: CalendarFiltersType[]) => {
    const response = await axios.get("/apps/calendar/events", {
      params: {
        calendars,
      },
    });

    return response.data;
  },
);

// ** Add Event
export const addEvent = createAsyncThunk(
  "appCalendar/addEvent",
  async (event: AddEventType, { dispatch }) => {
    const response = await axios.post("/apps/calendar/add-event", {
      data: {
        event,
      },
    });
    await dispatch(
      fetchEvents(["Personal", "Business", "Family", "Holiday", "ETC"]),
    );

    return response.data.event;
  },
);

// ** Update Event
export const updateEvent = createAsyncThunk(
  "appCalendar/updateEvent",
  async (event: EventType, { dispatch }) => {
    const response = await axios.post("/apps/calendar/update-event", {
      data: {
        event,
      },
    });
    await dispatch(
      fetchEvents(["Personal", "Business", "Family", "Holiday", "ETC"]),
    );

    return response.data.event;
  },
);

// ** Delete Event
export const deleteEvent = createAsyncThunk(
  "appCalendar/deleteEvent",
  async (id: number | string, { dispatch }) => {
    const response = await axios.delete("/apps/calendar/remove-event", {
      params: { id },
    });
    await dispatch(
      fetchEvents(["Personal", "Business", "Family", "Holiday", "ETC"]),
    );

    return response.data;
  },
);

export const appCalendarSlice = createSlice({
  name: "appCalendar",
  initialState: {
    events: [],
    selectedEvent: null,
    selectedCalendars: ["Personal", "Business", "Family", "Holiday", "ETC"],
  },
  reducers: {
    handleSelectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    handleCalendarsUpdate: (state, action) => {
      const filterIndex = state.selectedCalendars.findIndex(
        (i) => i === action.payload,
      );
      if (state.selectedCalendars.includes(action.payload)) {
        state.selectedCalendars.splice(filterIndex, 1);
      } else {
        state.selectedCalendars.push(action.payload);
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0;
      }
    },
    handleAllCalendars: (state, action) => {
      const value = action.payload;
      if (value === true) {
        state.selectedCalendars = [
          "Personal",
          "Business",
          "Family",
          "Holiday",
          "ETC",
        ];
      } else {
        state.selectedCalendars = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events = action.payload;
    });
  },
});
export const { handleSelectEvent, handleCalendarsUpdate, handleAllCalendars } =
  appCalendarSlice.actions;

export default appCalendarSlice.reducer;
