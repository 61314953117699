import { SubscriptionStatus } from "@prisma/client";
import axios from "axios";

import { IScene } from "src/@core/context/DesignEditorContext/scene";

import httpInterceptInstance from "./httpIntercept";

export type PxApiError = {
  error: string;
};

/**
 * Minimal information about a project for displaying it to the user or
 * navigating into the project.
 *
 * Deliberately does not contain scene information besides a preview of the
 * opening scene, to reduce data transfer. If scene information is required,
 * query the specific project or scene directly.
 */
export type ProjectDetails = {
  id: string;
  name: string;
  description?: string;
  backgroundImage?: string;
  openingScenePreview?: string;

  sceneIds: string[];

  createdAt: Date;
  updatedAt: Date;
  renderedDuration: number;

  account: any;
  users: any[];
  creditsUsed: number;

  isTemplate: boolean;
  isDefaultTemplate: boolean;
  isDemoProject: boolean;
};

export const requestMagicLink = async (email: string) => {
  const res = await axios.post(process.env.MAGIC_LINK_API_ENDPOINT || "", {
    email: email,
    magicLinkType: "self-sign-in",
  });
  return res.data;
};

export const verifyAttendeeByMagicLink = async (email: string) => {
  const res = await axios.post(process.env.MAGIC_LINK_API_ENDPOINT || "", {
    email: email,
    magicLinkType: "verify-attendee",
  });
  return res.data;
};

export const inviteUserInProject = async (
  fromUserEmail: string,
  email: string,
  roleId: number,
  projectId: number,
  sceneId: number,
) => {
  const res = await axios.post(process.env.MAGIC_LINK_API_ENDPOINT || "", {
    email: email,
    magicLinkType: "user-invitation",
    fromUserEmail: fromUserEmail,
    roleId: roleId,
    projectId: projectId,
    sceneId: sceneId,
  });
  return res.data;
};

export const textImage = async (dataUrl: string) => {
  return await axios.get(dataUrl);
};

export const getProjectDetails = async (projectId: string) => {
  const res = await httpInterceptInstance.get("/api/projects/" + projectId);
  return res.data;
};

export const getSceneDetails = async (projectId: string, scene_id: string) => {
  const res = await httpInterceptInstance.get(
    "/api/projects/" + projectId + "/scene/" + scene_id,
  );
  return res.data;
};

export const getAllRoles = async () => {
  const res = await httpInterceptInstance.get("/api/roles");
  return res.data;
};

export const getLoggedInUser = async () => {
  const res = await httpInterceptInstance.get("/api/getUserDetails");
  return res.data;
};

export const addRenderRequestToQueue = async (renderDetails: any) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/addRenderRequestToQueue",
    renderDetails,
  );
  return res.data;
};

export const withdrawRenderRequestToQueue = async (sceneId: number) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/withdrawRenderRequestToQueue",
    { sceneId },
  );
  return res.data;
};

export const updateSceneStatus = async (sceneId: number, status: string) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/updateStatus",
    {
      sceneId,
      status,
    },
  );
  return res.data;
};

export const createNewProject = async (projectDetails: any) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/createNew",
    projectDetails,
  );
  return res.data;
};

export const editProjectNameDescription = async (projectDetails: any) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/editDetails",
    projectDetails,
  );
  return res.data;
};

export const getRenderRequests = async (queryData: any) => {
  const res = await httpInterceptInstance.get("/api/getRenderRequests", {
    params: queryData,
  });
  return res.data;
};

export const getSceneHDRBackgroundRequests = async () => {
  const res = await httpInterceptInstance.get(
    "/api/getSceneHDRBackgroundRequests",
  );
  return res.data;
};

export const getSuperAdminDashboard = async (queryData: any) => {
  const res = await httpInterceptInstance.get("/api/getSuperAdminDashboard", {
    params: queryData,
  });
  return res.data;
};

export const getCandidateJobApplications = async () => {
  const res = await httpInterceptInstance.get(
    "/api/getCandidateJobApplications",
  );
  return res.data;
};

export const getAssetsRequests = async (queryData: any) => {
  const res = await httpInterceptInstance.get("/api/getAssetsRequests", {
    params: queryData,
  });
  return res.data;
};

export const updateScene = async (scene: IScene) => {
  const res = await httpInterceptInstance.post("/api/projects/scenes/update", {
    ...scene,
    pcgGeneratedAssets: [],
  });
  return res.data;
};

export const renderVideo = async (sceneId: number) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/exportVideo",
    { sceneId: sceneId },
  );
  return res.data;
};

export const renderScreenPlay = async (
  screenComponentId: string,
  sceneId: number,
  dimensions: any,
  enableRotation: boolean,
  mode: string,
  assetTagFilter: string,
  isLLMEnabled: boolean,
) => {
  const res = await httpInterceptInstance.post("/api/projects/scenes/render", {
    screenComponentId,
    sceneId,
    dimensions,
    enableRotation,
    mode,
    assetTagFilter,
    isLLMEnabled,
  });
  return res.data;
};

export const generateVideoForCameraShotCurve = async (
  sceneId: number,
  cameraShotCurveId: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/generateVideoForCameraShotCurve",
    { sceneId, cameraShotCurveId },
  );
  return res.data;
};

export type PrevisDetailsRequest = {
  sceneId: string;
};

export type PrevisDetailsResponse = {
  /**
   * URL of the glTF file produced by `scriptviz:packageScene` that contains
   * the mesh data for the background and all exportable objects.
   */
  glbFileURL: string;
};

export const previsDetails = async (request: PrevisDetailsRequest) => {
  const res = await httpInterceptInstance.get<
    PrevisDetailsResponse | PxApiError
  >("/api/projects/scenes/previs", {
    params: { sceneId: request.sceneId },
  });
  return res.data;
};

export const storyboardDetails = async (sceneId: string) => {
  const res = await httpInterceptInstance.get(
    "/api/projects/scenes/storyboard/details",
    { params: { sceneId } },
  );
  return res.data;
};

export const publishVideoForCameraShotCurve = async (sceneId: number) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/generateVideoForAllCameraShotCurves",
    { sceneId },
  );
  return res.data;
};

export const getStripeCheckoutSessionId = async (planId: number) => {
  const res = await httpInterceptInstance.post("/api/stripe/generateSession", {
    planId: planId,
  });
  return res.data;
};

export const confirmPayment = async (sessionId: string) => {
  const res = await httpInterceptInstance.post("/api/stripe/confirmPayment", {
    sessionId: sessionId,
  });
  return res.data;
};

export const getExportedUsdFile = async (sceneId: number) => {
  return await httpInterceptInstance.post(
    "/api/projects/scenes/getExportedUsdFile",
    {
      sceneId,
    },
  );
};

export const getRPMAvatars = async (sceneId: string) => {
  const res = await httpInterceptInstance.get(
    "/api/projects/scenes/characters?sceneId=" + sceneId,
  );
  return res.data;
};

export const updateRPMAvatarURL = async (
  sceneId: number,
  characterId: any,
  readyMePlayerURL: string,
  actorName: string,
  currentDataUid: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/characters/update",
    {
      sceneId,
      characterId,
      readyMePlayerURL,
      actorName,
      currentDataUid,
    },
  );
  return res.data;
};

export const updateSceneTime = async (sceneId: number, sceneTime: any) => {
  const res = await httpInterceptInstance.put(
    "/api/projects/scenes/updateTime",
    {
      sceneId,
      sceneTime,
    },
  );
  return res.data;
};

export type ProjectsResponse = {
  isSubscribed: boolean;
  subscriptionStatus: SubscriptionStatus;
  isProfileCompleted: boolean;
  projects: ProjectDetails[];
};

export const projects = async () => {
  const res =
    await httpInterceptInstance.post<ProjectsResponse>("/api/projects");
  return res.data;
};

type StoryboardUpdateItem = {
  /**
   * ID of the scene to update.
   *
   * This is a string to keep API stability.
   */
  sceneId: string;

  /**
   * ID of the shot to update.
   *
   * This is a string to keep API stability.
   */
  shotId: string;

  /**
   * Name of the image file to upload.
   *
   * This is technically unnecessary as this information is available in `file`,
   * but it is kept for API stability.
   */
  fileName: string;

  /**
   * Image file to use as a screenshot of the shot in the scene.
   */
  file: File;

  /**
   * S3 Object URL of the uploaded screenshot given in `file`.
   */
  screenshotImageUrl?: string;
};
const storyboardUpdateQueue: StoryboardUpdateItem[] = [];
let storyboardUpdateProcessing = false;

export const updateStoryBoard = async (
  sceneId: string,
  shotId: string,
  fileName: string,
  file: File,
) => {
  storyboardUpdateQueue.push({ sceneId, shotId, fileName, file });

  if (!storyboardUpdateProcessing) {
    processStoryboardUpdateQueue();
  }
};

const processStoryboardUpdateQueue = async () => {
  storyboardUpdateProcessing = true;

  while (storyboardUpdateQueue.length > 0) {
    const item = storyboardUpdateQueue.shift()!;
    if (item.sceneId) {
      const uploadUrlResp = await createStoryboardScreenshotUploadUrl({
        sceneId: item.sceneId,
        fileName: item.fileName,
        contentType: item.file.type,
      });
      // External request, so should not have the extra headers.
      await axios.put(uploadUrlResp.uploadUrl, item.file, {
        headers: uploadUrlResp.headers,
      });
      item.screenshotImageUrl = uploadUrlResp.destObjectUrl;
      await httpInterceptInstance.post(
        "/api/projects/scenes/storyboard/update",
        item,
      );
    }
  }

  storyboardUpdateProcessing = false;
};

export const createNewShot = async (sceneId: string) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/shots/new",
    {
      sceneId,
    },
  );
  return res.data;
};

export const createNewCameraSettings = async (
  sceneId: string,
  scriptComponentId: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/shots/newCameraSettings",
    {
      sceneId,
      scriptComponentId,
    },
  );
  return res.data;
};

export const updateShotName = async (shotId: string, shotName: string) => {
  const res = await httpInterceptInstance.put(
    "/api/projects/scenes/shots/updateName",
    {
      shotId,
      shotName,
    },
  );
  return res.data;
};

export const updateScript = async (sceneId: string, script: string) => {
  const res = await httpInterceptInstance.put(
    "/api/projects/scenes/updateScript",
    {
      sceneId,
      script,
    },
  );
  // setTimeout(() => {
  //   store.dispatch(setSceneScript(script));
  // }, 3000);
  return res.data;
};

export const updateCameraSettings = async (
  shotId: number,
  camera_shot: any,
) => {
  const res = await httpInterceptInstance.put(
    "/api/projects/scenes/shots/updateCameraSettings",
    {
      shotId,
      camera_shot,
    },
  );
  return res.data;
};

export const deleteProject = async (projectId: number) => {
  try {
    const response = await httpInterceptInstance.post("/api/projects/delete", {
      projectId,
    });
  } catch (error) {
    console.error("Error deleting project:", error);
  }
};

export const createNewScene = async (projectId: number) => {
  const res = await httpInterceptInstance.post("/api/projects/scenes/addNew", {
    projectId,
  });
  return res.data;
};

export const subscribeStripeYearlyPlan = async () => {
  const res = await httpInterceptInstance.post("/api/stripe/subscribe");
  return res.data;
};

export const updateSubscription = async (sessionId: string) => {
  const res = await httpInterceptInstance.post(
    "/api/stripe/updateSusbscription",
    {
      sessionId,
    },
  );
  return res.data;
};

export const getTopupPlanCheckoutUrl = async () => {
  const res = await httpInterceptInstance.post(
    "/api/stripe/createTopupPlanSession",
    {},
  );
  return res.data;
};

export const updateStoryboardOrder = async (updatedItems: any) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/storyboard/updateOrder",
    { updatedItems },
  );
  return res.data;
};

export const getSubscriptionDetails = async () => {
  const res = await httpInterceptInstance.get(
    "/api/stripe/getSusbscriptionDetails",
  );
  return res.data;
};

export const updateUserAccount = async (
  firstName: string,
  lastName: string,
  accountName: string,
  lastUsedAccountId: number,
) => {
  const res = await httpInterceptInstance.put("/api/accounts/update", {
    firstName,
    lastName,
    accountName,
    lastUsedAccountId,
  });
  return res.data;
};

export const updateUserProfile = async (
  firstName: string,
  lastName: string,
  organizationName: string,
  designation: string,
  country: string,
  imdbProfileLink: string,
  source: string,
  lastUsedAccountId: number,
  profilePictureLink: string,
) => {
  const res = await httpInterceptInstance.put("/api/users/updateProfile", {
    firstName,
    lastName,
    organizationName,
    designation,
    country,
    imdbProfileLink,
    source,
    lastUsedAccountId,
    profilePictureLink,
  });
  return res.data;
};

export const deactivateSubscription = async () => {
  const res = await httpInterceptInstance.delete("/api/accounts/deactivate");
  return res.data;
};

export const updateHdrBackgroundImageUrl = async (
  sceneHdrBackgroundId: number,
  s3ImageUrl: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/updateHdrBackgroundImages",
    {
      sceneHdrBackgroundId,
      s3ImageUrl,
    },
  );
  return res.data;
};

export const updateDepthBackgroundUrl = async (
  sceneHdrBackgroundId: number,
  s3ImageUrl: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/updateHdrDepthImages",
    {
      sceneHdrBackgroundId,
      s3ImageUrl,
    },
  );
  return res.data;
};

export const updateHdrBackgroundJson = async (
  sceneHdrBackgroundId: number,
  backgroundObjectsConfiguration: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/updateHdrBackgroundJson",
    {
      sceneHdrBackgroundId,
      backgroundObjectsConfiguration,
    },
  );
  return res.data;
};

export const updateDepthDataJson = async (
  sceneHdrBackgroundId: number,
  depthJsonData: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/updateDepthDataJson",
    {
      sceneHdrBackgroundId,
      depthJsonData,
    },
  );
  return res.data;
};

export const updateHdrShaString = async (
  sceneHdrBackgroundId: number,
  generatedSha512Digest: string,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/updateHdrShaString",
    {
      sceneHdrBackgroundId,
      generatedSha512Digest,
    },
  );
  return res.data;
};

export const updateHdrBackgroundDisplayMask = async (
  sceneHdrBackgroundId: number,
  displayMask: number,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/updateHdrBackgroundDisplayMask",
    {
      sceneHdrBackgroundId,
      displayMask,
    },
  );
  return res.data;
};

export const renderHdrBackgroundImageRequest = async (
  sceneHdrBackgroundId: number,
  status: string,
  assetTagFilter: string,
) => {
  const res = await httpInterceptInstance.put(
    "/api/projects/scenes/renderHdrBackgroundImageRequest",
    {
      sceneHdrBackgroundId,
      status,
      assetTagFilter,
    },
  );
  return res.data;
};

export const completeRenderingWithHdrBackgrounds = async (
  sceneHdrBackgroundId: number,
) => {
  const res = await httpInterceptInstance.put(
    "/api/projects/scenes/completeRenderingWithHdrBackground",
    {
      sceneHdrBackgroundId,
    },
  );
  return res.data;
};

export const generateDepthBackgroundMesh = async (
  sceneHdrBackgroundId: number,
) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/generateDepthBackgroundMesh",
    {
      sceneHdrBackgroundId,
    },
  );
  return res.data;
};

export const updateStoryboardThumbnailName = async (updatedItem: any) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/storyboard/updateName",
    { updatedItem },
  );
  return res.data;
};

export const importScript = async (projectId: string, fdxFileUrl: string) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/importScript",
    {
      projectId,
      fdxFileUrl,
    },
  );
  return res.data;
};

export const importGlb = async (sceneId: string, glbFileUrl: string) => {
  const res = await httpInterceptInstance.post(
    "/api/projects/scenes/importGlb",
    {
      sceneId,
      glbFileUrl,
    },
  );
  return res.data;
};

export const requestForCancellation = async (subscriptionId: number) => {
  const res = await httpInterceptInstance.post(
    "/api/subscriptions/request-cancellation",
    { subscriptionId },
  );
  return res.data;
};

export const approveCancellation = async (subscriptionId: number) => {
  const res = await httpInterceptInstance.post(
    "/api/subscriptions/approve-cancellation",
    { subscriptionId },
  );
  return res.data;
};

export const activateAccount = async (accountId: number) => {
  const res = await httpInterceptInstance.post("/api/activateAccount", {
    accountId,
  });
  return res.data;
};

export const deactivateAccount = async (accountId: number, comment: string) => {
  const res = await httpInterceptInstance.post("/api/deactivateAccount", {
    accountId,
    comment,
  });
  return res.data;
};

export const updateAutoGenerateSkyBoxImage = async (
  featureId: number,
  autoGenerateSkyBoxImage: boolean,
) => {
  const res = await httpInterceptInstance.post(
    "/api/features/updateAutoGenerateSkyBoxImage",
    {
      featureId: featureId,
      autoGenerateSkyBoxImage: autoGenerateSkyBoxImage,
    },
  );
  return res.data;
};

export const createAutoGenerateSkyBoxImage = async (
  autoGenerateSkyBoxImage: any,
) => {
  const res = await httpInterceptInstance.post(
    "/api/features/createAutoGenerateSkyBoxImage",
    {
      featureId: null,
      autoGenerateSkyBoxImage: autoGenerateSkyBoxImage,
    },
  );
  return res.data;
};

export const getFeatureId = async () => {
  try {
    const response = await httpInterceptInstance.get(
      "/api/features/getFeature",
    );
    const featureId = response.data.data.id;

    return featureId;
  } catch (error) {
    console.error("Error getting feature ID:", error);
    return null;
  }
};

export const updatePcgVolumes = async (sceneId: string, pcgVolumes: any) => {
  const res = await httpInterceptInstance.patch(
    "/api/projects/scenes/updatePcgVolumes",
    {
      sceneId,
      pcgVolumes,
    },
  );
  return res.data;
};

export const updateSceneShapes = async (sceneId: string, sceneShapes: any) => {
  const res = await httpInterceptInstance.patch(
    "/api/projects/scenes/updateSceneShapes",
    {
      sceneId,
      sceneShapes,
    },
  );
  return res.data;
};

export const updateSceneEntityTransforms = async (
  sceneId: string,
  sceneEntityTransforms: any,
) => {
  const res = await httpInterceptInstance.patch(
    "/api/projects/scenes/updateSceneEntityTransforms",
    {
      sceneId,
      sceneEntityTransforms,
    },
  );
  return res.data;
};

export const updateSceneObjectProperties = async (
  sceneId: string,
  sceneObjectProperties: any,
) => {
  const res = await httpInterceptInstance.patch(
    "/api/projects/scenes/updateSceneObjectProperties",
    {
      sceneId,
      sceneObjectProperties,
    },
  );
  return res.data;
};

export const updateSceneObjectAssetTagFilter = async (
  sceneId: string,
  assetTagFilter: string,
) => {
  const res = await httpInterceptInstance.patch(
    "/api/projects/scenes/updateObjectAssetTagFilter",
    {
      sceneId,
      assetTagFilter,
    },
  );
  return res.data;
};

export const createNewAsset = async (createAsset: any) => {
  const res = await httpInterceptInstance.post("/api/createAsset", createAsset);
  return res.data;
};

export const deleteAsset = async (assetData: any) => {
  const res = await httpInterceptInstance.delete("/api/deleteAsset", {
    params: assetData,
  });
  return res.data;
};

export type SearchAssetsRequest = {
  projectId: string;
  query: string;
  sceneId: string;
  pageSize?: number;
};

export type SearchAssetsResponse = {
  assets: VisualizerAsset[];
};

export type VisualizerAsset = {
  display_name: string;
  source_uri: string;
  status: string;
  uuid: string;
  uid: string;
  userData: {
    uid: string;
    humanizedName: string;
    type: string;
  };
  assetCollectionId: string;
};

export const searchAssets = async (request: SearchAssetsRequest) => {
  const res = await httpInterceptInstance.get<SearchAssetsResponse>(
    "/api/searchAssets",
    {
      params: request,
    },
  );
  return res.data;
};

export const updateAssetPermissionLevel = async (request: any) => {
  const res = await httpInterceptInstance.put(
    "/api/updateAssetPermissionLevel",
    request,
  );
  return res.data;
};

export const updateAssetsAssignee = async (request: any) => {
  const res = await httpInterceptInstance.put(
    "/api/updateAssetsAssignee",
    request,
  );
  return res.data;
};

export const getUploadedAssets = async (request: any) => {
  const res = await httpInterceptInstance.get("/api/getUploadedAssets", {
    params: request,
  });
  return res.data;
};

export const addAssetToSceneAssetCollection = async (createAsset: any) => {
  const res = await httpInterceptInstance.put(
    "/api/addAssetToSceneAssetCollection",
    createAsset,
  );
  return res.data;
};

export const usdzToGlb = async (usdzData: any) => {
  const res = await httpInterceptInstance.post("/api/usdzToGlb", usdzData);
  return res.data;
};

export const addCommonAssetToCollection = async (createAsset: any) => {
  const res = await httpInterceptInstance.put(
    "/api/addCommonAssetToCollection",
    createAsset,
  );
  return res.data;
};

export const generateVeltAuthToken = async (projectId: number) => {
  const res = await httpInterceptInstance.get("/api/generateVeltAuthToken", {
    params: { projectId },
  });
  return res.data;
};

/**
 * Base response type for methods returning an AWS S3 presigned URL.
 */
export type CreatePresignedUrlResponse = {
  /**
   * AWS S3 presigned URL to upload the object to using a PUT request.
   */
  uploadUrl: string;

  /**
   * Additional headers to pass to the PUT.
   *
   * These must be included in the PUT request otherwise the authentication
   * signature will not match and the request will be rejected.
   */
  headers: { [header: string]: string };

  /**
   * AWS S3 URI of the object after it is uploaded.
   */
  destUri: string;

  /**
   * AWS S3 Object URL of the object after it is uploaded.
   *
   * TRANSITION: Provided for compatibility with existing callers.
   */
  destObjectUrl: string;
};

export type CreateAssetUploadUrlRequest = {
  /**
   * ID of the asset resource that will be backed by the uploaded asset data.
   */
  assetId: string;

  /**
   * File extension including the dot, e.g. `.jpg`.
   */
  fileExtension: string;
};

export type CreateAssetUploadUrlResponse = CreatePresignedUrlResponse & {};

/**
 * Return a presigned URL for uploading an asset file to AWS S3.
 */
export const createAssetUploadUrl = async (
  request: CreateAssetUploadUrlRequest,
) => {
  const res = await httpInterceptInstance.post<CreateAssetUploadUrlResponse>(
    "/api/createAssetUploadUrl",
    request,
  );
  return res.data;
};

export type CreateAudioUploadUrlRequest = {
  /**
   * ID of the project that the audio file is being added to.
   *
   * The currently authenticated user must have write access to the project.
   */
  projectId: number;

  /**
   * Name of the file being uploaded.
   */
  fileName: string;

  /**
   * Content type of the file being uploaded.
   */
  contentType: string;
};

export type CreateAudioUploadUrlResponse = CreatePresignedUrlResponse & {};

/**
 * Return a presigned URL for uploading audio data for the timeline to S3.
 */
export const createAudioUploadUrl = async (
  request: CreateAudioUploadUrlRequest,
) => {
  const res = await httpInterceptInstance.post<CreateAudioUploadUrlResponse>(
    "/api/createAudioUploadUrl",
    request,
  );
  return res.data;
};

export type CreateImageUploadUrlRequest = {
  /**
   * ID of the project that the image file is being added to.
   *
   * The currently authenticated user must have write access to the project.
   */
  projectId: number;

  /**
   * Name of the file being uploaded.
   */
  fileName: string;

  /**
   * Content type of the file being uploaded.
   */
  contentType: string;
};

export type CreateImageUploadUrlResponse = CreatePresignedUrlResponse & {};

/**
 * Return a presigned URL for uploading image data for the timeline to S3.
 */
export const createImageUploadUrl = async (
  request: CreateImageUploadUrlRequest,
) => {
  const res = await httpInterceptInstance.post<CreateImageUploadUrlResponse>(
    "/api/createImageUploadUrl",
    request,
  );
  return res.data;
};

export type CreateSceneImportUploadUrlRequest = {
  /**
   * ID of the project that the scene will be imported into, using the uploaded
   * file.
   *
   * The currently authenticated user must have write access to the project.
   */
  projectId: number;

  /**
   * Name of the file being uploaded.
   */
  fileName: string;

  /**
   * Content type of the file being uploaded.
   *
   * Supports `model/gltf-binary` and `application/x-fdx`.
   */
  contentType: string;
};

export type CreateSceneImportUploadUrlResponse =
  CreatePresignedUrlResponse & {};

/**
 * Return a presigned URL for uploading FDX or glTF files, that are used to
 * import an existing scene into the project.
 */
export const createSceneImportUploadUrl = async (
  request: CreateSceneImportUploadUrlRequest,
) => {
  const res =
    await httpInterceptInstance.post<CreateSceneImportUploadUrlResponse>(
      "/api/createSceneImportUploadUrl",
      request,
    );
  return res.data;
};

export type CreateStoryboardScreenshotUploadUrlRequest = {
  /**
   * ID of the scene containing the shot that the screenshot is for.
   *
   * The currently authenticated user must have write access to the scene.
   */
  sceneId: string;

  /**
   * Name of the file being uploaded.
   */
  fileName: string;

  /**
   * Content type of the file being uploaded.
   */
  contentType: string;
};

export type CreateStoryboardScreenshotUploadUrlResponse =
  CreatePresignedUrlResponse & {};

/**
 * Return a presigned URL for uploading a screenshot of a shot in a scene.
 */
export const createStoryboardScreenshotUploadUrl = async (
  request: CreateStoryboardScreenshotUploadUrlRequest,
) => {
  const res =
    await httpInterceptInstance.post<CreateStoryboardScreenshotUploadUrlResponse>(
      "/api/createStoryboardScreenshotUploadUrl",
      request,
    );
  return res.data;
};

export type CreateHdrBackgroundImageUploadUrlRequest = {
  /**
   * Name of the file being uploaded.
   */
  fileName: string;

  /**
   * Content type of the file being uploaded.
   */
  contentType: string;
};

export type CreateHdrBackgroundImageUploadUrlResponse =
  CreatePresignedUrlResponse & {};

/**
 * Return a presigned URL for uploading an HDR background image to be used for
 * HDR background generation.
 *
 * The current authenticated user must be a superuser.
 */
export const createHdrBackgroundImageUploadUrl = async (
  request: CreateHdrBackgroundImageUploadUrlRequest,
) => {
  const res =
    await httpInterceptInstance.post<CreateHdrBackgroundImageUploadUrlResponse>(
      "/api/createHdrBackgroundImageUploadUrl",
      request,
    );
  return res.data;
};

export type CreateProfilePictureUploadUrlRequest = {
  /**
   * ID of the user that the profile picture is for.
   *
   * This must be the current authenticated user.
   */
  userId: number;

  /**
   * Name of the file being uploaded.
   */
  fileName: string;

  /**
   * Content type of the file being uploaded.
   */
  contentType: string;
};

export type CreateProfilePictureUploadUrlResponse =
  CreatePresignedUrlResponse & {};

/**
 * Return a presigned URL for uploading a profile picture.
 */
export const createProfilePictureUploadUrl = async (
  request: CreateProfilePictureUploadUrlRequest,
) => {
  const res =
    await httpInterceptInstance.post<CreateProfilePictureUploadUrlResponse>(
      "/api/createProfilePictureUploadUrl",
      request,
    );
  return res.data;
};
