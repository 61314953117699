const BracketIcon = (props: any) => {
    const color = props.color ?? 'white'
    return (
        <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2869 2.53393H10.1969V1.9721C10.1969 1.04273 9.44075 0.286621 8.51139 0.286621H6.26408C5.33471 0.286621 4.5786 1.04273 4.5786 1.9721V2.53393H1.48855C0.714073 2.53393 0.0839844 3.16402 0.0839844 3.9385V5.90489C0.0839844 6.21516 0.335542 6.46672 0.645811 6.46672H0.95285L1.43823 16.6598C1.48111 17.56 2.22061 18.2651 3.12178 18.2651H11.6537C12.5549 18.2651 13.2944 17.56 13.3372 16.6598L13.8226 6.46672H14.1297C14.4399 6.46672 14.6915 6.21516 14.6915 5.90489V3.9385C14.6915 3.16402 14.0614 2.53393 13.2869 2.53393ZM5.70225 1.9721C5.70225 1.66232 5.9543 1.41027 6.26408 1.41027H8.51139C8.82116 1.41027 9.07321 1.66232 9.07321 1.9721V2.53393H5.70225V1.9721ZM1.20764 3.9385C1.20764 3.78361 1.33366 3.65758 1.48855 3.65758H13.2869C13.4418 3.65758 13.5678 3.78361 13.5678 3.9385V5.34306C13.3947 5.34306 1.92513 5.34306 1.20764 5.34306V3.9385ZM12.2148 16.6063C12.2006 16.9064 11.9541 17.1414 11.6537 17.1414H3.12178C2.82138 17.1414 2.57488 16.9064 2.56062 16.6063L2.07777 6.46672H12.6977L12.2148 16.6063Z" fill={color}/>
        <path d="M7.38849 16.018C7.69876 16.018 7.95031 15.7664 7.95031 15.4562V8.1524C7.95031 7.84213 7.69876 7.59058 7.38849 7.59058C7.07822 7.59058 6.82666 7.84213 6.82666 8.1524V15.4562C6.82666 15.7664 7.07818 16.018 7.38849 16.018Z" fill={color}/>
        <path d="M10.1966 16.018C10.5069 16.018 10.7584 15.7664 10.7584 15.4562V8.1524C10.7584 7.84213 10.5069 7.59058 10.1966 7.59058C9.88632 7.59058 9.63477 7.84213 9.63477 8.1524V15.4562C9.63477 15.7664 9.88629 16.018 10.1966 16.018Z" fill={color}/>
        <path d="M4.57892 16.018C4.88919 16.018 5.14074 15.7664 5.14074 15.4562V8.1524C5.14074 7.84213 4.88919 7.59058 4.57892 7.59058C4.26865 7.59058 4.01709 7.84213 4.01709 8.1524V15.4562C4.01709 15.7664 4.26861 16.018 4.57892 16.018Z" fill={color}/>
        </svg>

    )
}
export default BracketIcon