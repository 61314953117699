import {
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  Fade,
  FadeProps,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import React, {
  forwardRef,
  Fragment,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import Icon from "src/@core/components/icon";
import { useSettings } from "src/@core/hooks/useSettings";
import useDesignEditorContext from "src/hooks/useDesignEditorContext";

import { CloseIcon } from "../../../public/images/pages/icons/Icons";
import { getAllRoles } from "../../config/api";
import { useAuth } from "../../config/auth";
import useProjectContext from "../../hooks/useProjectContext";

interface DataType {
  name: string;
  email: string;
  value: string;
  avatar: string;
}

interface OptionsType {
  name: string;
  avatar: string;
}

const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

const schema = yup.object().shape({
  email: yup.string().email().required(),
  role: yup.string().required(),
});

const defaultValues = {
  email: "",
  role: [],
};

const ShareProject = (props: any) => {
  const [disableButton, setButtonVisibility] = useState(false);
  const [roles, setRoles] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [msgSent, setMsgSent] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { settings } = useSettings();
  const { direction } = settings;
  const { userInvite, userData } = useAuth();
  const { project, projectRoles } = useProjectContext();
  const { scene } = useDesignEditorContext();
  const [invitations, setInvitations] = useState(project.UserInvitation || []);
  const [reinvitedUserEmail, setReinvitedUserEmail] = useState(null);
  const [showReinviteSuccess, setShowReinviteSuccess] = useState(false);
  const [reinvitingEmail, setReinvitingEmail] = useState(null);

  const router = useRouter();
  const { id, scene_id } = router.query;

  useEffect(() => {
    (async function () {
      const rolesData = await getAllRoles();
      setRoles(rolesData.roles);
    })();
  }, []);

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values: any) => {
    try {
      // Check if the email is already in use by an invited user or added user
      const emailInUseMessage = invitations.some(
        (inv) => inv.toEmail === values.email,
      )
        ? "User with this email has already been invited."
        : project?.users.some((user) => user.user.email === values.email)
          ? "User with this email has already been added."
          : "";

      if (emailInUseMessage) {
        setError("email", { type: "manual", message: emailInUseMessage });
        return;
      }

      setButtonVisibility(true);
      if (
        await userInvite({
          email: values.email,
          roleId: values.role,
          projectId: id,
          sceneId: scene_id,
        })
      ) {
        setButtonVisibility(false);
        reset();
        setMsgSent(true);
        const newInvitation = {
          toEmail: values.email,
          role: roles.find((role) => role.id == values.role), // find role object from roles state
        };
        setInvitations([...invitations, newInvitation]);
        setTimeout(() => {
          setMsgSent(false);
        }, 4000);
      }
    } catch (e) {
      setButtonVisibility(false);
    } finally {
      setButtonVisibility(false);
    }
  };

  const handleCopyClick = () => {
    // Get the URL of the current project
    const projectUrl = window.location.href;
    //TODO: Magic link needs to be copied
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(projectUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000); // Reset copied state after 3 seconds
    });
  };

  const handleProjectUsersRoleChange = (
    event: React.ChangeEvent<{
      value: any;
    }>,
  ) => {
    //TODO: Update the role of the shared project users
  };

  const handleReinvite = async (email, roleId) => {
    try {
      setReinvitingEmail(email);

      if (
        await userInvite({
          email: email,
          roleId: roleId,
          projectId: id,
          sceneId: scene_id,
        })
      ) {
        // Indicate which user has been reinvited and show success
        setReinvitedUserEmail(email);
        setShowReinviteSuccess(true);

        // Reset after 2 seconds
        setTimeout(() => {
          setShowReinviteSuccess(false);
          setReinvitedUserEmail(null);
        }, 2000);
      }
    } catch (e) {
    } finally {
      setReinvitingEmail(null);
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.show}
      maxWidth="md"
      scroll="body"
      PaperProps={{ sx: { position: "fixed", top: 60, right: 20, m: 0 } }}
      onClose={() => props.setShow(false)}
      TransitionComponent={Transition}
      onBackdropClick={() => props.setShow(false)}
    >
      <DialogContent
        sx={{
          position: "relative",
          px: (theme) => [
            `${theme.spacing(5)} !important`,
            `${theme.spacing(10)} !important`,
          ],
          py: (theme) => [
            `${theme.spacing(8)} !important`,
            `${theme.spacing(3)} !important`,
          ],
        }}
      >
        {/* Close Button */}
        {props.show ? (
          <IconButton
            aria-label="close"
            onClick={() => props.setShow(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}

        {/* Dialog Header */}
        <CardContent sx={{ textAlign: "center", "& svg": { mb: 2 } }}>
          <Typography variant="h6" sx={{ mb: 4 }}>
            Share Project
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Share project with a team members.
          </Typography>
        </CardContent>

        <Typography sx={{ mb: 3 }}>Invite New Collaborator</Typography>

        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} direction="row">
            {/* Email field */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      autoFocus
                      label="Email"
                      type="email"
                      value={value}
                      placeholder="Enter the member’s email"
                      size="small"
                      onBlur={onBlur}
                      onChange={onChange}
                      error={Boolean(errors.email)}
                      disabled={projectRoles?.includes("Viewer")}
                    />
                  )}
                />
                {errors.email && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.email.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Select Roles */}
            <Grid item xs={12} sm={4} md={4}>
              <FormControl fullWidth>
                <Controller
                  name="role"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Select
                      labelId="role"
                      id="role"
                      sx={{ ml: 8 }}
                      value={value}
                      size="small"
                      onBlur={onBlur}
                      onChange={onChange}
                      error={Boolean(errors.role)}
                      disabled={projectRoles?.includes("Viewer")}
                    >
                      {roles.map((item, index) => (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.role && (
                  <FormHelperText
                    sx={{
                      color: "error.main",
                      paddingLeft: 8,
                    }}
                  >
                    {"role is a required field"}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* Invite Button */}
            <Grid item xs={12} sm={2} md={2}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ ml: 8 }}
                  disabled={disableButton || projectRoles?.includes("Viewer")}
                  // onClick={()=>SubmitHandler()}
                >
                  {disableButton ? "Inviting!" : "Invite"}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </form>

        {msgSent && (
          <Typography sx={{ m: 2, color: "green" }}>
            Invitation sent successfully!!
          </Typography>
        )}

        {/* Project users list with their roles */}

        <Typography variant="h6" sx={{ mt: 3 }}>
          {project && `${project.users.length} Member(s)`}
        </Typography>
        <div style={{ maxHeight: "40vh", overflow: "auto" }}>
          <List dense sx={{ py: 4 }}>
            {project &&
              project.users.map((user, i) => {
                return (
                  <ListItem
                    key={i}
                    sx={{
                      p: 0,
                      display: "flex",
                      flexWrap: "wrap",
                      ".MuiListItem-container:not(:last-child) &": { mb: 4 },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {user.user.firstName ? (
                          <Avatar
                            sx={{
                              width: 40,
                              height: 40,
                            }}
                          >
                            {user?.user?.firstName?.charAt(0)?.toUpperCase() ||
                              ""}
                            {user?.user?.lastName?.charAt(0)?.toUpperCase() ||
                              ""}
                          </Avatar>
                        ) : (
                          <Avatar
                            sx={{
                              width: 40,
                              height: 40,
                            }}
                          >
                            {user.user.email.charAt(0).toUpperCase()}
                          </Avatar>
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        (user.user.firstName
                          ? user.user.firstName + " " + user.user.lastName
                          : user.user.email.split("@")[0]) +
                        (user.user.id == userData?.user?.id ? " (me)" : "")
                      }
                      secondary={user.user.email}
                      sx={{
                        m: 0,
                        "& .MuiListItemText-primary, & .MuiListItemText-secondary":
                          { lineHeight: "1.25rem" },
                      }}
                    />
                    <ListItemSecondaryAction sx={{ right: 0 }}>
                      {hidden ? (
                        <IconButton
                          size="small"
                          aria-haspopup="true"
                          onClick={handleClick}
                          aria-controls="modal-share-examples"
                        >
                          <Icon icon="mdi:chevron-down" fontSize={20} />
                        </IconButton>
                      ) : (
                        <Fragment>
                          <Button
                            color="secondary"
                            aria-haspopup="true"
                            onClick={handleClick}
                            sx={{ textTransform: "capitalize" }}
                            aria-controls="modal-share-examples"
                            // endIcon={<Icon icon='mdi:chevron-down' fontSize={20} />}
                          >
                            {user.role.name}
                          </Button>
                        </Fragment>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}

            {/* Code to display invitations */}
            {project &&
              invitations.map((invitation, i) => (
                <ListItem
                  key={`invitation-${i}`}
                  sx={{
                    p: 0,
                    display: "flex",
                    flexWrap: "wrap",
                    ".MuiListItem-container:not(:last-child) &": { mb: 4 },
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ width: 40, height: 40 }}>
                      {invitation.toEmail.charAt(0).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body1" component="span">
                          {invitation.toEmail}
                        </Typography>
                        <Tooltip title="Resend Invite">
                          <IconButton
                            onClick={() =>
                              handleReinvite(
                                invitation.toEmail,
                                invitation.role.id,
                              )
                            }
                            aria-label="reinvite"
                            size="small"
                            sx={{
                              marginLeft: "8px", // Add some space between the text and the icon
                              position: "relative", // Needed to position the CircularProgress
                            }}
                            color="primary"
                          >
                            {reinvitingEmail === invitation.toEmail ? (
                              <CircularProgress
                                size={15}
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  marginTop: "-7px",
                                  marginLeft: "-4px",
                                }}
                              />
                            ) : reinvitedUserEmail === invitation.toEmail &&
                              showReinviteSuccess ? (
                              <Icon
                                icon="mdi:check"
                                fontSize="small"
                                sx={{ color: "#00f300" }}
                              />
                            ) : (
                              <Icon icon="mdi:email-resend" fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    }
                    secondary={`Invited as ${invitation.role.name}`}
                    sx={{
                      m: 0,
                      "& .MuiListItemText-primary, & .MuiListItemText-secondary":
                        { lineHeight: "1.25rem" },
                    }}
                  />

                  <ListItemSecondaryAction sx={{ right: 0 }}>
                    <Button
                      color="secondary"
                      disabled
                      sx={{ textTransform: "capitalize" }}
                      // endIcon={<Icon icon='mdi:chevron-down' fontSize={20} />}
                    >
                      {invitation.role.name}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>

          {/* Project user's select roles menu */}

          {/* <Menu
            keepMounted
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            id='modal-share-examples'
            anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
          >
            {roles.map((item, index) => (
              <MenuItem key={index} value={item.id} sx={{ fontSize: '0.875rem' }} onClick={handleProjectUsersRoleChange}>
                {item.name}
              </MenuItem>
            ))}
          </Menu> */}
        </div>

        {/* Copy Project link  */}

        {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: "flex-end" }}>
            <Button sx={{ lineHeight: '1.5rem', '& svg': { mr: 2 }, mt:2 }} onClick={handleCopyClick}>
              <Icon icon='mdi:link-variant' fontSize={20} />
              {isCopied ? 'Copied!' : 'Copy Project Link'}
            </Button>
          </Box> */}
      </DialogContent>
    </Dialog>
  );
};

export default ShareProject;
